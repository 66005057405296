class _EventBus {
  constructor() {
    this.bus = {}
  }

  $on(id, callback) {
    if (this.bus[id]) {
      this.bus[id].push(callback)
    } else {
      this.bus[id] = [callback]
    }
  }

  $override(id, callback) {
    this.bus[id] = [callback]
  }

  $emit(id, ...variables) {
    this.bus[id].forEach(callback => {
      callback(...variables)
    })
  }
}

export const EventBus = new _EventBus()
