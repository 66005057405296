import { Helmet } from 'react-helmet-async'
import { Container, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Logo from 'components/Logo'
import IllustrationForgot from 'components/Illustrations/Forgot'
import { ForgotForm } from 'sections/auth'

import { useResponsive } from 'utils'

import {
  StyledRoot,
  StyledSection,
  StyledContent
} from './styled'

export default function ForgotPassword() {
  const { t } = useTranslation('common')
  const mdUp = useResponsive('up', 'md')

  return (
    <>
      <Helmet>
        <title>{t('auth.forgot.pageTitle')} | { process.env.REACT_APP_PROJECT_NAME }</title>
      </Helmet>

      <StyledRoot>
        <StyledSection>
          {
            mdUp ? (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    top: { xs: 16, sm: 24, md: 40 },
                    left: { xs: 16, sm: 24, md: 40 },
                  }}
                >
                  <Logo dark />
                </Box>
                <IllustrationForgot
                  style={{ position: 'relative', width: '90%', maxWidth: 500 }}
                />
              </>
            ) : (
              <Box
                sx={{
                  position: 'relative',
                  padding: { xs: 0, sm: 2, md: 2 },
                  width: '100%'
                }}
              >
                <Logo height={24} dark />
              </Box>
            )
          }
        </StyledSection>

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h3" gutterBottom>
              {t('auth.forgot.title')}
            </Typography>

            <Typography variant="subtitle1" sx={{ mb: 6 }}>
              {t('auth.forgot.description')}
            </Typography>

            <ForgotForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  )
}
