import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  Button,
  Container
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/Breadcrumbs';
import Iconify from 'components/Iconify';
import List from 'components/List';
import InviteForm from 'sections/dialogs/InviteForm';

import { getSellers } from 'services/requests/user';
import { setMask, toast } from 'utils';

export default function SellersInvited() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [isInviting, setIsInviting] = useState(false);

  const listConfig = {
    placeholderFilter: t('sellersInvited.search'),
    onClick: row => navigate(`/vendedores/perfil/${row.id}`),
    fields: [
      { key: 'name', label: t('sellersInvited.listFields.name'), desktopColumnSize: 4, isPrimary: true },
      { key: 'email', label: t('sellersInvited.listFields.email'), desktopColumnSize: 4 },
      { key: 'phone', label: t('sellersInvited.listFields.phone'), desktopColumnSize: 4, customValue: row => setMask('(99) 99999-9999', row.phone) }
    ]
  }

  const getData = () => {
    setLoadingData(true)

    getSellers()
      .then((res) => setData(res))
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingData(false));
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Helmet>
        <title> {t('sellersInvited.pageTitle')} | { process.env.REACT_APP_PROJECT_NAME } </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Breadcrumbs
            items={[
              { label: t('sellersInvited.title') }
            ]}
          />
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setIsInviting(true)}
            to="new"
          >
            {t('sellersInvited.new')}
          </Button>
        </Stack>

        <List
          config={listConfig}
          data={data}
          refresh={getData}
          loading={loadingData}
        />
      </Container>

      <InviteForm
        open={isInviting}
        onCancel={() => setIsInviting(false)}
      />
    </>
  )
}
