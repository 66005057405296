import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Link, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { forgotPassword } from 'services/requests/auth';
import { toast } from 'utils';

const initialValues = {
  email: ''
};

export default function ForgotForm() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const validationSchema = yup.object({
    email: yup.string()
      .email('O email deve ser válido')
      .required('Obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      forgotPassword({ body: { ...values } })
        .then(() => {
          toast(t('auth.forgot.success'), 'success');
          navigate('/auth', { replace: true });
        })
        .catch((err) => toast(err, 'error'))
        .then(() => setSubmitting(false));
    },
  });

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label={t('auth.forgot.form.email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        color="secondary"
        variant="contained"
        onClick={() => formik.handleSubmit()}
        loading={formik.isSubmitting}
        sx={{ my: 2 }}
      >
        {t('auth.forgot.form.submit')}
      </LoadingButton>

      <Stack direction="row" alignItems="center" justifyContent="end">
        <Link to="/auth" component={RouterLink} variant="subtitle2" underline="hover">
          {t('auth.forgot.form.forgot')}
        </Link>
      </Stack>
    </>
  );
}
