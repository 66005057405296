import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Chip, Grid, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';

import { setMask } from 'utils';

import MainCard from './_MainCard';
import Skeleton from './_Skeleton';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: theme.palette.primary.main,
    borderRadius: '50%',
    top: -85,
    right: -95,
    [theme.breakpoints.down('sm')]: {
      top: -105,
      right: -140
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: theme.palette.primary.lighter,
    borderRadius: '50%',
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: -155,
      right: -70
    }
  }
}));

const ContractClientCard = ({ isLoading, data, minimal }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { t } = useTranslation('common');

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <CardWrapper
          border={false}
          content={false}
          sx={{ cursor: (!minimal && data.status !== 'denied' && data.status !== 'cancelled') ? 'pointer' : 'default' }}
          onClick={() => (!minimal && data.status !== 'denied' && data.status !== 'cancelled') ? navigate(`/cliente/contrato/${data.id}`) : undefined}
        >
          <Box sx={{ p: 2.25 }}>
            <Grid container direction="column">
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Chip label={`Status: ${data?.statusLabel || t(`contracts.status.${data.status}`)}`} size="small" variant="outlined" color="success" sx={{ mt: 0.85 }} />
                  </Grid>
                  {
                    !minimal ? (
                      <Grid item display="flex" flexDirection="row">
                        {
                          (data.status !== 'denied' && data.status !== 'cancelled') ? (
                            <Avatar
                              variant="rounded"
                              sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                backgroundColor: 'transparent',
                                color: theme.palette.white,
                                zIndex: 1
                              }}
                              aria-controls="menu-earning-card"
                              aria-haspopup="true"
                            >
                              <VisibilityIcon fontSize="inherit" />
                            </Avatar>
                          ) : null
                        }
                      </Grid>
                    ) : null
                  }
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography sx={{ fontSize: '2.125rem', fontWeight: 500 }}>{setMask('money', data.amount)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {
                data.goal ? (
                  <Grid item sx={{ mb: 1.25 }}>
                    <Typography variant="body2">
                      {data.goal}
                    </Typography>
                  </Grid>
                ) : null
              }
              {
                !minimal ? (
                  <>
                    {
                      (data.status !== 'denied' && data.status !== 'cancelled') ? (
                        <Grid item sx={{ mb: 1.25 }}>
                          <Typography variant="body2">
                            {data.installmentsLabel}
                          </Typography>
                        </Grid>
                      ) : null
                    }
                    {
                      data.status === 'pendingSign' ? (
                        <Grid item sx={{ mb: 1.25 }}>
                          <Button
                            variant="contained"
                            onClick={(e) => {
                              e.stopPropagation()
                              window.open(`${process.env.REACT_APP_ZAPSIGN_URL}/${data.zapSignSignerBorrowed}`, '_blank')
                            }}
                          >
                            Assinar contrato
                          </Button>
                        </Grid>
                      ) : (
                        (data.status !== 'denied' && data.status !== 'cancelled') ? (
                          <Grid item sx={{ mb: 1.25 }}>
                            <Button variant="contained">
                              {
                                data.status === 'borrowed' ? 'Pagar parcelas' : 'Ver detalhes'
                              }
                            </Button>
                          </Grid>
                        ) : null
                      )
                    }
                  </>
                ) : data.status === 'pendingSign' ? (
                  <Grid item sx={{ mt: 1 }}>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation()
                        window.open(`${process.env.REACT_APP_ZAPSIGN_URL}/${data.zapSignSignerBorrowed}`, '_blank')
                      }}
                    >
                      Assinar contrato
                    </Button>
                  </Grid>
                ) : null
              }
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

ContractClientCard.propTypes = {
  isLoading: PropTypes.bool,
  minimal: PropTypes.bool,
  data: PropTypes.object
};

export default ContractClientCard;
