import PropTypes from 'prop-types'
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextField from 'components/TextField';

import { enums } from 'utils'

export default function AcceptInviteFormStepCompany({ values, handleChange, touched, errors, setFieldValue }) {
  const { t } = useTranslation('common');

  return (
    <Stack spacing={3}>
       <TextField
        name="cnpj"
        mask="99.999.999/9999-99"
        label={t('auth.acceptInvite.form.cnpj')}
        value={values.cnpj}
        onChange={handleChange}
        error={touched.cnpj && Boolean(errors.cnpj)}
        helperText={touched.cnpj && errors.cnpj}
      />

      <TextField
        name="socialName"
        label={t('auth.acceptInvite.form.socialName')}
        value={values.socialName}
        onChange={handleChange}
        error={touched.socialName && Boolean(errors.socialName)}
        helperText={touched.socialName && errors.socialName}
      />

      <TextField
        name="fantasyName"
        label={t('auth.acceptInvite.form.fantasyName')}
        value={values.fantasyName}
        onChange={handleChange}
        error={touched.fantasyName && Boolean(errors.fantasyName)}
        helperText={touched.fantasyName && errors.fantasyName}
      />

      <TextField
        name="emailCompany"
        label={t('auth.acceptInvite.form.email')}
        value={values.emailCompany}
        onChange={handleChange}
        error={touched.emailCompany && Boolean(errors.emailCompany)}
        helperText={touched.emailCompany && errors.emailCompany}
      />

      <TextField
        name="phoneCompany"
        mask="(99) 99999-9999"
        label={t('auth.acceptInvite.form.phone')}
        value={values.phoneCompany}
        onChange={handleChange}
        error={touched.phoneCompany && Boolean(errors.phoneCompany)}
        helperText={touched.phoneCompany && errors.phoneCompany}
      />

      <FormControl fullWidth>
        <InputLabel id="pixType">
          {t('auth.acceptInvite.form.pixType')}
        </InputLabel>
        <Select
          labelId="pixType"
          name="pixType"
          id="pixType"
          label={t('auth.acceptInvite.form.pixType')}
          value={values.pixType}
          onChange={e => {
            setFieldValue('pixType', e.target.value)
            setFieldValue('pix', '')
          }}
          error={touched.pixType && Boolean(errors.pixType)}
          sx={{ textAlign: 'left' }}
        >
          {
            enums.pixType.map((item, key) => (
              <MenuItem value={item} key={key}>
                {t(`enum.pixType.${item}`)}
              </MenuItem>
            ))
          }
        </Select>
        {
          touched.pixType && Boolean(errors.pixType) ? (
            <FormHelperText
              error={touched.pixType && Boolean(errors.pixType)}
            >
              {touched.pixType && errors.pixType}
            </FormHelperText>
          ) : null
        }
      </FormControl>

      <TextField
        name="pix"
        mask={values.pixType === 'cnpj' ? '99.999.999/9999-99' : values.pixType === 'cpf' ? '999.999.999-99' : values.pixType === 'phone' ? '(99) 99999-9999' : ''}
        label={t('auth.acceptInvite.form.pix')}
        value={values.pix}
        onChange={handleChange}
        error={touched.pix && Boolean(errors.pix)}
        helperText={touched.pix && errors.pix}
      />
    </Stack>
  );
}

AcceptInviteFormStepCompany.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func
}
