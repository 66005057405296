import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'

import Logo from 'components/Logo'

import {
  StyledHeader
} from './styled'

export default function SimpleLayout() {
  return (
    <>
      <StyledHeader>
        <Box
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        >
          <Logo />
        </Box>
      </StyledHeader>

      <Outlet />
    </>
  )
}
