import { styled } from '@mui/material/styles'

export const StyledRoot = styled('div')(({ theme }) => ({
  height: '100vh',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

export const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 1000,
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.primary.main,
}))

export const StyledImage = styled('img')(() => ({
  position: 'relative',
  width: '90%',
  maxWidth: 500
}))

export const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: theme.spacing(6, 0),
}))
