import { API } from 'services';

export function getMe() {
  return API({
    endpoint: '/user/me',
  });
}

export function getSellers() {
  return API({
    endpoint: '/user/sellers',
  });
}

export function getSellersProvider() {
  return API({
    endpoint: '/user/sellers/provider',
  });
}


export function getClients() {
  return API({
    endpoint: '/user/clients',
  });
}

export function getClientsProvider() {
  return API({
    endpoint: '/user/clients/provider',
  });
}

export function getUser({ id, ...params }) {
  return API({
    endpoint: `/user/profile/${id}`,
    ...params
  });
}

export function updateMe({ ...params }) {
  return API({
    method: 'PUT',
    endpoint: '/user/me',
    ...params
  });
}

export function updateUser({ id, ...params }) {
  return API({
    method: 'PUT',
    endpoint: `/user/profile/${id}`,
    ...params
  });
}

export function deleteFile({ key, ...params }) {
  return API({
    method: 'DELETE',
    endpoint: `/user/profile/file/${key}`,
    ...params
  });
}

export function uploadFile({ id, ...params }) {
  return API({
    method: 'POST',
    endpoint: `/user/profile/${id}/file`,
    ...params
  });
}
