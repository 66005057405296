import PropTypes from 'prop-types'
import { Box, Stack, IconButton } from '@mui/material'
import Iconify from 'components/Iconify'

import AccountPopover from './AccountPopover'

import {
  StyledRoot,
  StyledToolbar
} from './styled'

Header.propTypes = {
  onOpenNav: PropTypes.func,
}

export default function Header({ onOpenNav }) {
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  )
}
