import { Helmet } from 'react-helmet-async'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Typography, Container, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Illustration404 from 'components/Illustrations/404'

import {
  StyledContent
} from './styled'

export default function Page404() {
  const { t } = useTranslation('common')

  return (
    <>
      <Helmet>
        <title>{t('404.pageTitle')} | { process.env.REACT_APP_PROJECT_NAME }</title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h4" paragraph>
            {t('404.title')}
          </Typography>

          <Typography sx={{ color: 'text.secondary', mx: 2 }}>
            {t('404.description')}
          </Typography>

          <Illustration404
            style={{ height: 260, margin: '12 0' }}
          />

          <Button to="/" size="large" variant="contained" color="secondary" component={RouterLink}>
            {t('404.action')}
          </Button>
        </StyledContent>
      </Container>
    </>
  )
}
