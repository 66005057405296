import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Stack,
  Container,
  Button,
  Chip,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Backdrop
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PixIcon from '@mui/icons-material/Pix';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import format from 'date-fns/format'
import { utcToZonedTime } from 'date-fns-tz'
import { toast, setMask } from 'utils';
import { UserContext } from 'contexts';
import { useDialog } from 'components/Dialog';

import ACL from 'components/ACL';
import Breadcrumbs from 'components/Breadcrumbs';
import Iconify from 'components/Iconify';
import List from 'components/List';
import ProfileField from 'components/ProfileField'

import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { contractPayment, chargeInstallments, getContract, getPaymentVoucher, getBillingReceipt } from 'services/requests/contract';

dayjs.extend(utc);

export default function ContractDetail() {

  const { t } = useTranslation('common');
  const userContext = useContext(UserContext);
  const { state } = userContext;
  const params = useParams()
  const id = params?.id
  const dialog = useDialog();

  const { pathname } = useLocation();
  const breadcrumbFromProvider = pathname.includes('todos')

  const [data, setData] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingCharge, setLoadingCharge] = useState(null);
  const [loadingProof, setLoadingProof] = useState(false);

  const getData = () => {
    setLoadingData(true)

    getContract({ id })
      .then((res) => setData(res))
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingData(false));
  }

  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const payInstallment = (e, row) => {
    e.stopPropagation();

    setLoadingCharge(row.index)

    chargeInstallments({ body: { installments: [row.id] } })
      .then((res) => {
        dialog({
          title: `Pagar parcela ${row.index}/${data.installments.length}`,
          hideCancel: true,
          confirmationText: 'Ok',
          content: (
            <>
              <Stack direction="column" spacing={3} alignItems="center">
                <Typography variant="body1">
                  Pagamento via Pix
                </Typography>
                <Typography variant="body2" mt={0}>
                  { setMask('money', res.value) }
                </Typography>

                <QRCodeSVG value={res.openPixBrCode} />

                <Button
                  onClick={() => {
                    window.navigator.clipboard.writeText(res.openPixBrCode)
                    toast('Código PIX copiado com sucesso!', 'success')
                  }}
                >
                  Copiar Código PIX Copia e Cola
                </Button>
              </Stack>
            </>
          ),
        })
          .then(() => { })
          .catch(() => { })
      })
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingCharge(null))
  }

  const listConfig = {
    onClick: (row) => (row?.openPixIdentifier && row?.status === 'payed') ? viewBillingReceipt(row) : null,
    fields: [
      { key: 'index', label: 'Parcela', desktopColumnSize: 1, customValue: row => `${row.index}/${data.installments.length}`, sm_prefix: 'Parcela: ' },
      { key: 'installment', label: 'Data de Vencimento', desktopColumnSize: 3, customValue: row => format(new Date(row.maturity), 'dd/MM/yyyy'), sm_prefix: 'Vencimento: '  },
      { key: 'installment', label: 'Data de Pagamento', desktopColumnSize: 3, customValue: row => (
          <Typography component="span">
            {
              row?.openPixPayedDate
                ? format(new Date(row.openPixPayedDate), 'dd/MM/yyyy')
                : 'Pendente'
            }
          </Typography>
        ), sm_prefix: 'Pagamento: ' },
      { key: 'value', label: 'Valor', desktopColumnSize: 3, customValue: row => setMask('money', (row.payedValue || row.valueToPay || row.value)), sm_prefix: 'Valor: ' },
      {
        key: 'status', label: 'Status', desktopColumnSize: 2, customValue: row => (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row">
              <ACL profiles={['admin', 'seller', 'client']}>
                {
                  (row.status === 'pending' && data.contract.status === 'borrowed') ? (
                    <LoadingButton
                      variant="contained"
                      size="small"
                      endIcon={<PixIcon />}
                      onClick={(e) => payInstallment(e, row)}
                      loading={loadingCharge === row.index}
                      sx={{ mt: 1 }}
                    >
                      Cobrar
                    </LoadingButton>
                  ) : (
                    <Chip label={t(`installments.status.${row.status}`)} size="small" variant="outlined" color={row.status === 'pending' ? 'info' : row.status === 'payed' ? 'success' : 'error'} />
                  )
                }
              </ACL>
            </Stack>
          </Stack>
        )
      }
    ]
  }

  const openSignContract = () => {
    const url = state?.data?.role === 'admin' ? `${process.env.REACT_APP_ZAPSIGN_URL}/${data.contract.zapSignSignerLender}` : `${process.env.REACT_APP_ZAPSIGN_URL}/${data.contract.zapSignSignerBorrowed}`;
    window.open(url, '_blank');
  }

  const transferValueToClient = () => {
    contractPayment({ id: data.contract.id })
      .then(() => {
        toast('Pagamento criado, acesse o painel OpenPix para aprovar', 'success')
        getData()
      })
      .catch((err) => toast(err, 'error'));
  }

  const viewPaymentVoucher = async () => {
    setLoadingProof(true)
    const payment = await getPaymentVoucher({ id: data.contract.openPixPaymentId })
    setLoadingProof(false)
    if (payment?.payment?.status === 'CONFIRMED' || payment?.payment?.status === 'APPROVED') {
      dialog({
        title: '',
        hideCancel: true,
        confirmationText: 'Ok',
        content: (
          <>
            <Stack direction="column" alignItems="center" textAlign="center">
              <img src="/assets/logo_openpix.svg" className="logo-openpix-comprovante" alt="OpenPix" />

              <CheckCircleIcon color="success" sx={{ fontSize: 70 }} />

              <Typography variant="subtitle1" mt={2}>
                Pagamento Confirmado
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Valor Pago
              </Typography>
              <Typography variant="body1" mt={0}>
                {setMask('money', payment.transaction.value / 100)}
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Detalhes da transação
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Destinatário
              </Typography>
              <Typography variant="body2" mt={0}>
                {payment.destination.name}<br />{payment.destination.pixKey}
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Data e Hora
              </Typography>
              <Typography variant="body2" mt={0}>
                {format(new Date(payment.transaction.time), `dd/MM/yyyy 'às' HH:mm:ss`)}
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                ID da Transação
              </Typography>
              <Typography variant="body2" mt={0}>
                {payment.transaction.endToEndId}
              </Typography>
            </Stack>
          </>
        ),
      })
        .then(() => { })
        .catch(() => { })
    }
  }

  const viewBillingReceipt = async (row) => {
    setLoadingProof(true)
    let payment = null

    try {
      payment = await getBillingReceipt({ id: row.openPixIdentifier })
    } catch(e) {
      if (row.status === 'payed') {
        payment = {
          charge: {
            status: 'COMPLETED',
            value: row.value * 100,
            paidAt: row.openPixPayedDate,
            identifier: row.openPixIdentifier,
            transactionID: row.openPixIdentifier
          }
        }
      }
    }

    setLoadingProof(false)

    if (payment?.charge?.status === 'COMPLETED') {
      dialog({
        title: '',
        hideCancel: true,
        confirmationText: 'Ok',
        content: (
          <>
            <Stack direction="column" alignItems="center" textAlign="center">
              <img src="/assets/logo_openpix.svg" className="logo-openpix-comprovante" alt="OpenPix" />

              <CheckCircleIcon color="success" sx={{ fontSize: 70 }} />

              <Typography variant="subtitle1" mt={2}>
                Pagamento Confirmado
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Valor Pago
              </Typography>
              <Typography variant="body1" mt={0}>
                {setMask('money', payment.charge.value / 100)}
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Detalhes da transação
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Destinatário
              </Typography>
              <Typography variant="body2" mt={0}>
                {state.data.provider.name}
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Data e Hora
              </Typography>
              <Typography variant="body2" mt={0}>
                {format(new Date(payment.charge.paidAt), `dd/MM/yyyy 'às' HH:mm:ss`)}
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Identificador
              </Typography>
              <Typography variant="body2" mt={0}>
                {payment.charge.identifier}
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                ID da Transação
              </Typography>
              <Typography variant="body2" mt={0}>
                {payment.charge.transactionID}
              </Typography>
            </Stack>
          </>
        ),
      })
        .then(() => { })
        .catch(() => { })
    }
  }

  const getContractAction = () => {
    if ((state?.data?.role === 'admin' && data.contract.status === 'created') || (state?.data?.role === 'client' && data.contract.status === 'pendingSign')) {
      return <Button
        variant="contained"
        onClick={() => { openSignContract() }}
        sx={{ mr: 3, mt: 2 }}
      >
        Assinar Contrato
      </Button>
    }
    if (state?.data?.role === 'admin' && data.contract.status === 'signed') {
      return <Button
        variant="contained"
        onClick={() => { transferValueToClient() }}
        sx={{ mr: 3, mt: 2 }}
      >
        Liberar crédito
      </Button>
    }
    if (state?.data?.role === 'admin' && data.contract.status === 'pendingPayment' && data.contract.openPixPaymentId) {
      return (
        <>
          <Typography sx={{ mt: 2 }}>
            Pagamento gerado na plataforma OpenPix, aguardando aprovação!<br />
            Busque pelo pagamento com CorrelationId: <b>{data.contract.openPixPaymentId}</b>
          </Typography>
          <a href="https://app.openpix.com/home/payments/list" target="_blank" rel="noreferrer">
            <Button
              variant="contained"
              sx={{ mr: 3, mt: 2 }}
            >
              Acessar painel OpenPix
            </Button>
          </a>
        </>
      )
    }
    if (state?.data?.role === 'admin' && (data.contract.status === 'borrowed' || data.contract.status === 'finished') && process.env.NODE_ENV === 'production' && data.contract?.openPixPaymentId) {
      return <Button
        variant="contained"
        onClick={() => { viewPaymentVoucher() }}
        sx={{ mr: 3, mt: 2 }}
      >
        Comprovate de pagamento
      </Button>
    }
    return null
  }

  return (
    <>
      <Helmet>
        <title>Detalhes do Contrato</title>
      </Helmet>

      <Box component="main" mt={1}>
        {
          data && !loadingData ? (
            <Container maxWidth="lg">
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Breadcrumbs
                  items={[
                    { path: `/contratos${breadcrumbFromProvider ? '/todos' : ''}`, label: t(`contracts${breadcrumbFromProvider ? 'Provider' : 'Created'}.title`) },
                    { label: 'Detalhe' }
                  ]}
                />
              </Stack>

              <Grid container rowSpacing={2} columnSpacing={2} mb={3}>
                <Grid xs={12} item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    disabled={loadingData}
                    onClick={() => getData()}
                    type="link"
                    size="small"
                    endIcon={<Iconify icon="material-symbols:refresh" />}
                  >
                    Atualizar
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Card mb={5}>
                    <CardHeader
                      title="Detalhes do Contrato"
                    />
                    <CardContent>
                      <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={12} md={6}>
                          <ProfileField
                            label="Cliente"
                            value={data.borrowed.company.name}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ProfileField
                            label="CNPJ do Cliente"
                            value={setMask('99.999.999/9999-99', data.borrowed.company.cnpj)}
                          />
                        </Grid>
                        <ACL profiles={['admin']}>
                          <Grid item xs={12} md={6}>
                            <ProfileField
                              label="Vendedor"
                              value={data.comissioner.company.name}
                            />
                          </Grid>
                        </ACL>
                        <Grid item xs={12} md={6}>
                          <ProfileField
                            label="Data do Contrato"
                            value={format(new Date(data.contract.created_at), 'dd/MM/yyyy')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ProfileField
                            label="Valor Emprestado"
                            value={setMask('money', data.contract.amount)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ProfileField
                            label="Status"
                            value={t(`contracts.status.${data.contract.status}`)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ProfileField
                            label="Objetivo"
                            value={data.contract.goal}
                          />
                        </Grid>
                        <ACL profiles={['admin']}>
                          <Grid item xs={12} md={6}>
                            <ProfileField
                              label="Dias de carência (atraso parcelas)"
                              value={`${data.contract.installmentDelayDays} dias`}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <ProfileField
                              label="Juros de mora (atraso parcela)"
                              value={`${data.contract.latePaymentInterest * 100}%`}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <ProfileField
                              label="Multa de mora (atraso parcela)"
                              value={`${data.contract.latePaymentFine * 100}%`}
                            />
                          </Grid>
                        </ACL>
                      </Grid>

                      {
                        getContractAction()
                      }

                      <ACL profiles={['admin']}>
                        {
                          data.contract.signedFile ? (
                            <Button
                              variant="contained"
                              onClick={() => window.open(`${process.env.REACT_APP_S3_URL}/${process.env.REACT_APP_S3_FOLDER_CONTRACTS}/${data.contract.signedFile}`, '_blank')}
                              sx={{ mr: 3, mt: 2 }}
                            >
                              Baixar contrato
                            </Button>
                          ) : null
                        }
                      </ACL>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              {
                data.contract.status !== 'cancelled' ? (
                  <List
                    config={listConfig}
                    data={data?.installments || []}
                    showInput={false}
                    showAvatar={false}
                    showPagination={false}
                    loading={false}
                  />
                ) : null
              }
            </Container>
          ) : loadingData ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress size={80} sx={{ my: 12 }} />
            </Box>
          ) : null
        }

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingProof}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}
