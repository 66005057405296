import { API } from 'services';

export function getTerms({ type, ...params }) {
  return API({
    endpoint: `/terms/${type}`,
    ...params
  });
}

export function updateTerms({ body, ...params }) {
  return API({
    method: 'POST',
    endpoint: `/terms`,
    body,
    ...params
  })
}
