import PropTypes from 'prop-types'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { MenuItem, Button, Grid, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { DatePicker } from '@mui/x-date-pickers';
import { format, startOfDay } from 'date-fns';

import TextField from 'components/TextField';

import { getContractReport, getInstallmentReport, getPaymentsReport, getManagerialReport } from 'services/requests/dashboard';
import { toast } from 'utils';

export default function ExportContractReport({ onReset }) {
  const validationSchema = yup.object({
    reportType: yup.string().required('Obrigatório'),
    initialDate: yup.date().required('Obrigatório'),
    finalDate: yup.date().required('Obrigatório')
  });

  const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()

    toast('Relatório exportado com sucesso!', 'success')
    onReset()
  }

  const formik = useFormik({
    initialValues: {
      reportType: '',
      initialDate: '',
      finalDate: ''
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const startDate = format(values.initialDate, 'yyyy-MM-dd')
      const endDate = format(values.finalDate, 'yyyy-MM-dd')

      if (values.reportType === 'contracts') {
        getContractReport({ startDate, endDate })
          .then((response) => downloadFile({ data: response, fileName: `contratos-${format(values.initialDate, 'dd-MM-yyyy')}-${format(values.finalDate, 'dd-MM-yyyy')}.xlsx`, fileType: response.type }))
          .catch((err) => toast(err, 'error'))
          .finally(() => setSubmitting(false))
      } else if (values.reportType === 'installments') {
        getInstallmentReport({ startDate, endDate })
          .then((response) => downloadFile({ data: response, fileName: `parcelas-${format(values.initialDate, 'dd-MM-yyyy')}-${format(values.finalDate, 'dd-MM-yyyy')}.xlsx`, fileType: response.type }))
          .catch((err) => toast(err, 'error'))
          .finally(() => setSubmitting(false))
      } else if (values.reportType === 'payments') {
        getPaymentsReport({ startDate, endDate })
          .then((response) => downloadFile({ data: response, fileName: `emprestimo-${format(values.initialDate, 'dd-MM-yyyy')}-${format(values.finalDate, 'dd-MM-yyyy')}.xlsx`, fileType: response.type }))
          .catch((err) => toast(err, 'error'))
          .finally(() => setSubmitting(false))
      } else if (values.reportType === 'managerial') {
        getManagerialReport({ startDate, endDate })
          .then((response) => downloadFile({ data: response, fileName: `gerencial-${format(values.initialDate, 'dd-MM-yyyy')}-${format(values.finalDate, 'dd-MM-yyyy')}.xlsx`, fileType: response.type }))
          .catch((err) => toast(err, 'error'))
          .finally(() => setSubmitting(false))
      }
    },
  });

  return (
    <Grid container mb={2} columnSpacing={2} rowSpacing={3}>
      <Grid item xs={12} mb={1.5}>
        <Typography variant="subtitle1">
          Gerar relatório para exportação
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <TextField
          fullWidth
          id={`reportType`}
          name={`reportType`}
          label={`Tipo de relatório`}
          value={formik.values.reportType}
          select
          onChange={formik.handleChange}
          InputLabelProps={{ shrink: true }}
          error={formik.touched.reportType && Boolean(formik.errors.reportType)}
          helperText={formik.touched.reportType && formik.errors.reportType}
        >
          <MenuItem value="contracts">Contratos</MenuItem>
          <MenuItem value="installments">Parcelas</MenuItem>
          {/* <MenuItem value="payments">Empréstimos</MenuItem> */}
          <MenuItem value="managerial">Gerencial</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={6} sm={6} md={4}>
        <DatePicker
          id="initialDate"
          name="initialDate"
          label="Data inicial"
          inputFormat="DD/MM/YYYY"
          value={formik.values.initialDate}
          onChange={newValue => formik.setFieldValue('initialDate', startOfDay(new Date(newValue)))}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              error={formik.touched.initialDate && Boolean(formik.errors.initialDate)}
              helperText={formik.touched.initialDate && formik.errors.initialDate}
            />
          )}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={4}>
        <DatePicker
          id="finalDate"
          name="finalDate"
          label="Data final"
          inputFormat="DD/MM/YYYY"
          value={formik.values.finalDate}
          onChange={newValue => formik.setFieldValue('finalDate', startOfDay(new Date(newValue)))}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              error={formik.touched.finalDate && Boolean(formik.errors.finalDate)}
              helperText={formik.touched.finalDate && formik.errors.finalDate}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size="large"
          variant="contained"
          onClick={() => formik.handleSubmit()}
          endIcon={<DownloadIcon />}
          loading={formik.isSubmitting}
        >
          Download
        </Button>
      </Grid>
    </Grid>
  );
}

ExportContractReport.propTypes = {
  onReset: PropTypes.func
}
