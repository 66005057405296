import PropTypes from 'prop-types'
import { useState, useEffect } from 'react';
import { FormHelperText, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MarkdownEditor from '@uiw/react-markdown-editor';

import { updateTerms } from 'services/requests/terms';
import { toast } from 'utils';

export default function TermsForm({ defaultValues, type }) {
  document.documentElement.setAttribute('data-color-mode', 'light')

  const [content, setContent] = useState('')
  const [invalidContent, setInvalidContent] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = () => {
    if (!content) {
      setInvalidContent('Obrigatório')
      return false
    }

    setIsSubmitting(true)

    updateTerms({
      body: {
        content,
        type
      }
    })
      .then(() => toast('Termos atualizados com sucesso', 'success'))
      .catch((err) => toast(err, 'error'))
      .finally(() => setIsSubmitting(false));
  }

  useEffect(() => {
    setInvalidContent(null)
  }, [content])

  useEffect(() => {
    setContent(defaultValues?.content || '')
  }, [defaultValues])

  return (
    <>
      <Stack spacing={3} mt={2}>
        <MarkdownEditor
          value={content}
          height="600px"
          onChange={value => setContent(value)}
          toolbarsFilter={(tool) => {
            if (tool && tool.name === "fullscreen") {
              return false;
            }
            return true;
          }}
        />
        {
          invalidContent ? (
            <FormHelperText error sx={{ textAlign: 'right' }}>
              { invalidContent }
            </FormHelperText>
          ) : null
        }
      </Stack>

      <Stack direction="column" alignItems="end" justifyContent="end">
        <LoadingButton
          size="large"
          type="submit"
          color="secondary"
          variant="contained"
          onClick={() => handleSubmit()}
          loading={isSubmitting}
          sx={{ my: 2 }}
        >
          Salvar
        </LoadingButton>
      </Stack>
    </>
  );
}

TermsForm.propTypes = {
  defaultValues: PropTypes.object,
  type: PropTypes.string,
}
