import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Stack, Box, InputAdornment, MenuItem, FormHelperText } from '@mui/material';
import { Calendar } from "react-multi-date-picker"
import gregorianPtBr from 'react-date-object/locales/gregorian_pt_br'
import 'react-multi-date-picker/styles/colors/purple.css'
import { format } from 'date-fns';

import Dialog from 'components/Dialog';
import TextField from 'components/TextField';

import { createContract } from 'services/requests/contract';
import { toast, workday } from 'utils';
import { UserContext } from 'contexts';
import { getClients, getClientsProvider } from 'services/requests/user';

const LIMIT_OF_INSTALLMENTS = 60

export default function ContractForm({ open, onCancel, onSuccess }) {
  const userContext = useContext(UserContext);
  const {state} = userContext;

  const [minDate, setMinDate] = useState(new Date())
  const [users, setUsers] = useState([]);
  const [installments, setInstallments] = useState([])

  const validationSchema = yup.object({
    user: yup.mixed().required('Obrigatório'),
    amount: yup.number().min(1, 'Mínimo de R$ 1').required('Obrigatório'),
    goal: yup.string().required('Obrigatório')
  });

  useEffect(() => {
    getUsers()
    getMinDate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      user: null,
      amount: 0,
      goal: ''
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (installments?.length <= 0) {
        setSubmitting(false)
        return
      }

      const maturity = installments.map(parcel => format(new Date(parcel), 'yyyy-MM-dd'))
      createContract({
        amount: values.amount,
        maturity,
        goal: values.goal.trim()
        }, values.user).then(() => {
          toast('Contrato gerado com sucesso', 'success')
          handleReset()
          onSuccess?.()
        })
        .catch(err => toast(err, 'error'))
        .finally(() => setSubmitting(false))
    },
  });

  const handleReset = () => {
    formik.handleReset()
    setInstallments([])
    onCancel()
  };

  const getUsers = () => {
    if(state?.data?.role === 'admin'){
      getClientsProvider().then(res => {
        setUsers(res)
      })
      .catch(err => toast(err, 'error'))
    }
    else if(state?.data?.role === 'seller'){
      getClients().then(res => {
        setUsers(res)
      })
      .catch(err => toast(err, 'error'))
    }
  }

  const getMinDate = async() => {
    const mindt = await workday.delayDays(new Date(), 2)
    setMinDate(mindt)
  }

  return (
    <Dialog
      open={Boolean(open)}
      options={{
        title: 'Novo Contrato',
        content: (
          <Stack spacing={3} py={3}>
            <TextField
              fullWidth
              id="user"
              name="user"
              label="Cliente"
              select
              value={formik.values.company}
              onChange={formik.handleChange}
              error={formik.touched.user && Boolean(formik.errors.user)}
              helperText={formik.touched.user && formik.errors.user}
            >
              {
                users?.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))
              }
            </TextField>

            <TextField
              fullWidth
              id="amount"
              name="amount"
              label="Valor"
              type="number"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
            />

            <TextField
              fullWidth
              id="goal"
              name="goal"
              label="Objetivo"
              value={formik.values.goal}
              onChange={formik.handleChange}
              error={formik.touched.goal && Boolean(formik.errors.goal)}
              helperText={formik.touched.goal && formik.errors.goal}
            />

            <Box>
              <FormHelperText sx={{ pb: 1 }}>
                Vencimento das parcelas
              </FormHelperText>
              <Calendar
                sort
                multiple
                format="DD/MM/YYYY"
                minDate={minDate}
                value={installments}
                onChange={array => {
                  if (array.length > LIMIT_OF_INSTALLMENTS) return setInstallments([...installments])

                  return setInstallments(array)
                }}
                locale={gregorianPtBr}
                className="purple"
              />
              {
                installments?.length > 0 ? (
                  <FormHelperText sx={{ py: 1 }}>
                    Total de parcelas: {installments?.length}
                  </FormHelperText>
                ) : (
                  <FormHelperText sx={{ py: 1, color: '#FF4842' }}>
                    É necessário selecionar pelo menos 1 data!
                  </FormHelperText>
                )
              }
            </Box>
          </Stack>
        ),
        cancellationText: 'Cancelar',
        confirmationText: 'Gerar contrato',
        confirmationButtonProps: {
          loading: formik.isSubmitting
        }
      }}
      onCancel={() => handleReset()}
      onConfirm={() => formik.handleSubmit()}
    />
  );
}

ContractForm.propTypes = {
  open: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
}
