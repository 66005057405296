import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Stack,
  Container,
  CircularProgress,
  Card,
  CardContent,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import TermsForm from 'sections/settings/TermsForm';

import { getTerms } from 'services/requests/terms';
import { toast } from 'utils';

export default function Privacy() {
  const navigate = useNavigate()

  const [data, setData] = useState(null)
  const [loadingData, setLoadingData] = useState(true)

  const getData = () => {
    getTerms({ type: 'privacy_policy' })
      .then((res) => setData(res))
      .catch((err) => {
        toast(err, 'error')
        navigate('/', { replace: true })
      })
      .finally(() => setLoadingData(false));
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>Políticas de Privacidade | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Breadcrumbs
            items={[
              { label: 'Políticas de Privacidade' }
            ]}
          />
        </Stack>

        <Stack>
          {
            loadingData ? (
              <Stack spacing={3} alignItems="center">
                <CircularProgress size={80} sx={{ my: 12 }} />
              </Stack>
            ) : data ? (
              <Card>
                <CardContent mt={2}>
                  <TermsForm
                    defaultValues={data}
                    type="privacy_policy"
                  />
                </CardContent>
              </Card>
            ) : null
          }
        </Stack>
      </Container>
    </>
  )
}
