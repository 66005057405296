import PropTypes from 'prop-types'
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextField from 'components/TextField';

import { getAddressFromCep } from 'services/requests/external'
import { Brazil } from 'utils'

export default function AcceptInviteFormStepPersonalAddress({ values, handleChange, touched, errors, setFieldValue, isEditing = false }) {
  const { t } = useTranslation('common');

  const getFullAddressFromCep = async cep => {
    setFieldValue('zipcode', cep)

    if (cep.length === 9) {
      const address = await getAddressFromCep({ cep })
      if (address?.street) {
        const { street, neighborhood, city, state } = address
        setFieldValue('zipcode', cep)
        setFieldValue('street', street)
        setFieldValue('neighborhood', neighborhood)
        setFieldValue('city', city)
        setFieldValue('state', state)
      }
    }
  }

  const handleSelectCompanyAddress = use => {
    if (use) {
      setFieldValue('applyCompanyAddress', use)
      setFieldValue('zipcode', values.zipcodeCompany)
      setFieldValue('street', values.streetCompany)
      setFieldValue('neighborhood', values.neighborhoodCompany)
      setFieldValue('city', values.cityCompany)
      setFieldValue('state', values.stateCompany)
      setFieldValue('number', values.numberCompany)
      setFieldValue('complement', values.complementCompany)
    } else {
      setFieldValue('applyCompanyAddress', use)
    }
  }


  return (
    <Stack spacing={3}>
      {
        !isEditing ? (
          <FormControlLabel
            control={
              <Checkbox
                name="applyCompanyAddress"
                onChange={e => handleSelectCompanyAddress(e.target.checked)}
                defaultChecked={values.applyCompanyAddress}
              />
            }
            label="Aplicar o endereço comercial"
          />
        ) : null
      }

      <TextField
        name="zipcode"
        mask="99999-999"
        label={t('auth.acceptInvite.form.zipcode')}
        value={values.zipcode}
        onChange={e => getFullAddressFromCep(e.target.value)}
        error={touched.zipcode && Boolean(errors.zipcode)}
        helperText={touched.zipcode && errors.zipcode}
        disabled={values.applyCompanyAddress}
      />

      <TextField
        name="street"
        label={t('auth.acceptInvite.form.street')}
        value={values.street}
        onChange={handleChange}
        error={touched.street && Boolean(errors.street)}
        helperText={touched.street && errors.street}
        disabled={values.applyCompanyAddress}
      />

      <TextField
        name="neighborhood"
        label={t('auth.acceptInvite.form.neighborhood')}
        value={values.neighborhood}
        onChange={handleChange}
        error={touched.neighborhood && Boolean(errors.neighborhood)}
        helperText={touched.neighborhood && errors.neighborhood}
        disabled={values.applyCompanyAddress}
      />

      <TextField
        name="city"
        label={t('auth.acceptInvite.form.city')}
        value={values.city}
        onChange={handleChange}
        error={touched.city && Boolean(errors.city)}
        helperText={touched.city && errors.city}
        disabled={values.applyCompanyAddress}
      />

      <FormControl fullWidth>
        <InputLabel id="state">
          {t('auth.acceptInvite.form.state')}
        </InputLabel>
        <Select
          labelId="state"
          name="state"
          id="state"
          label={t('auth.acceptInvite.form.state')}
          value={values.state}
          onChange={handleChange}
          error={touched.state && Boolean(errors.state)}
          disabled={values.applyCompanyAddress}
          sx={{ textAlign: 'left' }}
        >
          {
            Brazil.states.map((item, key) => (
              <MenuItem value={item} key={key}>
                {item}
              </MenuItem>
            ))
          }
        </Select>
        {
          touched.state && Boolean(errors.state) ? (
            <FormHelperText
              error={touched.state && Boolean(errors.state)}
            >
              {touched.state && errors.state}
            </FormHelperText>
          ) : null
        }
      </FormControl>

      <TextField
        name="number"
        label={t('auth.acceptInvite.form.number')}
        value={values.number}
        onChange={handleChange}
        error={touched.number && Boolean(errors.number)}
        helperText={touched.number && errors.number}
        disabled={values.applyCompanyAddress}
      />

      <TextField
        name="complement"
        label={t('auth.acceptInvite.form.complement')}
        value={values.complement}
        onChange={handleChange}
        error={touched.complement && Boolean(errors.complement)}
        helperText={touched.complement && errors.complement}
        disabled={values.applyCompanyAddress}
      />
    </Stack>
  );
}

AcceptInviteFormStepPersonalAddress.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  isEditing: PropTypes.bool
}
