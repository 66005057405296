import PropTypes from 'prop-types';
import { useContext } from 'react';

import { UserContext } from 'contexts';

export default function ACL({ children, profiles, isLogged }) {
  const { state: { isLoggedIn, data } } = useContext(UserContext);

  const profile = data?.role || ''

  const isApproved = () => {
    if (profiles !== undefined && isLogged !== undefined) {
      return Boolean(isLogged) === isLoggedIn || profiles.includes(profile)
    }

    if (profile !== undefined) {
      return profiles.includes(profile)
    }

    if (isLogged !== undefined) {
      return Boolean(isLogged) === isLoggedIn
    }

    return true
  }

  return (
    isApproved()
      ? children
      : null
  )
}

ACL.propTypes = {
  children: PropTypes.node,
  profiles: PropTypes.arrayOf(PropTypes.string),
  isLogged: PropTypes.bool,
}
