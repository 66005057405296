import { useState, useContext } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, IconButton, Popover, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'

import { UserContext } from 'contexts';

import ACL from 'components/ACL'

export default function AccountPopover() {
  const { t } = useTranslation('common');
  const navigate = useNavigate()

  const user = useContext(UserContext);
  const { state } = user;

  const [open, setOpen] = useState(null);
  const [loadingLogout, setLoadingLogout] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMyProfile = () => {
    handleClose();
    navigate('/meu-perfil');
  }

  const handleNavigate = path => {
    handleClose();
    navigate(path);
  }

  const handleLogout = async () => {
    setLoadingLogout(true);
    await user.exec.logout();
    setLoadingLogout(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[100], 0.8),
            },
          }),
        }}
      >
        <Avatar src={state?.data?.avatar || null} alt={state?.data?.name}>
          {state?.data?.name?.slice(0, 1)}
        </Avatar>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 240,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {state?.data?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {state?.data?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleMyProfile} sx={{ mx: 1, mt: 1 }}>
          {t('myProfile.pageTitle')}
        </MenuItem>

        <ACL profiles={['admin']}>
          <MenuItem onClick={() => handleNavigate('/configuracoes/contrato')} sx={{ mx: 1, whiteSpace: 'normal' }}>
            Configurações de Contrato
          </MenuItem>
          <MenuItem onClick={() => handleNavigate('/configuracoes/termos')} sx={{ mx: 1, whiteSpace: 'normal' }}>
            Termos de uso
          </MenuItem>
          <MenuItem onClick={() => handleNavigate('/configuracoes/privacidade')} sx={{ mx: 1, whiteSpace: 'normal' }}>
            Políticas de Privacidade
          </MenuItem>
        </ACL>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ mx: 1, mb: 1 }} disabled={loadingLogout}>
          {loadingLogout ? <CircularProgress size={20} /> : t('logout')}
        </MenuItem>
      </Popover>
    </>
  );
}
