import React, { useContext } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'

import { DialogContext } from 'contexts/dialog'
import { Close } from '@mui/icons-material'

const _Dialog = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation('common')

  const {
    title,
    description,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    dialogActionsProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
    hideCancel,
    confirmationKeyword,
    confirmationKeywordTextFieldProps,
  } = options

  const [confirmationKeywordValue, setConfirmationKeywordValue] = React.useState('')

  const confirmationButtonDisabled = confirmationKeyword && confirmationKeywordValue !== confirmationKeyword

  const confirmationContent = (
    <>
      {confirmationKeyword && (
        <TextField
          onChange={(e) => setConfirmationKeywordValue(e.target.value)}
          value={confirmationKeywordValue}
          fullWidth
          {...confirmationKeywordTextFieldProps}
        />
      )}
    </>
  )

  return (
    <Dialog
      fullWidth
      {...dialogProps}
      open={open}
      onClose={allowClose ? onClose : null}
    >
      {
        allowClose ? (
          <IconButton
            sx={{ position: 'absolute', right: 8, top: 8 }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        ) : null
      }
      {
        title ? (
          <DialogTitle {...titleProps}>{title}</DialogTitle>
        ) : null
      }
      {
        content ? (
          <DialogContent {...contentProps}>
            {content}
            {confirmationContent}
          </DialogContent>
        ) : description ? (
          <DialogContent {...contentProps}>
            <DialogContentText>{description}</DialogContentText>
            {confirmationContent}
          </DialogContent>
        ) : (
          confirmationKeyword && (
            <DialogContent {...contentProps}>{confirmationContent}</DialogContent>
          )
        )
      }
      <DialogActions {...dialogActionsProps}>
        {
          !hideCancel &&
          <LoadingButton
            color="error"
            variant="contained"
            {...cancellationButtonProps}
            onClick={onCancel}
          >
            {t(cancellationText)}
          </LoadingButton>
        }
        <LoadingButton
          color="primary"
          variant="contained"
          disabled={confirmationButtonDisabled}
          {...confirmationButtonProps}
          onClick={onConfirm}
        >
          {t(confirmationText)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export const useDialog = () => useContext(DialogContext)

export default _Dialog
