import PropTypes from 'prop-types';
import { useContext, useState, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'

import { UserContext } from 'contexts'

const Private = ({ profiles }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { state } = useContext(UserContext)

  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    if (state.initialized) {
      if (!state.isLoggedIn && !state.data) {
        navigate(`/auth?${location?.pathname ? `next=${location.pathname.replace('/', '')}` : ''}`, { replace: true })
        return
      }

      if (profiles !== undefined) {
        if (!profiles.includes(state.data.role)) {
          navigate(`/auth?${location?.pathname ? `next=${location.pathname.replace('/', '')}` : ''}`, { replace: true })
          return
        }
      }

      setAuthorized(true)
    }
  }, [state, location, navigate, profiles])

  return (
    state.initialized
      ? authorized && <Outlet />
      : <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh"><CircularProgress size={100} /></Box>
  )
}

export default Private

Private.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.string)
}
