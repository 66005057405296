import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link } from '@mui/material'

const LOGO_HEIGHT = 40

const Logo = forwardRef(({ disabledLink = false, authHeight = false, height = null, dark = false }, ref) => {
  const logoImage = process.env.REACT_APP_PROJECT_LOGO

  const logo = (
    <Box
      ref={ref}
      component="div"
    >
      <img
        src={logoImage}
        style={{
          height: authHeight ? '100%' : height || LOGO_HEIGHT,
          filter: dark ? 'brightness(0) invert(1)' : 'inherit'
        }}
      />
    </Box>
  )

  if (disabledLink) {
    return <>{logo}</>
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  )
})

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  authHeight: PropTypes.bool,
  height: PropTypes.number,
  dark: PropTypes.bool,
  full: PropTypes.bool
}

export default Logo
