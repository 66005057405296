import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async'
import { useNavigate, useLocation } from 'react-router-dom'
import { Container, CircularProgress, Stack } from '@mui/material'
import Markdown from 'react-markdown';

import { getTerms } from 'services/requests/terms';

import {
  StyledContent
} from './styled'

export default function PageTerms() {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState(null)
  const [loadingData, setLoadingData] = useState(true)

  const type = location.pathname === '/termos-de-uso' ? 'terms_of_use' : location.pathname === '/politica-de-privacidade' ? 'privacy_policy' : null
  const title = location.pathname.replaceAll(/-/g, ' ').replaceAll('/', '')
  const parsedTitle = title.charAt(0).toUpperCase() + title.substring(1)

  const getData = () => {
    if (!type) {
      navigate('/', { replace: true })
      return
    }

    getTerms({ type })
      .then((res) => setData(res))
      .catch((err) => navigate('/', { replace: true }))
      .finally(() => setLoadingData(false));
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  return (
    <>
      <Helmet>
        <title>{parsedTitle} | { process.env.REACT_APP_PROJECT_NAME }</title>
      </Helmet>

      <Container>
        {
          loadingData ? (
            <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
              <Stack spacing={3} alignItems="center">
                <CircularProgress size={80} sx={{ my: 12 }} />
              </Stack>
            </StyledContent>
          ) : data ? (
            <Stack mt={12}>
              <Markdown>
                {data?.content}
              </Markdown>
            </Stack>
          ) : null
        }
      </Container>
    </>
  )
}
