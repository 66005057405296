export default function IllustrationLogin({ ...props }) {
  const color = process.env.REACT_APP_PROJECT_COLOR

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 500" {...props}>
      <g id="freepik--Floor--inject-45">
        <line
          x1={48.92}
          y1={456.65}
          x2={700.84}
          y2={456.65}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
      </g>
      <g id="freepik--Plants--inject-45">
        <line
          x1={77.32}
          y1={289.53}
          x2={90.51}
          y2={415.83}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <line
          x1={88.38}
          y1={391.66}
          x2={82.41}
          y2={385.35}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M84.22,387.24a6.77,6.77,0,0,0-2.38-5.07c-1.53-1.37-9.6-4.76-10.37-1.92C70.64,383.34,78.9,389.42,84.22,387.24Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={84.22}
          y1={387.24}
          x2={76.54}
          y2={382.45}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={89.88}
          y1={406.03}
          x2={83.91}
          y2={399.72}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M85.72,401.61a6.76,6.76,0,0,0-2.38-5.07c-1.53-1.37-9.6-4.77-10.37-1.93C72.14,397.71,80.4,403.78,85.72,401.61Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={85.72}
          y1={401.61}
          x2={78.04}
          y2={396.82}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={84.72}
          y1={356.61}
          x2={78.75}
          y2={350.3}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M80.56,352.19a6.79,6.79,0,0,0-2.37-5.07c-1.54-1.37-9.61-4.77-10.38-1.92C67,348.29,75.24,354.36,80.56,352.19Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={80.56}
          y1={352.19}
          x2={72.88}
          y2={347.4}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={86.22}
          y1={370.97}
          x2={80.25}
          y2={364.66}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M82.06,366.55a6.77,6.77,0,0,0-2.38-5.07c-1.53-1.37-9.6-4.76-10.37-1.92C68.48,362.65,76.74,368.73,82.06,366.55Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={82.06}
          y1={366.55}
          x2={74.38}
          y2={361.76}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={82.29}
          y1={333.27}
          x2={76.31}
          y2={326.96}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M78.13,328.85a6.77,6.77,0,0,0-2.38-5.07c-1.53-1.36-9.61-4.76-10.37-1.92C64.54,325,72.81,331,78.13,328.85Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={78.13}
          y1={328.85}
          x2={70.44}
          y2={324.06}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={79.84}
          y1={309.84}
          x2={73.87}
          y2={303.53}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M75.68,305.42a6.77,6.77,0,0,0-2.38-5.07c-1.53-1.37-9.6-4.77-10.37-1.92C62.1,301.52,70.36,307.59,75.68,305.42Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={75.68}
          y1={305.42}
          x2={68}
          y2={300.63}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={87.38}
          y1={383.16}
          x2={91.92}
          y2={375.75}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M90.54,378a6.74,6.74,0,0,1,1.28-5.45c1.21-1.66,8.41-6.65,9.75-4C103,371.36,96.19,379,90.54,378Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={90.54}
          y1={377.98}
          x2={97.46}
          y2={371.36}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={83.43}
          y1={345.36}
          x2={87.98}
          y2={337.95}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M86.59,340.18a6.78,6.78,0,0,1,1.28-5.45c1.22-1.66,8.42-6.65,9.75-4C99.08,333.56,92.25,341.2,86.59,340.18Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={86.59}
          y1={340.18}
          x2={93.51}
          y2={333.56}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={81.7}
          y1={328.75}
          x2={86.24}
          y2={321.34}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M84.86,323.56a6.74,6.74,0,0,1,1.28-5.45c1.22-1.65,8.41-6.64,9.75-4C97.34,316.94,90.51,324.59,84.86,323.56Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={84.86}
          y1={323.56}
          x2={91.78}
          y2={316.95}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={79.16}
          y1={304.4}
          x2={83.7}
          y2={296.99}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M82.32,299.21a6.74,6.74,0,0,1,1.28-5.45c1.21-1.66,8.41-6.65,9.75-4C94.8,292.59,88,300.24,82.32,299.21Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={82.32}
          y1={299.21}
          x2={89.24}
          y2={292.6}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <path
          d="M77.21,288.82a6.78,6.78,0,0,1,1.28-5.45c1.22-1.65,8.42-6.64,9.76-4C89.7,282.2,82.87,289.85,77.21,288.82Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={77.21}
          y1={288.82}
          x2={84.13}
          y2={282.21}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <path
          d="M77.62,289.11a6.75,6.75,0,0,1-5.29-1.85c-1.52-1.38-5.73-9.06-3-10.12C72.34,276,79.23,283.59,77.62,289.11Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={77.62}
          y1={289.11}
          x2={71.76}
          y2={281.53}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={112.09}
          y1={306.92}
          x2={98.98}
          y2={433.24}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <line
          x1={101.88}
          y1={409.15}
          x2={97.33}
          y2={401.74}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M98.72,404a6.78,6.78,0,0,0-1.28-5.45c-1.22-1.65-8.42-6.64-9.76-4C86.23,397.35,93.07,405,98.72,404Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={98.72}
          y1={403.96}
          x2={92.19}
          y2={397.69}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={105.52}
          y1={374.09}
          x2={100.97}
          y2={366.68}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M102.36,368.91a6.78,6.78,0,0,0-1.28-5.45c-1.22-1.66-8.42-6.64-9.76-4C89.87,362.29,96.7,369.94,102.36,368.91Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={102.36}
          y1={368.91}
          x2={95.82}
          y2={362.64}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={104.03}
          y1={388.46}
          x2={99.48}
          y2={381.05}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M100.87,383.27a6.78,6.78,0,0,0-1.28-5.45c-1.22-1.65-8.42-6.64-9.76-4C88.38,376.66,95.21,384.3,100.87,383.27Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={100.87}
          y1={383.27}
          x2={94.33}
          y2={377}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={107.94}
          y1={350.75}
          x2={103.39}
          y2={343.35}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M104.78,345.57a6.78,6.78,0,0,0-1.28-5.45c-1.22-1.66-8.42-6.64-9.76-4C92.29,339,99.13,346.6,104.78,345.57Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={104.78}
          y1={345.57}
          x2={98.25}
          y2={339.3}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={110.37}
          y1={327.32}
          x2={105.83}
          y2={319.91}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M107.21,322.13a6.74,6.74,0,0,0-1.28-5.45c-1.22-1.65-8.42-6.64-9.76-4C94.72,315.52,101.56,323.16,107.21,322.13Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={107.21}
          y1={322.13}
          x2={100.68}
          y2={315.86}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={102.65}
          y1={400.62}
          x2={108.62}
          y2={394.31}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M106.81,396.2a6.74,6.74,0,0,1,2.37-5.07c1.54-1.37,9.61-4.77,10.37-1.93C120.39,392.29,112.13,398.37,106.81,396.2Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={106.81}
          y1={396.2}
          x2={114.94}
          y2={391.15}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={106.57}
          y1={362.82}
          x2={112.54}
          y2={356.5}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M110.73,358.39a6.77,6.77,0,0,1,2.38-5.07c1.53-1.37,9.6-4.77,10.37-1.92C124.31,354.49,116.05,360.56,110.73,358.39Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={110.73}
          y1={358.39}
          x2={118.86}
          y2={353.35}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={108.3}
          y1={346.2}
          x2={114.27}
          y2={339.89}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M112.45,341.78a6.77,6.77,0,0,1,2.38-5.07c1.53-1.37,9.6-4.77,10.37-1.93C126,337.87,117.78,344,112.45,341.78Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={112.45}
          y1={341.78}
          x2={120.59}
          y2={336.73}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={110.82}
          y1={321.85}
          x2={116.8}
          y2={315.53}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M115,317.43a6.76,6.76,0,0,1,2.38-5.07c1.53-1.38,9.6-4.78,10.37-1.93C128.56,313.52,120.3,319.6,115,317.43Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={114.98}
          y1={317.43}
          x2={123.12}
          y2={312.38}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <path
          d="M112.13,306.21a6.79,6.79,0,0,1,2.37-5.07c1.54-1.37,9.61-4.77,10.37-1.93C125.71,302.3,117.45,308.38,112.13,306.21Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={112.13}
          y1={306.21}
          x2={120.26}
          y2={301.16}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <path
          d="M112.46,306.57a6.76,6.76,0,0,1-4.79-2.89c-1.2-1.67-3.74-10.05-.83-10.52C110,292.66,115.18,301.51,112.46,306.57Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={112.46}
          y1={306.57}
          x2={108.29}
          y2={297.96}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={97.07}
          y1={255.48}
          x2={97.07}
          y2={415.22}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <line
          x1={97.2}
          y1={391.2}
          x2={91.91}
          y2={384.3}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M93.52,386.37a6.77,6.77,0,0,0-1.84-5.29c-1.38-1.52-9.06-5.73-10.12-3C80.41,381.08,88,388,93.52,386.37Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={93.52}
          y1={386.37}
          x2={86.37}
          y2={380.81}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={97.2}
          y1={361.18}
          x2={91.91}
          y2={354.29}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M93.52,356.35a6.75,6.75,0,0,0-1.84-5.28c-1.38-1.53-9.06-5.74-10.12-3C80.41,351.07,88,358,93.52,356.35Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={93.52}
          y1={356.35}
          x2={86.37}
          y2={350.79}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={97.2}
          y1={325.94}
          x2={91.91}
          y2={319.04}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M93.52,321.11a6.77,6.77,0,0,0-1.84-5.29c-1.38-1.52-9.06-5.73-10.12-3C80.41,315.82,88,322.72,93.52,321.11Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={93.52}
          y1={321.11}
          x2={86.37}
          y2={315.55}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={97.2}
          y1={302.48}
          x2={91.91}
          y2={295.58}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M93.52,297.65a6.77,6.77,0,0,0-1.84-5.29c-1.38-1.52-9.06-5.74-10.12-3C80.41,292.36,88,299.26,93.52,297.65Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={93.52}
          y1={297.65}
          x2={86.37}
          y2={292.09}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={97.2}
          y1={278.91}
          x2={91.91}
          y2={272.02}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M93.52,274.09a6.77,6.77,0,0,0-1.84-5.29c-1.38-1.52-9.06-5.74-10.12-3C80.41,268.8,88,275.7,93.52,274.09Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={93.52}
          y1={274.09}
          x2={86.37}
          y2={268.53}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={97.08}
          y1={400.37}
          x2={102.37}
          y2={393.47}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M100.76,395.54a6.77,6.77,0,0,1,1.84-5.29c1.38-1.52,9.06-5.74,10.12-3C113.87,390.25,106.28,397.15,100.76,395.54Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={100.76}
          y1={395.54}
          x2={108.33}
          y2={389.68}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={97.08}
          y1={352.63}
          x2={102.37}
          y2={345.73}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M100.76,347.8a6.77,6.77,0,0,1,1.84-5.29c1.38-1.52,9.06-5.74,10.12-3C113.87,342.51,106.28,349.41,100.76,347.8Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={100.76}
          y1={347.8}
          x2={108.33}
          y2={341.94}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={97.08}
          y1={329.06}
          x2={102.37}
          y2={322.16}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M100.76,324.23a6.77,6.77,0,0,1,1.84-5.29c1.38-1.52,9.06-5.73,10.12-3C113.87,318.94,106.28,325.84,100.76,324.23Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={100.76}
          y1={324.23}
          x2={108.33}
          y2={318.37}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={97.08}
          y1={297.92}
          x2={102.37}
          y2={291.02}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M100.76,293.09a6.77,6.77,0,0,1,1.84-5.29c1.38-1.52,9.06-5.74,10.12-3C113.87,287.8,106.28,294.7,100.76,293.09Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={100.76}
          y1={293.09}
          x2={108.33}
          y2={287.23}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={97.08}
          y1={273.43}
          x2={102.37}
          y2={266.53}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M100.76,268.6a6.77,6.77,0,0,1,1.84-5.29c1.38-1.52,9.06-5.73,10.12-3C113.87,263.31,106.28,270.21,100.76,268.6Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={100.76}
          y1={268.6}
          x2={108.33}
          y2={262.74}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <path
          d="M96.77,257.74a6.78,6.78,0,0,1,1.83-5.29c1.39-1.52,9.06-5.73,10.12-3C109.87,252.45,102.28,259.35,96.77,257.74Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={96.77}
          y1={257.74}
          x2={104.33}
          y2={251.88}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <path
          d="M97.13,258.07a6.74,6.74,0,0,1-5.06-2.39c-1.37-1.53-4.76-9.61-1.91-10.37C93.25,244.48,99.31,252.75,97.13,258.07Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={97.13}
          y1={258.07}
          x2={92.1}
          y2={249.93}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <polygon
          points="103.64 456.65 86.73 456.65 84.61 413.92 105.76 413.92 103.64 456.65"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={644.11}
          y1={289.53}
          x2={657.29}
          y2={415.83}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <line
          x1={655.17}
          y1={391.66}
          x2={649.19}
          y2={385.35}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M651,387.24a6.77,6.77,0,0,0-2.38-5.07c-1.53-1.37-9.61-4.76-10.37-1.92C637.42,383.34,645.69,389.42,651,387.24Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={651.01}
          y1={387.24}
          x2={643.32}
          y2={382.45}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={656.67}
          y1={406.03}
          x2={650.69}
          y2={399.72}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M652.51,401.61a6.76,6.76,0,0,0-2.38-5.07c-1.54-1.37-9.61-4.77-10.37-1.93C638.92,397.71,647.19,403.78,652.51,401.61Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={652.51}
          y1={401.61}
          x2={644.82}
          y2={396.82}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={651.51}
          y1={356.61}
          x2={645.53}
          y2={350.3}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M647.35,352.19a6.77,6.77,0,0,0-2.38-5.07c-1.53-1.37-9.61-4.77-10.37-1.92C633.77,348.29,642,354.36,647.35,352.19Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={647.35}
          y1={352.19}
          x2={639.66}
          y2={347.4}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={653.01}
          y1={370.97}
          x2={647.03}
          y2={364.66}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M648.85,366.55a6.77,6.77,0,0,0-2.38-5.07c-1.53-1.37-9.61-4.76-10.37-1.92C635.26,362.65,643.53,368.73,648.85,366.55Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={648.85}
          y1={366.55}
          x2={641.16}
          y2={361.76}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={649.07}
          y1={333.27}
          x2={643.1}
          y2={326.96}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M644.91,328.85a6.79,6.79,0,0,0-2.37-5.07c-1.54-1.36-9.61-4.76-10.38-1.92C631.33,325,639.59,331,644.91,328.85Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={644.91}
          y1={328.85}
          x2={637.23}
          y2={324.06}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={646.63}
          y1={309.84}
          x2={640.65}
          y2={303.53}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M642.47,305.42a6.77,6.77,0,0,0-2.38-5.07c-1.53-1.37-9.61-4.77-10.37-1.92C628.88,301.52,637.15,307.59,642.47,305.42Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={642.47}
          y1={305.42}
          x2={634.78}
          y2={300.63}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={654.16}
          y1={383.16}
          x2={658.71}
          y2={375.75}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M657.32,378a6.78,6.78,0,0,1,1.28-5.45c1.22-1.66,8.42-6.65,9.75-4C669.81,371.36,663,379,657.32,378Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={657.32}
          y1={377.98}
          x2={664.24}
          y2={371.36}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={650.22}
          y1={345.36}
          x2={654.76}
          y2={337.95}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M653.38,340.18a6.74,6.74,0,0,1,1.28-5.45c1.21-1.66,8.41-6.65,9.75-4C665.86,333.56,659,341.2,653.38,340.18Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={653.38}
          y1={340.18}
          x2={660.3}
          y2={333.56}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={648.48}
          y1={328.75}
          x2={653.03}
          y2={321.34}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M651.64,323.56a6.78,6.78,0,0,1,1.28-5.45c1.22-1.65,8.42-6.64,9.75-4C664.13,316.94,657.3,324.59,651.64,323.56Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={651.64}
          y1={323.56}
          x2={658.56}
          y2={316.95}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={645.94}
          y1={304.4}
          x2={650.49}
          y2={296.99}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M649.1,299.21a6.78,6.78,0,0,1,1.28-5.45c1.22-1.66,8.42-6.65,9.75-4C661.59,292.59,654.76,300.24,649.1,299.21Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={649.1}
          y1={299.21}
          x2={656.02}
          y2={292.6}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <path
          d="M644,288.82a6.78,6.78,0,0,1,1.28-5.45c1.22-1.65,8.42-6.64,9.75-4C656.49,282.2,649.66,289.85,644,288.82Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={644}
          y1={288.82}
          x2={650.92}
          y2={282.21}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <path
          d="M644.4,289.11a6.75,6.75,0,0,1-5.29-1.85c-1.52-1.38-5.72-9.06-3-10.12C639.13,276,646,283.59,644.4,289.11Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={644.4}
          y1={289.11}
          x2={638.55}
          y2={281.53}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={678.88}
          y1={306.92}
          x2={665.77}
          y2={433.24}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <line
          x1={668.67}
          y1={409.15}
          x2={664.12}
          y2={401.74}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M665.51,404a6.77,6.77,0,0,0-1.29-5.45c-1.22-1.65-8.42-6.64-9.75-4C653,397.35,659.85,405,665.51,404Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={665.51}
          y1={403.96}
          x2={658.97}
          y2={397.69}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={672.31}
          y1={374.09}
          x2={667.76}
          y2={366.68}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M669.14,368.91a6.74,6.74,0,0,0-1.28-5.45c-1.22-1.66-8.42-6.64-9.75-4C656.65,362.29,663.49,369.94,669.14,368.91Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={669.14}
          y1={368.91}
          x2={662.61}
          y2={362.64}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={670.82}
          y1={388.46}
          x2={666.27}
          y2={381.05}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M667.65,383.27a6.74,6.74,0,0,0-1.28-5.45c-1.22-1.65-8.42-6.64-9.75-4C655.16,376.66,662,384.3,667.65,383.27Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={667.65}
          y1={383.27}
          x2={661.12}
          y2={377}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={674.73}
          y1={350.75}
          x2={670.18}
          y2={343.35}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M671.57,345.57a6.77,6.77,0,0,0-1.29-5.45c-1.22-1.66-8.42-6.64-9.75-4C659.08,339,665.91,346.6,671.57,345.57Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={671.57}
          y1={345.57}
          x2={665.03}
          y2={339.3}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={677.16}
          y1={327.32}
          x2={672.61}
          y2={319.91}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M674,322.13a6.77,6.77,0,0,0-1.29-5.45c-1.21-1.65-8.41-6.64-9.75-4C661.51,315.52,668.34,323.16,674,322.13Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={674}
          y1={322.13}
          x2={667.46}
          y2={315.86}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={669.44}
          y1={400.62}
          x2={675.41}
          y2={394.31}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M673.59,396.2a6.77,6.77,0,0,1,2.38-5.07c1.53-1.37,9.6-4.77,10.37-1.93C687.17,392.29,678.92,398.37,673.59,396.2Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={673.59}
          y1={396.2}
          x2={681.73}
          y2={391.15}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={673.36}
          y1={362.82}
          x2={679.33}
          y2={356.5}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M677.52,358.39a6.74,6.74,0,0,1,2.37-5.07c1.53-1.37,9.6-4.77,10.37-1.92C691.1,354.49,682.84,360.56,677.52,358.39Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={677.52}
          y1={358.39}
          x2={685.65}
          y2={353.35}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={675.08}
          y1={346.2}
          x2={681.05}
          y2={339.89}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M679.24,341.78a6.77,6.77,0,0,1,2.38-5.07c1.53-1.37,9.6-4.77,10.37-1.93C692.82,337.87,684.56,344,679.24,341.78Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={679.24}
          y1={341.78}
          x2={687.37}
          y2={336.73}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={677.61}
          y1={321.85}
          x2={683.58}
          y2={315.53}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M681.77,317.43a6.73,6.73,0,0,1,2.37-5.07c1.54-1.38,9.61-4.78,10.37-1.93C695.35,313.52,687.09,319.6,681.77,317.43Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={681.77}
          y1={317.43}
          x2={689.9}
          y2={312.38}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <path
          d="M678.91,306.21a6.77,6.77,0,0,1,2.38-5.07c1.53-1.37,9.6-4.77,10.37-1.93C692.5,302.3,684.24,308.38,678.91,306.21Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={678.91}
          y1={306.21}
          x2={687.05}
          y2={301.16}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <path
          d="M679.25,306.57a6.78,6.78,0,0,1-4.8-2.89c-1.2-1.67-3.73-10.05-.83-10.52C676.79,292.66,682,301.51,679.25,306.57Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={679.25}
          y1={306.57}
          x2={675.08}
          y2={297.96}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={663.86}
          y1={255.48}
          x2={663.86}
          y2={415.22}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <line
          x1={663.98}
          y1={391.2}
          x2={658.7}
          y2={384.3}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M660.31,386.37a6.77,6.77,0,0,0-1.84-5.29c-1.39-1.52-9.06-5.73-10.12-3C647.2,381.08,654.79,388,660.31,386.37Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={660.31}
          y1={386.37}
          x2={653.16}
          y2={380.81}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={663.98}
          y1={361.18}
          x2={658.7}
          y2={354.29}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M660.31,356.35a6.75,6.75,0,0,0-1.84-5.28c-1.39-1.53-9.06-5.74-10.12-3C647.2,351.07,654.79,358,660.31,356.35Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={660.31}
          y1={356.35}
          x2={653.16}
          y2={350.79}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={663.98}
          y1={325.94}
          x2={658.7}
          y2={319.04}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M660.31,321.11a6.77,6.77,0,0,0-1.84-5.29c-1.39-1.52-9.06-5.73-10.12-3C647.2,315.82,654.79,322.72,660.31,321.11Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={660.31}
          y1={321.11}
          x2={653.16}
          y2={315.55}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={663.98}
          y1={302.48}
          x2={658.7}
          y2={295.58}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M660.31,297.65a6.77,6.77,0,0,0-1.84-5.29c-1.39-1.52-9.06-5.74-10.12-3C647.2,292.36,654.79,299.26,660.31,297.65Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={660.31}
          y1={297.65}
          x2={653.16}
          y2={292.09}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={663.98}
          y1={278.91}
          x2={658.7}
          y2={272.02}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M660.31,274.09a6.77,6.77,0,0,0-1.84-5.29c-1.39-1.52-9.06-5.74-10.12-3C647.2,268.8,654.79,275.7,660.31,274.09Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={660.31}
          y1={274.09}
          x2={653.16}
          y2={268.53}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={663.87}
          y1={400.37}
          x2={669.16}
          y2={393.47}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M667.55,395.54a6.77,6.77,0,0,1,1.84-5.29c1.38-1.52,9.06-5.74,10.11-3C680.65,390.25,673.06,397.15,667.55,395.54Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={667.55}
          y1={395.54}
          x2={675.12}
          y2={389.68}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={663.87}
          y1={352.63}
          x2={669.16}
          y2={345.73}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M667.55,347.8a6.77,6.77,0,0,1,1.84-5.29c1.38-1.52,9.06-5.74,10.11-3C680.65,342.51,673.06,349.41,667.55,347.8Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={667.55}
          y1={347.8}
          x2={675.12}
          y2={341.94}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={663.87}
          y1={329.06}
          x2={669.16}
          y2={322.16}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M667.55,324.23a6.77,6.77,0,0,1,1.84-5.29c1.38-1.52,9.06-5.73,10.11-3C680.65,318.94,673.06,325.84,667.55,324.23Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={667.55}
          y1={324.23}
          x2={675.12}
          y2={318.37}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={663.87}
          y1={297.92}
          x2={669.16}
          y2={291.02}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M667.55,293.09a6.77,6.77,0,0,1,1.84-5.29c1.38-1.52,9.06-5.74,10.11-3C680.65,287.8,673.06,294.7,667.55,293.09Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={667.55}
          y1={293.09}
          x2={675.12}
          y2={287.23}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={663.87}
          y1={273.43}
          x2={669.16}
          y2={266.53}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: 2,
          }}
        />
        <path
          d="M667.55,268.6a6.77,6.77,0,0,1,1.84-5.29c1.38-1.52,9.06-5.73,10.11-3C680.65,263.31,673.06,270.21,667.55,268.6Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={667.55}
          y1={268.6}
          x2={675.12}
          y2={262.74}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <path
          d="M663.55,257.74a6.77,6.77,0,0,1,1.84-5.29c1.38-1.52,9.06-5.73,10.12-3C676.66,252.45,669.07,259.35,663.55,257.74Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={663.55}
          y1={257.74}
          x2={671.12}
          y2={251.88}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <path
          d="M663.92,258.07a6.76,6.76,0,0,1-5.07-2.39c-1.36-1.53-4.75-9.61-1.91-10.37C660,244.48,666.1,252.75,663.92,258.07Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={663.92}
          y1={258.07}
          x2={658.89}
          y2={249.93}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeMiterlimit: 10,
            strokeWidth: "0.5px",
          }}
        />
        <polygon
          points="670.43 456.65 653.51 456.65 651.4 413.92 672.54 413.92 670.43 456.65"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeMiterlimit: 10,
          }}
        />
      </g>
      <g id="freepik--light-bulb--inject-45">
        <path
          d="M677,119a35.37,35.37,0,1,0-62.54,22.63,34.72,34.72,0,0,1,8.06,22.25v4.84H660.7v-4.84a34.63,34.63,0,0,1,8-22.23A35.24,35.24,0,0,0,677,119Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={622.47}
          y={168.73}
          width={38.23}
          height={9.46}
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M622.47,178.21H660.7a0,0,0,0,1,0,0v4.73a4.73,4.73,0,0,1-4.73,4.73H627.19a4.73,4.73,0,0,1-4.73-4.73v-4.73A0,0,0,0,1,622.47,178.21Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M627.29,187.66h28.58a0,0,0,0,1,0,0v3a6.41,6.41,0,0,1-6.41,6.41H633.7a6.41,6.41,0,0,1-6.41-6.41v-3A0,0,0,0,1,627.29,187.66Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <line
          x1={681.97}
          y1={119.19}
          x2={698.17}
          y2={119.19}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <line
          x1={583.48}
          y1={119.19}
          x2={599.89}
          y2={119.19}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <line
          x1={640.83}
          y1={78.09}
          x2={640.83}
          y2={61.85}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <line
          x1={670.19}
          y1={89.82}
          x2={681.37}
          y2={78.64}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <line
          x1={600.28}
          y1={159.73}
          x2={611.62}
          y2={148.39}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <line
          x1={611.14}
          y1={89.5}
          x2={600.28}
          y2={78.64}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <line
          x1={681.37}
          y1={159.73}
          x2={670.56}
          y2={148.92}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </g>
      <g id="freepik--Arrow--inject-45">
        <polygon
          points="432.55 107.12 385.74 42.39 338.92 107.12 358.18 107.12 358.18 179.03 413.3 179.03 413.3 107.12 432.55 107.12"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </g>
      <g id="freepik--Coin--inject-45">
        <circle
          cx={385.41}
          cy={170.09}
          r={48.26}
          transform="translate(-22.2 63.68) rotate(-9.18)"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <circle
          cx={385.41}
          cy={170.09}
          r={41.14}
          transform="translate(60.02 437.66) rotate(-63.24)"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M399.15,176.27c0-10.77-15.26-11-15.26-15.5,0-2.36,1.24-3.07,3.79-3.07,2.19,0,4,.94,6.15,2.84l5-5.8a15.5,15.5,0,0,0-8.93-5v-6H383.3V150c-5.7,1.23-9.11,5.29-9.11,11.27,0,10.36,15.26,11,15.26,15.74,0,2.31-1.18,3.31-4.14,3.31-2.48,0-5-.94-8.16-3.31l-4.38,6.86a20.05,20.05,0,0,0,10.53,4.47v5.94h6.63v-6.36C396.1,186.42,399.15,182,399.15,176.27Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </g>
      <g id="freepik--Charts--inject-45">
        <circle
          cx={140.99}
          cy={104.43}
          r={31.42}
          transform="translate(-16.37 26.83) rotate(-10.28)"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M149.87,74.31,141,104.43h31.42A31.41,31.41,0,0,0,149.87,74.31Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polyline
          points="160.51 84.61 172.98 69.91 183.68 69.91"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={191.26}
          y1={70.35}
          x2={207.3}
          y2={70.35}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={191.26}
          y1={74.36}
          x2={207.3}
          y2={74.36}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={191.26}
          y1={78.37}
          x2={207.3}
          y2={78.37}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={191.26}
          y1={82.38}
          x2={207.3}
          y2={82.38}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <rect
          x={101.8}
          y={169.07}
          width={9.14}
          height={27.27}
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <rect
          x={116.16}
          y={178.88}
          width={9.14}
          height={17.46}
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={130.53}
          y={163.1}
          width={9.14}
          height={33.24}
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <rect
          x={144.9}
          y={169.98}
          width={9.14}
          height={26.37}
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={159.26}
          y={163.95}
          width={9.14}
          height={32.39}
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <rect
          x={173.63}
          y={144.76}
          width={9.14}
          height={51.59}
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <line
          x1={193.04}
          y1={144.22}
          x2={198.39}
          y2={144.22}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={193.04}
          y1={154.64}
          x2={198.39}
          y2={154.64}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={193.04}
          y1={165.07}
          x2={198.39}
          y2={165.07}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={193.04}
          y1={175.5}
          x2={198.39}
          y2={175.5}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={193.04}
          y1={185.93}
          x2={198.39}
          y2={185.93}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <line
          x1={193.04}
          y1={196.35}
          x2={198.39}
          y2={196.35}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "0.5px",
          }}
        />
        <polyline
          points="107.03 159.02 121.03 167.49 135.6 151.89 149.99 153.1 163.93 147.57 179.71 126.81"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M108.79,159a1.77,1.77,0,1,0-1.76,1.77A1.76,1.76,0,0,0,108.79,159Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M122.8,167.49a1.77,1.77,0,1,0-1.77,1.77A1.76,1.76,0,0,0,122.8,167.49Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M137.36,151.89a1.77,1.77,0,1,0-1.76,1.77A1.76,1.76,0,0,0,137.36,151.89Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M151.75,153.1a1.77,1.77,0,1,0-1.76,1.77A1.76,1.76,0,0,0,151.75,153.1Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M165.7,147.29a1.77,1.77,0,1,0-1.77,1.77A1.77,1.77,0,0,0,165.7,147.29Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M181.47,126.81a1.77,1.77,0,1,0-1.76,1.77A1.77,1.77,0,0,0,181.47,126.81Z"
          style={{
            fill: "#263238",
          }}
        />
      </g>
      <g id="freepik--character-2--inject-45">
        <rect
          x={529.98}
          y={358.51}
          width={5.7}
          height={54.92}
          transform="translate(1065.67 771.93) rotate(180)"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={561.51}
          y={306.31}
          width={10.12}
          height={54.92}
          transform="translate(1090.56 731.06) rotate(-173.33)"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polygon
          points="525.6 415.67 525.6 406.41 540.07 406.41 540.07 415.75 597.95 438.98 597.95 449.94 594 449.94 594 441.61 532.63 427.64 472.88 441.61 472.88 449.94 469.04 449.94 469.04 438.98 525.6 415.67"
          style={{
            fill: "#8c8c8c",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={518.58}
          y={358.51}
          width={28.5}
          height={17.54}
          transform="translate(1065.67 734.55) rotate(180)"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M598,450.38a6.14,6.14,0,1,1,6.13,6.14A6.13,6.13,0,0,1,598,450.38Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M601.45,450.38a2.63,2.63,0,1,1,2.63,2.63A2.63,2.63,0,0,1,601.45,450.38Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M456.32,450.38a6.14,6.14,0,1,1,6.14,6.14A6.14,6.14,0,0,1,456.32,450.38Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <circle
          cx={462.46}
          cy={450.38}
          r={2.63}
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polygon
          points="530.2 450.26 535.02 450.26 537.44 417.37 527.79 417.37 530.2 450.26"
          style={{
            fill: "#8c8c8c",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={530.42}
          y={443.24}
          width={4.82}
          height={13.59}
          transform="translate(1065.67 900.07) rotate(180)"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M570.8,333.26h0a21.51,21.51,0,0,0,23.48-19.35L601,244a21.52,21.52,0,0,0-19.35-23.48h0a21.53,21.53,0,0,0-23.48,19.36l-6.73,70A21.51,21.51,0,0,0,570.8,333.26Z"
          style={{
            fill: "#8c8c8c",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M565.7,332.77h0a21.52,21.52,0,0,0,23.48-19.35l6.73-70A21.52,21.52,0,0,0,576.55,220h0a21.51,21.51,0,0,0-23.47,19.36l-6.73,69.94A21.51,21.51,0,0,0,565.7,332.77Z"
          style={{
            fill: "#8c8c8c",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M472.09,364l100.36,2.64a4.24,4.24,0,0,0,4.37-4.11l.51-16.06a4.24,4.24,0,0,0-4.11-4.37l-100.35-2.64a4.23,4.23,0,0,0-4.37,4.1L468,359.63A4.24,4.24,0,0,0,472.09,364Z"
          style={{
            fill: "#8c8c8c",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={510.17}
          y={341.17}
          width={66.92}
          height={24.55}
          rx={4.24}
          transform="translate(1075.84 723.87) rotate(-178.19)"
          style={{
            fill: "#8c8c8c",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M465.4,432.72l-.49,3.7s-4.44,8.88-6.17,10.36-12.58,6.16-14.55,6.9,1.48,3,6.17,3.21,13.32.25,16.28-.49,8.63-4.94,9.87-5.18l1,3.2s5.43-2,7.4-2.46,5.18-3,5.18-3a95.77,95.77,0,0,0-3.94-10.12,17.83,17.83,0,0,0-3.46-4.44L479,430Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M495.25,291.8l-8.14,4.93-17.76,5.18S413.11,312.27,409.9,316s-2,6.91.74,11.6S461,436.17,461,436.17l19.74-3.95-33.06-99.47s62.17,12.58,73,10.6,24.67-13.56,27.13-22.44-8.88-23-8.88-23Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M433.69,323.1s12.43,6,14.66,12.75"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polygon
          points="559.63 209.41 532.99 206.94 494.02 295.25 519.67 299.69 559.63 209.41"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M533.48,215.19S494.51,294,494,295.25s-11.59,4.44-14.06,4.93-10.61,1.73-10.61,1.73l13.82-9.37L493,267.13,481,261s22.2-33.3,27.13-44.65S515,202,519.92,201.76s19,.74,19,.74Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M528.06,231.85l.49,5.68s-25.9,46.37-26.14,47.36,2,7.89,2,7.89l9.13-6.66,17.26-47.6,4.69-2.72,0-8.81Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M538.36,146.61s-9.16,22.18-11.19,28.9-3.46,17.7-3,18.92,3,5.49,7.73,5.7,8.75.2,8.75.2l-7.12,14.86s.81,11.6,2,11.8,19.13-13.63,19.54-14.25,11.8-25,11.8-25,10.18-5.09,10.79-9.57a7.09,7.09,0,0,0-2.65-6.71v-5.5l4.48-13.43s-11.19-7.32-23.61-10.38-15.06-1-15.06-1Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M558.91,134.2s-18.31-4.89-26.45,2.24S545.48,155,552.4,156s15.47-.41,13.43,1.43-6.51,4.68-4.27,6.51a16.55,16.55,0,0,0,4.68,2.64s-9.56,21-11.19,23.4-4.68,2.24-6.51,1.63-10.58-6.1-13.43-7.12-8.14-4.07-9.36-.81-4.28,17.09.2,20.14,26.25-4.68,29.51-6.92,4.27-6.31,4.88-7.12,9.36-15.26,10.17-16.28,4.48-2,4.48-2,7.33-14.66,7.94-20.35S564.41,134,558.91,134.2Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M539.79,162.48l-.41,9s-9.56,10.78-9.56,12,11.39-.2,11.39-.2"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M538.36,160.45a3.26,3.26,0,0,0-3.86-1.63"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M546.91,165.53s5.9-.61,7.73,3.87"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M531,188.12s5.29,3.26,8.75,3.26"
          style={{
            fill: "none",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M548.92,174.35c-.56,1.11-1.58,1.71-2.28,1.36s-.82-1.54-.26-2.64,1.58-1.71,2.28-1.36S549.48,173.25,548.92,174.35Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M536.18,169.4c-.55,1.1-1.58,1.71-2.28,1.35s-.82-1.53-.26-2.64,1.58-1.71,2.28-1.35S536.74,168.29,536.18,169.4Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M447.89,424.47l-1.73,10.36-3.7,1S439,440,438.27,441s-9.87,7.64-12.33,8.88-8.64,1.72-8.39,3.45,13.32,3.21,21.71,3.45,13.07-2.46,13.07-2.46l.74,1.72,8.63-.74s-.49-8.14-.24-11.59a48.08,48.08,0,0,0-.5-8.39l-.49-7.15Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M517.21,303.39s-70.06,8.63-71.54,10.36-.25,52.79-.25,52.79l-8.14,59.76L462.44,434s7.9-50.39,10.61-64A293,293,0,0,0,477,339.41s38.73,8.88,52,1.48,10.6-16.53,10.6-16.53"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M446.76,364.54s3.19-14.34,3.82-22"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M471.79,331.16a12.08,12.08,0,0,0-2.71-2.64"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M477,339.41s-1.42-2.78-3.28-5.59"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M558.65,204.72S530.77,256,524.85,269.1,511,300.43,511,302.4s-5.18,52.55-3.45,53.53,27.62-7.4,39.71-12.08,12.58-8.14,13.57-10.86.74-37.74.74-37.74,25.16-71.54,24.91-74.74S558.65,204.72,558.65,204.72Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M537.46,256.6c-4.57,4.48-8.41,7.82-8.41,7.82"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M567.53,210.39s-11.59,22.7-17.52,31.83a84.22,84.22,0,0,1-9.86,11.68"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M530.45,205.63s-12,20.06-15.46,31.9-4.2,18-4.2,18"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polyline
          points="524.11 234.32 535.51 226.99 535.95 244.19"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <line
          x1={548.43}
          y1={311.39}
          x2={552.24}
          y2={310.79}
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polyline
          points="551 306.35 524.11 309.56 524.11 315.23 543.31 312.2"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M513.51,213.35s4.44-9.37,6.66-9.62,7.15,0,7.15,2-2.71,2.22-2.71,2.22h-3.7l-1.73,4.44Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M507.34,231.11l1.73-9.62s.74-8.38,2-8.88,12.58-.24,12.58-.24.25,9.86-.74,12.33-5.43,3.45-5.43,3.45a21.78,21.78,0,0,1-1.72,4.44c-.74,1-3.46,2.22-3.46,2.22l-.74,2-6.41-4.2Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polyline
          points="517.45 228.16 516.22 223.47 520.91 223.22"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polygon
          points="504.87 230.38 514.99 237.53 511.04 241.72 502.41 233.83 504.87 230.38"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M502.41,233.83,514,242.46l-22.2,33.8a14.68,14.68,0,0,1-7.89-.5c-4.2-1.48-8.14-9.86-8.14-10.85S502.41,233.83,502.41,233.83Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M493.53,273.05s-3.7-6.91-3.95-8.88-.49-5.92-1.73-7.4-2.71-2.47-3.2-4.69-2.72-1.23-3,1,1.72,4.44,1.72,4.44-6.16-2-7.15-1.48-3,1.23-2.47,3A20.71,20.71,0,0,0,475,262.2s-3.94,5.18-3.94,7.64,2.71,6.42,4.69,7.9,8.88,3,8.88,3Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M564.7,222.29l-22.33,46.32-54.52,3a7.7,7.7,0,0,0-5.67,5.67,24.56,24.56,0,0,0-.74,6.91s36.26,4.44,47.61,5.18,25.16,2.22,26.89.49,22.2-44.15,22.2-44.15"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </g>
      <g id="freepik--character-1--inject-45">
        <rect
          x={232.67}
          y={358.51}
          width={5.7}
          height={54.92}
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={196.73}
          y={306.31}
          width={10.12}
          height={54.92}
          transform="translate(-37.39 25.68) rotate(-6.67)"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polygon
          points="242.76 415.67 242.76 406.41 228.29 406.41 228.29 415.75 170.41 438.98 170.41 449.94 174.36 449.94 174.36 441.61 235.73 427.64 295.48 441.61 295.48 449.94 299.32 449.94 299.32 438.98 242.76 415.67"
          style={{
            fill: "#8c8c8c",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={221.27}
          y={358.51}
          width={28.5}
          height={17.54}
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M170.41,450.38a6.14,6.14,0,1,0-6.14,6.14A6.14,6.14,0,0,0,170.41,450.38Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M166.9,450.38a2.63,2.63,0,1,0-2.63,2.63A2.63,2.63,0,0,0,166.9,450.38Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M312,450.38a6.14,6.14,0,1,0-6.14,6.14A6.14,6.14,0,0,0,312,450.38Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <circle
          cx={305.9}
          cy={450.38}
          r={2.63}
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polygon
          points="238.15 450.26 233.33 450.26 230.92 417.37 240.57 417.37 238.15 450.26"
          style={{
            fill: "#8c8c8c",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={233.11}
          y={443.24}
          width={4.82}
          height={13.59}
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M197.56,333.26h0a21.51,21.51,0,0,1-23.48-19.35l-6.73-70a21.51,21.51,0,0,1,19.35-23.48h0a21.53,21.53,0,0,1,23.48,19.36l6.73,70A21.51,21.51,0,0,1,197.56,333.26Z"
          style={{
            fill: "#8c8c8c",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M202.66,332.77h0a21.52,21.52,0,0,1-23.48-19.35l-6.73-70A21.52,21.52,0,0,1,191.8,220h0a21.52,21.52,0,0,1,23.48,19.36L222,309.29A21.52,21.52,0,0,1,202.66,332.77Z"
          style={{
            fill: "#8c8c8c",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M299.74,364l-103.83,2.64a4.24,4.24,0,0,1-4.37-4.11L191,346.47a4.24,4.24,0,0,1,4.1-4.37L299,339.46a4.23,4.23,0,0,1,4.37,4.1l.51,16.07A4.24,4.24,0,0,1,299.74,364Z"
          style={{
            fill: "#8c8c8c",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={191.27}
          y={341.17}
          width={66.92}
          height={24.55}
          rx={4.24}
          transform="translate(-11.04 7.27) rotate(-1.81)"
          style={{
            fill: "#8c8c8c",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M313,331.29s.89,17.16,3.25,32.53,6.27,62,6.27,62a76,76,0,0,0,5,13.6,35.19,35.19,0,0,0,5.32,8s7.69,7.69,7.39,8.87-5.12,1.06-9.85.17-5.7-2-8.36-3.79-8.71-4.08-11.37-5-3.54-3.24-2.65-5.9,3.85-8.87,3.85-12.71S309.48,407,303,394.87s-10.35-33.12-10.35-33.12Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M314.57,442.66c-1.88-1.46-3.64-3-5.14-4.45-.56,1.43-1.08,2.69-1.37,3.56-.89,2.66,0,5,2.65,5.9s8.71,3.2,11.37,5,3.63,2.9,8.36,3.79,9.56,1,9.85-.17-7.39-8.87-7.39-8.87l-3.84,1.78S319.89,446.8,314.57,442.66Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M199.56,296.05s-2.66,10.42-1.21,17,11.39,21.8,28.59,25.44,60.43,2.45,60.43,2.45-10.42,15.83-14.3,34.89c-2.56,12.62-10.41,30.28-11.62,32.95s-15.51,7.51-15,9.69,2.18,15.74,5.81,21.32,9.69,8,12.11,8.72,2.91.72,2.91-1.7-1.7-5.81-1.7-8-.24-17,.73-18.9S299,368,303.84,359.8s12.36-24.23,14.54-26.89,4.84-6.3,2.66-10.9-52.23-20-52.23-20l-2.63-9.31-25-1.45Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M268.81,302l-2.63-9.31-25-1.45-41.67,4.84s-2.72,10.43-1.21,17c2.4,10.39,11.39,21.8,28.59,25.44s60.43,2.45,60.43,2.45,4.1-20.49,12.11-28.48C289.81,309.21,268.81,302,268.81,302Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M287.37,340.9a88,88,0,0,0,16.71-6.3"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M254.66,430.06c-1.72-4-1.61-12-1.39-16.28-3.66,1.81-7.09,3.56-6.84,4.65.48,2.18,2.18,15.74,5.81,21.32s9.69,8,12.11,8.72,2.91.72,2.91-1.7-1.69-5.8-1.7-8a57.46,57.46,0,0,1-7.51-1.47S257.08,435.63,254.66,430.06Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M251.16,200.12l-7.51,3.15S249.71,260,249.47,268s-.73,24-.73,24,10.42,3.15,17.44.73l-.48-27.37s5.09-26.17,3.15-34.65-8-23.5-8-23.5S258,200.36,251.16,200.12Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M249,224.59l7.28-11.09,8.95,13s-3.87-18.17-4.36-19.38a14.2,14.2,0,0,0-5.08-5.57c-2.91-1.94-6.31-2-8.24-2.18s-3.88,3.88-3.88,3.88Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M245.6,199.23s-24.35,5.33-26.44,6.85-3,6.47-2.48,8.37,6.09,43,6.09,43l-28,40.9s9.14,7.61,17.7,9.13,26.82-.76,33.29-3.42l6.47-2.66s3-49.66-.19-69.26S245.6,199.23,245.6,199.23Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M320.37,262.39l9.51-4.18s2.86-6.28,3.24-6.85,3.61-1.52,5.13-2.86,3.62-3.61,4.19-3.8,1.52,1.52,0,3.8a24.62,24.62,0,0,1-4,4.38l9.33-3.42s3.61-6.09,4.56-3.43-4,10.85-5.13,13.51-2.29,4-4.38,4.38-8.56,1.52-9.89,1.71-9.52,4.94-9.52,4.94Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M333.57,260.49c1.69-1.43,3.64-2.85,4.68-2.85,2.1,0,6.66.38,6.66.38s2.67-6.09,4-7,2.47.38,1.33,2.28"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M258.35,203.8s6.09,26.44,5.9,36.33S261,255,261.58,258.4s1.9,43.38,1.9,43.38l5.33.19s-1.33-15.22-1.14-18.08,2.09-14.27,2.09-14.27,9.52,18.84,10.85,19.79,45.47-17.88,45.47-17.88l-3.24-10.85-33.67,6.85s-6.66-22.07-7.61-26.83-1.71-21.49-4.38-25.68S258.35,203.8,258.35,203.8Z"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M269.76,269.62s1.14-4.75,3.05-17.31a79.17,79.17,0,0,0,.19-22.64"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M267.68,218.61c.91,6.85,1.59,15.31.37,20a112.38,112.38,0,0,1-5.33,15.6"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M266,208s.57,2.87,1.2,7"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M247.8,245c2.47,6.12,4.65,12.09,4.65,12.09"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M236.28,202.27s4.37,26.83,7.8,34.06c.7,1.47,1.46,3.21,2.23,5"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M290.31,256.69l7.42-2.1s4.76-6.09,5.9-7.61,3.8-1.52,5.71-2.66,4.37-5.33,5.32-4.57,0,4.19-1,5.14a40.22,40.22,0,0,1-4,2.85c-.76.57,8.94-1.71,8.94-1.71s6.28-5.52,6.66-4-2.86,6.85-2.86,6.85-4.56,4.37-6.85,6.27-7.8,2.67-9.32,3-4.18.76-4.18.76l-11.42,4.18Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M320.94,250s-4,1.9-6.09,2.85"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <line
          x1={309.72}
          y1={247.74}
          x2={306.29}
          y2={248.5}
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M223.34,204.56s-11.22,3.8-12.94,6.66,4.38,26.25,8.95,39.19,16.74,34.62,18.26,35.38S278.9,272.48,288,269.62s10.65-4.37,10.65-4.37l-4-10.28L249,263.15,235,219.21"
          style={{
            fill: `#${color}`,
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M278.47,146.62s4.44,3,2.19,6.23-7,3.45-7,3.45l-4.15-6.54S274.45,143.9,278.47,146.62Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M273.81,150.52s6.81,16.16,6.43,17.55-3.55,3.56-2.11,5.67,5.66,6.33,5.55,6.92-5.27,3.6-5.27,3.6-3.61,13-5.08,14.9-13.88-.41-13.88-.41l-2.62,14.56-14.74-13.74-5.51-5.2s-4.94-22.18,5.54-35.51S273.81,150.52,273.81,150.52Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M272.87,173.29c0,1.36-.56,2.46-1.19,2.45s-1.12-1.13-1.09-2.5.55-2.46,1.18-2.45S272.89,171.92,272.87,173.29Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M272.9,187.51a8.7,8.7,0,0,1-6.17-3s-.37,5.85,1.45,6.19S272.9,187.51,272.9,187.51Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M269.07,167.87s3.79-3.21,7.18.55"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polygon
          points="259.45 198.75 253.3 197.28 257.93 205.87 259.45 198.75"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M273.81,150.52s-1.34,4.21-7.89,9.71-12.41,9-12.39,10.52,1,2.94-.32,3.31a5.49,5.49,0,0,1-3.14-.53s-3.18,9.07-3.66,15.47a32.07,32.07,0,0,0,1.89,12.42s-14.11.76-22.25-5.42-10.43-13.18-9.53-19.25,3.48-25.24,16-34.63,30.46-9,36.55-5.17,10.5,6.64,9.38,9.67S273.81,150.52,273.81,150.52Z"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M244.24,341.51a2.49,2.49,0,0,1-1.08-.24,3.34,3.34,0,0,1-1.51-4.15c4.52-12.34,9.7-34.35,4.39-40.92-1.24-1-8.71-3-48.95,1.16l-2.93.3c-2.81.24-5.21,4.3-5.83,5.7a2.67,2.67,0,0,1-3.76,1.43,3.4,3.4,0,0,1-1.26-4.27c.39-.88,4-8.64,10.42-9.2l2.85-.29c42.88-4.46,51.1-2.21,53.6.87,9.44,11.68-2,44-3.35,47.65A2.81,2.81,0,0,1,244.24,341.51Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </g>
      <g id="freepik--Device--inject-45">
        <polygon
          points="421.02 247.54 355.31 247.54 340.11 299.03 405.82 299.03 421.02 247.54"
          style={{
            fill: "#d9d9d9",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polygon
          points="292.04 296.21 291.21 299.03 405.82 299.03 406.66 296.21 292.04 296.21"
          style={{
            fill: "#d9d9d9",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <polygon
          points="341.06 296.21 340.23 299.03 405.82 299.03 406.66 296.21 341.06 296.21"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </g>
      <g id="freepik--Desk--inject-45">
        <rect
          x={393.76}
          y={306.76}
          width={11.12}
          height={148.68}
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={393.76}
          y={306.76}
          width={11.12}
          height={24.56}
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <rect
          x={257.67}
          y={299.06}
          width={282.3}
          height={8.26}
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          d="M457.34,456.65h-117a86.87,86.87,0,0,1,37-8.26h43.13a86.8,86.8,0,0,1,37,8.26Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </g>
    </svg>
  )
}
