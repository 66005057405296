import { API } from 'services';

export function createInvite(params) {
  return API({
    method: 'POST',
    endpoint: '/invite',
    ...params,
  });
}

export function getInvites() {
  return API({
    endpoint: '/invite',
  });
}

export function getInvitesProvider() {
  return API({
    endpoint: '/invite/provider',
  });
}

export function getInvitePreview({ token, ...params }) {
  return API({
    endpoint: `/invite/preview/${token}`,
    ...params
  });
}

export function updateInvite({ token, ...params }) {
  return API({
    method: 'PUT',
    endpoint: `/invite/${token}`,
    ...params
  });
}

export function deleteInvite({ token, ...params }) {
  return API({
    method: 'DELETE',
    endpoint: `/invite/${token}`,
    ...params
  });
}

export function acceptInvite({ token, ...params }) {
  return API({
    method: 'POST',
    endpoint: `/invite/accept/${token}`,
    ...params
  });
}
