import formModel from './formModel';

const {
  formField: {
    applyCompanyAddress,
    document,
    name,
    email,
    phone,
    cpf,
    rg,
    birthDate,
    profession,
    nationality,
    maritalStatus,
    propertyRegime,
    password,
    zipcode,
    street,
    neighborhood,
    city,
    state,
    number,
    complement,
    socialName,
    fantasyName,
    emailCompany,
    phoneCompany,
    cnpj,
    pixType,
    pix,
    zipcodeCompany,
    streetCompany,
    neighborhoodCompany,
    cityCompany,
    stateCompany,
    numberCompany,
    complementCompany
  }
} = formModel;

export default {
  [applyCompanyAddress]: false,
  [document]: null,
  [name]: '',
  [email]: '',
  [phone]: '',
  [cpf]: '',
  [rg]: '',
  [birthDate]: '',
  [profession]: '',
  [nationality]: 'Brasileiro',
  [maritalStatus]: '',
  [propertyRegime]: '',
  [password]: '',
  [zipcode]: '',
  [street]: '',
  [neighborhood]: '',
  [city]: '',
  [state]: '',
  [number]: '',
  [complement]: '',
  [socialName]: '',
  [fantasyName]: '',
  [emailCompany]: '',
  [phoneCompany]: '',
  [cnpj]: '',
  [pixType]: '',
  [pix]: '',
  [zipcodeCompany]: '',
  [streetCompany]: '',
  [neighborhoodCompany]: '',
  [cityCompany]: '',
  [stateCompany]: '',
  [numberCompany]: '',
  [complementCompany]: ''
};
