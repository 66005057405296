import Iconify from 'components/Iconify'

const icon = icon => <Iconify icon={icon} />

const navConfig = [
  {
    title: 'common:nav.menu',
    items: [
      {
        title: 'common:nav.dashboard',
        icon: icon('material-symbols:dashboard'),
        path: '/',
        profiles: ['admin', 'seller'],
      },
      {
        title: 'common:nav.contractsActive',
        icon: icon('fa-solid:file-contract'),
        path: '/',
        profiles: ['client'],
      },
      {
        title: 'common:nav.contractsInactive',
        icon: icon('carbon:paste'),
        path: '/encerrados',
        profiles: ['client'],
      },
      {
        title: 'common:nav.sac',
        icon: icon('basil:phone-outline'),
        url: process.env.REACT_APP_SAC_URL,
        profiles: ['client'],
      },
      {
        title: 'common:nav.invites',
        icon: icon('mdi:invite'),
        path: '/convites',
        profiles: ['admin'],
        items: [
          {
            title: 'common:nav.invitesInvited',
            path: '/convites'
          },
          {
            title: 'common:nav.invitesProvider',
            path: '/convites/todos'
          }
        ]
      },
      {
        title: 'common:nav.invites',
        icon: icon('mdi:invite'),
        path: '/convites',
        profiles: ['seller'],
      },
      {
        title: 'common:nav.sellers',
        icon: icon('fluent:people-team-toolbox-20-filled'),
        path: '/vendedores',
        profiles: ['admin'],
        items: [
          {
            title: 'common:nav.sellersInvited',
            path: '/vendedores'
          },
          {
            title: 'common:nav.sellersProvider',
            path: '/vendedores/todos'
          }
        ]
      },
      {
        title: 'common:nav.clients',
        icon: icon('fluent:people-team-28-filled'),
        path: '/clientes',
        profiles: ['admin'],
        items: [
          {
            title: 'common:nav.clientsInvited',
            path: '/clientes'
          },
          {
            title: 'common:nav.clientsProvider',
            path: '/clientes/todos'
          }
        ]
      },
      {
        title: 'common:nav.clients',
        icon: icon('fluent:people-team-28-filled'),
        path: '/clientes',
        profiles: ['seller'],
      },
      {
        title: 'common:nav.contracts',
        icon: icon('fa-solid:file-contract'),
        path: '/contratos',
        profiles: ['admin'],
        items: [
          {
            title: 'common:nav.contractsCreated',
            path: '/contratos'
          },
          {
            title: 'common:nav.contractsProvider',
            path: '/contratos/todos'
          }
        ]
      },
      {
        title: 'common:nav.contracts',
        icon: icon('fa-solid:file-contract'),
        path: '/contratos',
        profiles: ['seller'],
      }
    ]
  }
]

export default navConfig
