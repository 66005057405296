import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async'
import {
  Container,
  Grid,
  Typography,
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  IconButton
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format'

import ACL from 'components/ACL'
import ProfileField from 'components/ProfileField'
import ImageGallery from 'components/ImageGallery'
import EditProfile from 'sections/dialogs/editProfile'

import { UserContext } from 'contexts'
import { setMask } from 'utils'

export default function MyProfile() {
  const { t } = useTranslation('common');

  const [selectedFormEdit, setSelectedFormEdit] = useState(null)

  const user = useContext(UserContext);
  const { address, company, companyaddress, provider, files, ...profile } = user.state.data;
  profile.birthDate = format(new Date(profile.birthDate), 'dd/MM/yyyy')

  const handleSelectEditForm = index => {
    if (index === 0) {
      setSelectedFormEdit({ index, form: profile })
    } else if (index === 1) {
      setSelectedFormEdit({ index, form: address })
    } else if (index === 2) {
      const {
        name: socialName,
        email: emailCompany,
        phone: phoneCompany,
        ...restCompany
      } = company

      setSelectedFormEdit({ index, form: { ...restCompany, socialName, emailCompany, phoneCompany } })
    } else if (index === 3) {
      const {
        zipcode: zipcodeCompany,
        street: streetCompany,
        neighborhood: neighborhoodCompany,
        city: cityCompany,
        state: stateCompany,
        number: numberCompany,
        complement: complementCompany,
      } = companyaddress

      setSelectedFormEdit({ index, form: { zipcodeCompany, streetCompany, neighborhoodCompany, cityCompany, stateCompany, numberCompany, complementCompany } })
    }
  }

  const getProfileFields = () => {
    const fields = [
      'name',
      'email',
      { key: 'phone', customValue: value => setMask('(99) 99999-9999', value) },
      { key: 'cpf', customValue: value => setMask('999.999.999-99', value) },
      'rg',
      'birthDate',
      'profession',
      { key: 'maritalStatus', customValue: value => t(`enum.maritalStatus.${value}`) },
      { key: 'propertyRegime', customValue: value => t(`enum.propertyRegime.${value}`) },
      'nationality'
    ]

    return (
      <Box>
        {
          fields.map((item, key) => {
            const label = typeof item === 'string' ? item : item.key
            const value = typeof item === 'string' ? profile[label] : item.customValue(profile[label])

            return (
              <ProfileField
                key={key}
                label={t(`myProfile.fields.profile.${label}`)}
                value={value}
              />
            )
          })
        }

        <CardHeader
          title={t('myProfile.address')}
          sx={{ pl: 0, pr: 0, pb: 3 }}
          action={(
            <ACL profiles={['admin']}>
              <IconButton onClick={() => handleSelectEditForm(1)}>
                <Edit />
              </IconButton>
            </ACL>
          )}
        />
        <ProfileField
          value={(
            <>
              {`${address.street}, ${address.number}`}
              <br />
              {`${address.neighborhood}, ${address.city}/${address.state}`}
              <br />
              {setMask('CEP 99999-999', address.zipcode)}
              {address.complement ? <><br />{address.complement}</> : ''}
            </>
          )}
        />
      </Box>
    )
  }

  const getCompanyFields = () => {
    const fields = [
      'name',
      'fantasyName',
      'email',
      { key: 'phone', customValue: value => setMask('(99) 99999-9999', value) },
      { key: 'cnpj', customValue: value => setMask('99.999.999-9999/99', value) },
      { key: 'pixType', customValue: value => t(`enum.pixType.${value}`) },
      'pix'
    ]

    return (
      <Box>
        {
          fields.map((item, key) => {
            const label = typeof item === 'string' ? item : item.key
            const value = typeof item === 'string' ? company[label] : item.customValue(company[label])

            return (
              <ProfileField
                key={key}
                label={t(`myProfile.fields.company.${label}`)}
                value={value}
              />
            )
          })
        }

        <CardHeader
          title={t('myProfile.address')}
          sx={{ pl: 0, pr: 0, pb: 3 }}
          action={(
            <ACL profiles={['admin']}>
              <IconButton onClick={() => handleSelectEditForm(3)}>
                <Edit />
              </IconButton>
            </ACL>
          )}
        />
        <ProfileField
          value={(
            <>
              {`${companyaddress.street}, ${companyaddress.number}`}
              <br />
              {`${companyaddress.neighborhood}, ${companyaddress.city}/${companyaddress.state}`}
              <br />
              {setMask('CEP 99999-999', companyaddress.zipcode)}
              {companyaddress.complement ? <><br />{companyaddress.complement}</> : ''}
            </>
          )}
        />
      </Box>
    )
  }

  const getData = () => {
    user.exec.getMe();
  }

  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('myProfile.pageTitle')} - { process.env.REACT_APP_PROJECT_NAME }</title>
      </Helmet>

      <Box component="main" mt={1}>
        <Container maxWidth="lg">
          <Card>
            <CardContent>
              <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <Avatar alt={profile.name} sx={{ height: 80, mb: 2, width: 80 }}>
                  {profile.name?.slice(0, 1)}
                </Avatar>
                <Typography gutterBottom variant="h5">
                  {profile.name}
                </Typography>
                <Typography color="text.secondary" variant="body2">
                  {t(`profiles.${profile.role}`)}
                </Typography>
                <Typography color="text.secondary" variant="body2">
                  {provider.name}
                </Typography>
              </Box>
            </CardContent>
          </Card>

          <Grid container rowSpacing={2} columnSpacing={2} mt={1} mb={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader
                  title={t('myProfile.personal')}
                  action={(
                    <ACL profiles={['admin']}>
                      <IconButton onClick={() => handleSelectEditForm(0)}>
                        <Edit />
                      </IconButton>
                    </ACL>
                  )}
                />
                <CardContent>
                  {
                    getProfileFields()
                  }
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader
                  title={t('myProfile.company')}
                  action={(
                    <ACL profiles={['admin']}>
                      <IconButton onClick={() => handleSelectEditForm(2)}>
                        <Edit />
                      </IconButton>
                    </ACL>
                  )}
                />
                <CardContent>
                  {
                    getCompanyFields()
                  }
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Card>
            <CardHeader title={t('myProfile.docs')} />
            <CardContent sx={{ pt: 5 }}>
              {
                files?.length > 0 ? (
                  <ImageGallery files={files || []} />
                ) : (
                  <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                      <Box
                        component="img"
                        src="/assets/illustrations/illustration_empty_content.svg"
                        sx={{ height: 160, mx: 'auto', mb: 5 }}
                      />
                    <Typography variant="h6" paragraph mb={3}>
                      {t('no_data')}
                    </Typography>
                  </Box>
                )
              }
            </CardContent>
          </Card>
        </Container>

        {
          selectedFormEdit?.index >= 0 && selectedFormEdit?.form ? (
            <EditProfile
              formIndex={selectedFormEdit.index}
              defaultValues={selectedFormEdit.form}
              onSuccess={() => {
                setSelectedFormEdit(null)
                getData()
              }}
              onCancel={() =>  setSelectedFormEdit(null)}
            />
          ) : null
        }
      </Box>
    </>
  );
}
