import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import format from 'date-fns/format'

import { setMask } from 'utils';

import MainCard from './_MainCard';

const CardWrapper = styled(MainCard, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
  overflow: 'hidden',
  position: 'relative',
  cursor: 'pointer',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette[color].main} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: '50%',
    top: -30,
    right: -180
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette[color].main} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
    borderRadius: '50%',
    top: -160,
    right: -130
  }
}));

const InstallmentCard = ({ data, onClick, highlight }) => {
  const theme = useTheme();
  const color = highlight ? 'primary' : data.status === 'payed' ? 'success' : 'error'

  return (
    <CardWrapper
      border={false}
      content={false}
      highlight={highlight || false}
      color={color}
      onClick={() => onClick?.()}
    >
      <Box sx={{ p: 2 }}>
        <List sx={{ py: 0 }}>
          <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
            <ListItemAvatar>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.largeAvatar,
                  backgroundColor: theme.palette[color].main,
                  color: theme.palette.common.white
                }}
              >
                { data.index }
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{ py: 0, mt: 0.45, mb: 0.45 }}
              primary={<Typography variant="h4">{setMask('money', (data.payedValue || data.valueToPay || data.value))}</Typography>}
              secondary={
                <Typography variant="subtitle2">
                  Vencimento: {format(new Date(data.maturity), 'dd/MM/yyyy')}
                </Typography>
              }
            />
            {
              (onClick && data.status === 'pending') ? (
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: 24, height: 24,
                      backgroundColor: theme.palette.common.white,
                      borderColor: theme.palette.primary.main,
                      border: 2,
                      color: theme.palette.primary.main
                    }}
                  >
                    {
                      highlight ? (
                        <CheckIcon />
                      ) : (
                        ' '
                      )
                    }
                  </Avatar>
                </ListItemAvatar>
              ) : null
            }
          </ListItem>
        </List>
      </Box>
    </CardWrapper>
  );
};

InstallmentCard.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  highlight: PropTypes.bool
};

export default InstallmentCard;
