import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';

import { EventBus } from 'utils';
import { Storage } from 'services';

import { login } from 'services/requests/auth';
import { getMe } from 'services/requests/user';
import { acceptInvite } from 'services/requests/invite'

export const UserContext = createContext(null);

UserProvider.propTypes = {
  children: PropTypes.node,
};

export function UserProvider({ children }) {
  const navigate = useNavigate();
  const cookie = new Cookies();
  const [initialized, setInitialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [data, setData] = useState(null);

  const exec = {
    async login(params) {
      const response = await login(params);
      if (response?.accessToken) {
        cookie.set('access_token', response?.accessToken, { path: '/' });
        Storage.set({ key: 'user', value: response?.payload });
        setIsLoggedIn(true);
        setData(response?.payload);
      }

      return true
    },
    logout() {
      Storage.delete({ key: 'user' });
      cookie.remove('access_token');
      setIsLoggedIn(false);
      setData(null);
      navigate('/auth');
    },
    getMe() {
      return getMe()
        .then(user => {
          setIsLoggedIn(true);
          setData(user);
          Storage.set({ key: 'user', value: { ...user } });
        })
        .catch(() => {
          if (isLoggedIn) {
            setIsLoggedIn(false);
            setData(null);
            Storage.delete({ key: 'user' });
            cookie.remove('access_token');
            navigate('/auth');
          }
        });
    },
    async acceptInvite(params) {
      const response = await acceptInvite(params)
      if (response?.accessToken) {
        cookie.set('access_token', response?.accessToken, { path: '/' });
        Storage.set({ key: 'user', value: response?.payload });
        setIsLoggedIn(true);
        setData(response?.payload);
      }

      return true
    }
  };

  EventBus.$override('logout', exec.logout);

  const getInitialData = async () => {
    if (cookie.get('access_token')) {
      const userFromStorage = Storage.get({ key: 'user' });
      if (userFromStorage) {
        setData(userFromStorage);
        setIsLoggedIn(true);
        setInitialized(true);
      }

      await exec.getMe();
    } else {
      setIsLoggedIn(false);
      setData(null);
      Storage.delete({ key: 'user' });
      cookie.remove('access_token');
    }

    setInitialized(true);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={{ state: { initialized, isLoggedIn, data }, exec }}>{children}</UserContext.Provider>
  );
}
