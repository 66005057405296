import { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  Stack,
  CircularProgress,
  IconButton
} from '@mui/material';
import { Edit, AddPhotoAlternate } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format'

import ACL from 'components/ACL'
import Breadcrumbs from 'components/Breadcrumbs';
import ProfileField from 'components/ProfileField'
import ImageGallery from 'components/ImageGallery'
import EditProfile from 'sections/dialogs/editProfile'

import { getUser, deleteFile, uploadFile } from 'services/requests/user'
import { toast, setMask } from 'utils'
import { LoadingButton } from '@mui/lab';

export default function ClientDetail() {
  const { t } = useTranslation('common');
  const { id } = useParams();
  const navigate = useNavigate();
  const inputFileRef = useRef(null);

  const { pathname } = useLocation();
  const breadcrumbFromProvider = pathname.includes('todos')

  const [data, setData] = useState({});
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [selectedFormEdit, setSelectedFormEdit] = useState(null);

  const handleSelectEditForm = index => {
    if (index === 0) {
      setSelectedFormEdit({ index, form: data.profile })
    } else if (index === 1) {
      setSelectedFormEdit({ index, form: data.address })
    } else if (index === 2) {
      const {
        name: socialName,
        email: emailCompany,
        phone: phoneCompany,
        ...restCompany
      } = data.company

      setSelectedFormEdit({ index, form: { ...restCompany, socialName, emailCompany, phoneCompany } })
    } else if (index === 3) {
      const {
        zipcode: zipcodeCompany,
        street: streetCompany,
        neighborhood: neighborhoodCompany,
        city: cityCompany,
        state: stateCompany,
        number: numberCompany,
        complement: complementCompany,
      } = data.companyaddress

      setSelectedFormEdit({ index, form: { zipcodeCompany, streetCompany, neighborhoodCompany, cityCompany, stateCompany, numberCompany, complementCompany } })
    }
  }

  const handleAddDocument = () => {
    inputFileRef.current.click()
  }

  const handleSelectFileToAdd = file => {
    setLoadingUpload(true)

    const formData = new FormData()
    formData.append('file', file)

    uploadFile({ id, formData })
      .then(() => {
        toast('Envio realizado com successo!', 'success')
        getData()
      })
      .catch((err) => toast(err, 'error'))
      .finally(() => {
        setLoadingUpload(false)
        inputFileRef.current.value = null
      })
  }

  const handleRemoveFile = key => {
    deleteFile({ key })
      .then(() => getData())
      .catch(err => toast(err, 'error'))
  }

  const getProfileFields = () => {
    const fields = [
      'name',
      'email',
      { key: 'phone', customValue: value => setMask('(99) 99999-9999', value) },
      { key: 'cpf', customValue: value => setMask('999.999.999-99', value) },
      'rg',
      'birthDate',
      'profession',
      { key: 'maritalStatus', customValue: value => t(`enum.maritalStatus.${value}`) },
      { key: 'propertyRegime', customValue: value => t(`enum.propertyRegime.${value}`) },
      'nationality'
    ]

    return (
      <Box>
        {
          fields.map((item, key) => {
            const label = typeof item === 'string' ? item : item.key
            const value = typeof item === 'string' ? data.profile[label] : item.customValue(data.profile[label])

            return (
              <ProfileField
                key={key}
                label={t(`myProfile.fields.profile.${label}`)}
                value={value}
              />
            )
          })
        }

        <CardHeader
          title={t('myProfile.address')}
          sx={{ pl: 0, pr: 0, pb: 3 }}
          action={(
            <ACL profiles={['admin']}>
              <IconButton color="inherit" onClick={() => handleSelectEditForm(1)}>
                <Edit />
              </IconButton>
            </ACL>
          )}
        />
        <ProfileField
          value={(
            <>
              {`${data.address.street}, ${data.address.number}`}
              <br />
              {`${data.address.neighborhood}, ${data.address.city}/${data.address.state}`}
              <br />
              {setMask('CEP 99999-999', data.address.zipcode)}
              {data.address.complement ? <><br />{data.address.complement}</> : ''}
            </>
          )}
        />
      </Box>
    )
  }

  const getCompanyFields = () => {
    const fields = [
      'name',
      'fantasyName',
      'email',
      { key: 'phone', customValue: value => setMask('(99) 99999-9999', value) },
      { key: 'cnpj', customValue: value => setMask('99.999.999-9999/99', value) },
      { key: 'pixType', customValue: value => t(`enum.pixType.${value}`) },
      'pix'
    ]

    return (
      <Box>
        {
          fields.map((item, key) => {
            const label = typeof item === 'string' ? item : item.key
            const value = typeof item === 'string' ? data.company[label] : item.customValue(data.company[label])

            return (
              <ProfileField
                key={key}
                label={t(`myProfile.fields.company.${label}`)}
                value={value}
              />
            )
          })
        }

        <CardHeader
          title={t('myProfile.address')}
          sx={{ pl: 0, pr: 0, pb: 3 }}
          action={(
            <ACL profiles={['admin']}>
              <IconButton color="inherit" onClick={() => handleSelectEditForm(3)}>
                <Edit />
              </IconButton>
            </ACL>
          )}
        />
        <ProfileField
          value={(
            <>
              {`${data.companyaddress.street}, ${data.companyaddress.number}`}
              <br />
              {`${data.companyaddress.neighborhood}, ${data.companyaddress.city}/${data.companyaddress.state}`}
              <br />
              {setMask('CEP 99999-999', data.companyaddress.zipcode)}
              {data.companyaddress.complement ? <><br />{data.companyaddress.complement}</> : ''}
            </>
          )}
        />
      </Box>
    )
  }

  const getData = () => {
    getUser({ id })
      .then((res) => {
        const { address, company, companyaddress, provider, files, ...profile } = res;
        profile.birthDate = format(new Date(profile.birthDate), 'dd/MM/yyyy')
        setData({ profile, address, company, companyaddress, files, provider })
      })
      .catch(() => navigate('/', { replace: true }))
  }

  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('clientDetail.pageTitle')} - { process.env.REACT_APP_PROJECT_NAME }</title>
      </Helmet>

      <Box component="main" mt={1}>
        {
          data?.profile?.id ? (
            <Container maxWidth="lg">
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Breadcrumbs
                  items={[
                    { path: `/clientes${breadcrumbFromProvider ? '/todos' : ''}`, label: t(`clients${breadcrumbFromProvider ? 'Provider' : 'Invited'}.title`) },
                    { label: t('clientDetail.title') }
                  ]}
                />
              </Stack>

              <Card>
                <CardContent>
                  <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <Avatar alt={data.profile.name} sx={{ height: 80, mb: 2, width: 80 }}>
                      {data.profile.name?.slice(0, 1)}
                    </Avatar>
                    <Typography gutterBottom variant="h5">
                      {data.profile.name}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      {t(`profiles.${data.profile.role}`)}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      {data.provider.name}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>

              <Grid container rowSpacing={2} columnSpacing={2} mt={1} mb={3}>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader
                      title={t('myProfile.personal')}
                      action={(
                        <ACL profiles={['admin']}>
                          <IconButton color="inherit" onClick={() => handleSelectEditForm(0)}>
                            <Edit />
                          </IconButton>
                        </ACL>
                      )}
                    />
                    <CardContent>
                      {
                        getProfileFields()
                      }
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader
                      title={t('myProfile.company')}
                      action={(
                        <ACL profiles={['admin']}>
                          <IconButton color="inherit" onClick={() => handleSelectEditForm(2)}>
                            <Edit />
                          </IconButton>
                        </ACL>
                      )}
                    />
                    <CardContent>
                      {
                        getCompanyFields()
                      }
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Card>
                <CardHeader
                  title={t('myProfile.docs')}
                  action={(
                    <ACL profiles={['admin']}>
                      <input
                        ref={inputFileRef}
                        accept="image/*"
                        id="document"
                        type="file"
                        hidden
                        onChange={e => handleSelectFileToAdd(e.target.files[0])}
                      />
                      {
                        loadingUpload ? 'Enviando documento...' : null
                      }
                      <LoadingButton
                        color="inherit"
                        onClick={() => handleAddDocument()}
                        loading={loadingUpload}
                      >
                        <AddPhotoAlternate />
                      </LoadingButton>
                    </ACL>
                  )}
                />
                <CardContent sx={{ pt: 5 }}>
                  {
                    data.files?.length > 0 ? (
                      <ImageGallery
                        files={data.files || []}
                        onRemoveFile={handleRemoveFile}
                      />
                    ) : (
                      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                          <Box
                            component="img"
                            src="/assets/illustrations/illustration_empty_content.svg"
                            sx={{ height: 160, mx: 'auto', mb: 5 }}
                          />
                        <Typography variant="h6" paragraph mb={3}>
                          {t('no_data')}
                        </Typography>
                      </Box>
                    )
                  }
                </CardContent>
              </Card>
            </Container>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress size={80} sx={{ my: 12 }} />
            </Box>
          )
        }

        {
          selectedFormEdit?.index >= 0 && selectedFormEdit?.form ? (
            <EditProfile
              id={id}
              formIndex={selectedFormEdit.index}
              defaultValues={selectedFormEdit.form}
              onSuccess={() => {
                setSelectedFormEdit(null)
                getData()
              }}
              onCancel={() => setSelectedFormEdit(null)}
            />
          ) : null
        }
      </Box>
    </>
  );
}
