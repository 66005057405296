export default function Illustration404({ ...props }) {
  const color = process.env.REACT_APP_PROJECT_COLOR

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <g id="freepik--background-complete--inject-64">
        <rect
          y={382.4}
          width={500}
          height={0.25}
          style={{
            fill: "#ebebeb",
          }}
        />
        <rect
          x={416.78}
          y={398.49}
          width={33.12}
          height={0.25}
          style={{
            fill: "#ebebeb",
          }}
        />
        <rect
          x={322.53}
          y={401.21}
          width={8.69}
          height={0.25}
          style={{
            fill: "#ebebeb",
          }}
        />
        <rect
          x={396.59}
          y={389.21}
          width={19.19}
          height={0.25}
          style={{
            fill: "#ebebeb",
          }}
        />
        <rect
          x={52.46}
          y={390.89}
          width={43.19}
          height={0.25}
          style={{
            fill: "#ebebeb",
          }}
        />
        <rect
          x={104.56}
          y={390.89}
          width={6.33}
          height={0.25}
          style={{
            fill: "#ebebeb",
          }}
        />
        <rect
          x={131.47}
          y={395.11}
          width={93.68}
          height={0.25}
          style={{
            fill: "#ebebeb",
          }}
        />
        <path
          d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z"
          style={{
            fill: "#ebebeb",
          }}
        />
        <path
          d="M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
          style={{
            fill: "#ebebeb",
          }}
        />
        <rect
          x={336.71}
          y={237.51}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={309.73}
          y={237.51}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={282.75}
          y={237.51}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={350.2}
          y={250.72}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={323.22}
          y={250.72}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={296.24}
          y={250.72}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={269.26}
          y={250.72}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={393.69}
          y={263.93}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={366.71}
          y={263.93}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={309.73}
          y={263.93}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={282.75}
          y={263.93}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={255.77}
          y={263.93}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={380.2}
          y={277.13}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={323.22}
          y={277.13}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={296.24}
          y={277.13}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={269.26}
          y={277.13}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={164.01}
          y={102.96}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={137.03}
          y={102.96}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={177.5}
          y={116.17}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={150.52}
          y={116.17}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={96.56}
          y={116.17}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={69.58}
          y={116.17}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={137.03}
          y={129.38}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={382.64}
          y={180.26}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={335.66}
          y={180.26}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={396.14}
          y={193.47}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={369.15}
          y={193.47}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={138.94}
          y={166.94}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={81.95}
          y={166.94}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={152.43}
          y={180.14}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={115.44}
          y={180.14}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={382.64}
          y={206.68}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={335.66}
          y={206.68}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={83.07}
          y={129.38}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={398.99}
          y={103.32}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={372.01}
          y={103.32}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={385.5}
          y={116.52}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={278.12}
          y={103.53}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={251.14}
          y={103.53}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={291.61}
          y={116.73}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={264.63}
          y={116.73}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={237.65}
          y={116.73}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={305.1}
          y={129.94}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={278.12}
          y={129.94}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={251.14}
          y={129.94}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={291.61}
          y={143.15}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={264.63}
          y={143.15}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={110.26}
          y={233.93}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={83.27}
          y={233.93}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={187.72}
          y={235.13}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={123.75}
          y={247.13}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={96.76}
          y={247.13}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={201.21}
          y={248.34}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={174.22}
          y={248.34}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={245.82}
          y={205.64}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={105.93}
          y={199.36}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={215.41}
          y={158.81}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={232.33}
          y={192.44}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={259.31}
          y={192.44}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={360.6}
          y={372.73}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={374.09}
          y={359.53}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={110.26}
          y={260.34}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={83.27}
          y={260.34}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={123.75}
          y={273.55}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={96.76}
          y={273.55}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={152.6}
          y={310.08}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={125.62}
          y={310.08}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={98.63}
          y={310.08}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={71.65}
          y={310.08}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={166.09}
          y={323.29}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={139.11}
          y={323.29}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={85.14}
          y={323.29}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={179.58}
          y={336.49}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={152.6}
          y={336.49}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={125.62}
          y={336.49}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={166.09}
          y={349.7}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={139.11}
          y={349.7}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={112.13}
          y={349.7}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={335.13}
          y={309.47}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={278.15}
          y={309.47}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={251.17}
          y={309.47}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={348.63}
          y={322.68}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={291.64}
          y={322.68}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={264.66}
          y={322.68}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={237.68}
          y={322.68}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={362.12}
          y={335.88}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={335.13}
          y={335.88}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={278.15}
          y={335.88}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={251.17}
          y={335.88}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={318.62}
          y={349.09}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={291.64}
          y={349.09}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={264.66}
          y={349.09}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={237.68}
          y={349.09}
          width={22.3}
          height={9.49}
          style={{
            fill: "#fafafa",
          }}
        />
        <rect
          x={309.03}
          y={155.11}
          width={96.32}
          height={110.17}
          transform="translate(714.38 420.4) rotate(180)"
          style={{
            fill: "#e0e0e0",
          }}
        />
        <rect
          x={309.21}
          y={155.11}
          width={92.61}
          height={110.17}
          transform="translate(711.03 420.4) rotate(180)"
          style={{
            fill: "#e0e0e0",
          }}
        />
        <rect
          x={306.03}
          y={155.11}
          width={96.32}
          height={110.17}
          transform="translate(708.37 420.4) rotate(180)"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={310.4}
          y={158.63}
          width={87.74}
          height={101.6}
          transform="translate(708.54 418.85) rotate(180)"
          style={{
            fill: "#fff",
          }}
        />
        <rect
          x={310.4}
          y={158.63}
          width={2.74}
          height={101.6}
          transform="translate(623.54 418.85) rotate(180)"
          style={{
            fill: "#e0e0e0",
          }}
        />
        <rect
          x={354.24}
          y={190.29}
          width={2.74}
          height={85.54}
          transform="translate(122.56 588.67) rotate(-90)"
          style={{
            fill: "#e0e0e0",
          }}
        />
        <rect
          x={310.4}
          y={227.09}
          width={89.21}
          height={4.6}
          transform="translate(710.01 458.77) rotate(180)"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={105.9}
          y={155.11}
          width={96.32}
          height={110.17}
          transform="translate(308.12 420.4) rotate(180)"
          style={{
            fill: "#e0e0e0",
          }}
        />
        <rect
          x={106.08}
          y={155.11}
          width={92.61}
          height={110.17}
          transform="translate(304.78 420.4) rotate(180)"
          style={{
            fill: "#e0e0e0",
          }}
        />
        <rect
          x={102.9}
          y={155.11}
          width={96.32}
          height={110.17}
          transform="translate(302.12 420.4) rotate(180)"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={107.27}
          y={158.63}
          width={87.74}
          height={101.6}
          transform="translate(302.28 418.85) rotate(180)"
          style={{
            fill: "#fff",
          }}
        />
        <rect
          x={107.27}
          y={158.63}
          width={2.74}
          height={101.6}
          transform="translate(217.28 418.85) rotate(180)"
          style={{
            fill: "#e0e0e0",
          }}
        />
        <rect
          x={151.12}
          y={190.29}
          width={2.74}
          height={85.54}
          transform="translate(-80.57 385.54) rotate(-90)"
          style={{
            fill: "#e0e0e0",
          }}
        />
        <rect
          x={107.27}
          y={227.09}
          width={89.21}
          height={4.6}
          transform="translate(303.75 458.77) rotate(180)"
          style={{
            fill: "#f0f0f0",
          }}
        />
      </g>
      <g id="freepik--Shadow--inject-64">
        <ellipse
          id="freepik--path--inject-64"
          cx={250}
          cy={416.24}
          rx={193.89}
          ry={11.32}
          style={{
            fill: "#f5f5f5",
          }}
        />
      </g>
      <g id="freepik--Plant--inject-64">
        <path
          d="M89.38,390.41S88,380.24,81.49,379.13s-4.55-6-6.24-7.94-6.12,3.71-2.33,9.16.69,3.73,2.33,8.79S90,398.85,89.38,390.41Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M89.38,390.41S88,380.24,81.49,379.13s-4.55-6-6.24-7.94-6.12,3.71-2.33,9.16.69,3.73,2.33,8.79S90,398.85,89.38,390.41Z"
          style={{
            fill: "#fff",
            isolation: "isolate",
            opacity: 0.2,
          }}
        />
        <path
          d="M89.11,397.47a.45.45,0,0,1-.41-.25c0-.11-5-10.69-2.4-27.55a70.69,70.69,0,0,1,4-14.09c3.15-8.58,5.63-15.36-.05-27.74-1.8-3.92-3.64-7.57-5.42-11.1-6.68-13.26-11.95-23.73-8-34.34a.45.45,0,0,1,.58-.26.43.43,0,0,1,.27.57c-3.79,10.24,1.41,20.56,8,33.62,1.78,3.54,3.62,7.2,5.43,11.13,5.84,12.72,3.17,20,.08,28.43a68.49,68.49,0,0,0-4,13.92c-2.57,16.56,2.28,26.91,2.33,27a.45.45,0,0,1-.21.6A.48.48,0,0,1,89.11,397.47Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M85.36,395s-4.21-6.34,1.86-9.6,7.29-.63,11-3,6.6-8,7.24-4.39-3.69,5.71-2.16,7.37S93.13,397.84,85.36,395Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M75.43,287.85s-4.09-12.45,5.23-12.71S79.7,286.52,75.43,287.85Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M83.53,297.43s2.78-11.33,9.39-8.94S90.12,297.64,83.53,297.43Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M72.31,298.41s1-9-6-9.22S62.39,297,72.31,298.41Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M77,316.84s-9.42-8.4-12.81-2.15S71.28,322.24,77,316.84Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M93.64,315.35s1.77-14,9.38-9.57S100.27,316.75,93.64,315.35Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M100.34,335.32s-5.62-14.09,4.82-12.5S107.71,331.9,100.34,335.32Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M84.32,340.35S75.51,330,68.77,333.26,67.54,346.87,84.32,340.35Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M92.32,363.36s7.51-16.68,14.19-11.06S102.25,366.32,92.32,363.36Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M81.41,362.38s2-12.94-5.45-11.9S70.48,359.44,81.41,362.38Z"
          style={{
            fill: "#263238",
          }}
        />
        <g
          style={{
            isolation: "isolate",
            opacity: 0.2,
          }}
        >
          <path
            d="M75.43,287.85s-4.09-12.45,5.23-12.71S79.7,286.52,75.43,287.85Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M83.53,297.43s2.78-11.33,9.39-8.94S90.12,297.64,83.53,297.43Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M72.31,298.41s1-9-6-9.22S62.39,297,72.31,298.41Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M77,316.84s-9.42-8.4-12.81-2.15S71.28,322.24,77,316.84Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M93.64,315.35s1.77-14,9.38-9.57S100.27,316.75,93.64,315.35Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M100.34,335.32s-5.62-14.09,4.82-12.5S107.71,331.9,100.34,335.32Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M84.32,340.35S75.51,330,68.77,333.26,67.54,346.87,84.32,340.35Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M92.32,363.36s7.51-16.68,14.19-11.06S102.25,366.32,92.32,363.36Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M81.41,362.38s2-12.94-5.45-11.9S70.48,359.44,81.41,362.38Z"
            style={{
              fill: "#fff",
            }}
          />
        </g>
        <path
          d="M79.47,304.92a.51.51,0,0,1-.22-.05c-5.52-3.09-9.49-8.93-9.65-9.17a.45.45,0,0,1,.12-.63.46.46,0,0,1,.63.12,32.63,32.63,0,0,0,8.94,8.66c.84-1.56,3.87-6.94,6.4-8.46a.45.45,0,1,1,.47.77c-2.69,1.61-6.26,8.45-6.29,8.52a.45.45,0,0,1-.27.22A.32.32,0,0,1,79.47,304.92Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M88,322.23a.42.42,0,0,1-.27-.09c-7.85-5.93-17.88-5.68-18-5.68a.48.48,0,0,1-.47-.44.45.45,0,0,1,.44-.46c.1,0,10.11-.26,18.15,5.56,1.05-1.67,5.08-7.74,10.26-10.79a.45.45,0,0,1,.46.78c-5.71,3.36-10.16,10.82-10.2,10.9a.46.46,0,0,1-.3.21Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M93.45,345.1a.46.46,0,0,1-.23-.06c-.11-.07-11.58-6.66-19.87-6.82a.46.46,0,0,1,0-.91c7.9.16,18.19,5.75,20,6.79,5.32-4.49,9.55-15.5,9.59-15.61a.45.45,0,1,1,.84.32c-.18.47-4.48,11.68-10.1,16.19A.48.48,0,0,1,93.45,345.1Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M86.81,368.74a.49.49,0,0,1-.23-.06c-4.33-2.48-9.93-13.33-10.16-13.79a.45.45,0,0,1,.8-.42c0,.11,5.41,10.5,9.48,13.22a25.79,25.79,0,0,1,12.6-8.05.45.45,0,0,1,.56.3.46.46,0,0,1-.3.57,24.65,24.65,0,0,0-12.38,8A.47.47,0,0,1,86.81,368.74Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M86,393.48s-3.61-5.74-7.68-6.56-5.83-5.51-7.33-2.69S73.63,396.41,86,393.48Z"
          style={{
            fill: "#263238",
          }}
        />
        <polygon
          points="71.31 416.01 101.39 416.01 103.4 391.72 69.3 391.72 71.31 416.01"
          style={{
            fill: `#${color}`,
          }}
        />
        <polygon
          points="68.59 396.32 104.1 396.32 104.56 391.17 68.14 391.17 68.59 396.32"
          style={{
            fill: `#${color}`,
          }}
        />
        <polygon
          points="68.59 396.32 104.1 396.32 104.56 391.17 68.14 391.17 68.59 396.32"
          style={{
            fill: "#fff",
            opacity: 0.30000000000000004,
          }}
        />
      </g>
      <g id="freepik--Frame--inject-64">
        <path
          d="M280.47,208.42l-1.52.29a.61.61,0,0,1-.51-1l.06,0c-.43.19-.86.38-1.31.55a18.12,18.12,0,0,0,0,2L287,261l-36.54,7.08a.78.78,0,0,1,0,.22.61.61,0,0,1-.65.55c-.59-.11-1.19-.22-1.79-.3l-48.38,9.38a.57.57,0,0,1-.08.24,7.66,7.66,0,0,1-.61.88,1,1,0,0,1-.06.53,17.7,17.7,0,0,1-1.45,3.23,1.33,1.33,0,0,1-.8.53,1.13,1.13,0,0,1-.63-.05,6.68,6.68,0,0,0-.5,1.55l77.52-15,.07-.09a4.48,4.48,0,0,1,3.4-1.21,6.13,6.13,0,0,1,1.55-1.3,1.89,1.89,0,0,1,1.8-.15,1.93,1.93,0,0,1,.83,1,22,22,0,0,1,2.3-.16l11-2.14-10.75-55.43A7,7,0,0,1,280.47,208.42Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M180.26,135.91l.36.07,7.23-1.4c.8-.35,1.59-.72,2.36-1.05a13.89,13.89,0,0,1,4.62-1.27,7.35,7.35,0,0,1,2.81.42l62.18-12.05,5.77,29.74a.61.61,0,0,1,1.09.2,17.3,17.3,0,0,1,.54,4,5.34,5.34,0,0,1-.44,1.88l9.81,50.63c.59-.22,1.17-.46,1.75-.72a2.6,2.6,0,0,1,1.11-1.73,1.87,1.87,0,0,1,1.34-.1,7.24,7.24,0,0,0,.5-1.64,9.45,9.45,0,0,0,.19-2.09l-.39-2.05c0-.11-.09-.21-.14-.31a.53.53,0,0,1,0-.5l-10.32-53.22a.57.57,0,0,1-.18,0,2,2,0,0,1-1.87-.87,3,3,0,0,1-.25-1.88c.1-.75.29-1.49.44-2.23a5.86,5.86,0,0,0,.14-2,.55.55,0,0,1,.26-.59l-4.58-23.61L174.92,131a10.56,10.56,0,0,1-5.49,1.43.54.54,0,0,1-.44-.28l-44.49,8.63a.64.64,0,0,1-.47.48l-2.34.45a1.93,1.93,0,0,1-.31,1,.56.56,0,0,1-.19.36,5.66,5.66,0,0,0-.44.4c-.27.25-.53.52-.81.76a.77.77,0,0,1-.34.19.62.62,0,0,1-.57-.2.64.64,0,0,1-.15-.44.85.85,0,0,1,0-.17c-.36,0-.72,0-1.07-.09a.62.62,0,0,1-.55-.65.6.6,0,0,1,.64-.55,8.75,8.75,0,0,0,1.14.09h.3a.57.57,0,0,0,.13,0h0a1.25,1.25,0,0,1-.76-.41.35.35,0,0,1,0-.08l-25.76,5a.72.72,0,0,1,.34.09.61.61,0,0,1,.16.83,5.57,5.57,0,0,1-.69.76l-.07.08h0c0-.05.06,0,0,0a1.67,1.67,0,0,0-.12.16s0,.08,0,.11a.36.36,0,0,0,0,.1.13.13,0,0,0,0,.06.62.62,0,0,1-.94.45l0,0h0a.58.58,0,0,0-.17-.06,1.39,1.39,0,0,0-.19-.06,1.53,1.53,0,0,1-.22.8.56.56,0,0,1-.52.24,5.41,5.41,0,0,1-.56,1.61,4.15,4.15,0,0,1-1.2,1.3l0,0a2,2,0,0,1,.24.42l38.3-7.42,0-.06a2.64,2.64,0,0,1,.62-.71,1.33,1.33,0,0,1,1.16-.21,2.78,2.78,0,0,1,.87.45l49.59-9.61A.6.6,0,0,1,180.26,135.91Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M113.57,294.64l-9-46.35a.6.6,0,0,1-.65.17.6.6,0,0,1-.37-.76,3.91,3.91,0,0,1,.67-1.21L92.43,185.58l-.11-.19a6.07,6.07,0,0,1-.51-1.56,4.22,4.22,0,0,0-.62,0,.61.61,0,0,1-.71-.64,6.47,6.47,0,0,1,.86-3.29l-4.78-24.64a3.17,3.17,0,0,1-.21-.41l-.24-.45a.54.54,0,0,1-.47-.3.6.6,0,0,1,.27-.8,2,2,0,0,0,.67-.43,2,2,0,0,0,.33-.52,4,4,0,0,0,.25-1.32,1.93,1.93,0,0,1-.32-.68,2.26,2.26,0,0,1,.09-1,.45.45,0,0,1,.13-.22c0-.38-.06-.76-.08-1.14l-7.65,1.48,21.83,112.62a.5.5,0,0,1,.65.16,3.28,3.28,0,0,1,.87,2.25,2.43,2.43,0,0,1-.14.48.57.57,0,0,1,.2.66,2.92,2.92,0,0,0-.27,1.86,12.06,12.06,0,0,0,.51,1.75,4.65,4.65,0,0,1,.14,3l5.7,29.43,1.62-.32a23.24,23.24,0,0,1,2.78-.73.5.5,0,0,1,.09-.4,9.2,9.2,0,0,1,.76-.78.65.65,0,0,1,0-.31,3.26,3.26,0,0,1,1.11-1.76,2.56,2.56,0,0,1,1.74-.49,4.08,4.08,0,0,1,2.94,1.87c1.64-.4,3.41-1,5-.19l62.16-12a2.73,2.73,0,0,1,.1-.48,4.58,4.58,0,0,1,1.37-2.09.59.59,0,0,1,.7-.05.54.54,0,0,1,.92,0l.25.3.11.1h0l.19.13,0,0h0l.21.1a2.85,2.85,0,0,1,0-1.78,2.51,2.51,0,0,1,1.38-1.46.59.59,0,0,1,.36-.93.61.61,0,0,1,.47,1.07l.07.08a.6.6,0,0,1-.27.8l-.23.11-.13.07s0,0,0,0l-.07.05-.16.14,0,0,0,0-.06.07-.1.15,0,.06a1.66,1.66,0,0,0-.06.16h0a3.82,3.82,0,0,1,.69-.7,2.48,2.48,0,0,1,.56-.29c.49-1.08.95-2.18,1.41-3.26Z"
          style={{
            fill: `#${color}`,
          }}
        />
      </g>
      <g id="freepik--Number--inject-64">
        <path
          d="M128.35,248a1.43,1.43,0,0,1,.37-.29l18.06-35.68-8.3,1.61-7.28,14.25a.5.5,0,0,1,.13.05.61.61,0,0,1,.16.83c-.17.25-.3.52-.45.78.13.08.25.18.38.25a.6.6,0,0,1,.25.62.61.61,0,0,1-.47.47L131,231l-.05,0-.06,0-.39.18-.09,0h0l-.14.11-.31.27-.16.17h0l-.07.08-.28.36c-.2.27-.38.55-.57.83a.62.62,0,0,1-.39.25l-10.65,20.86,1,5.38,4.73-.92a3.07,3.07,0,0,1,.74-1.19,2.94,2.94,0,0,1,.52-.39.41.41,0,0,1,0-.11,15.59,15.59,0,0,1,2.3-6.82.62.62,0,0,1,0-.67,1.47,1.47,0,0,0,.18-.94A.62.62,0,0,1,128.35,248Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M159.46,244.83a1.11,1.11,0,0,1-.12.43,2.92,2.92,0,0,1-1.37,1.32c-.12.18-.25.35-.36.52a.56.56,0,0,1-.26.2c0,.09-.07.19-.09.28a.6.6,0,1,1-1.13-.4,6.37,6.37,0,0,1,.38-1l-.57.11a.61.61,0,0,1-.7-.47.22.22,0,0,1,0-.14l-.35.07L152.63,234l-7.22,1.4,2.26,11.68-17.87,3.46c-.12.06-.23.15-.35.22l0,.08a.6.6,0,0,1-1,.61,6.82,6.82,0,0,0-.91.95.58.58,0,0,1,.59.17.59.59,0,0,1,0,.84l-.48.4a.65.65,0,0,1,0,.67h0v0l0,.07v0a.41.41,0,0,0,0,.11c0,.14,0,.29,0,.44a1.12,1.12,0,0,1-.33.71,1.51,1.51,0,0,1-.77.35h-.07a8.67,8.67,0,0,0,.13.89,4.36,4.36,0,0,1,.32.85l21.89-4.24L151.28,267l7.46-1.44-2.56-13.22,10.21-2-1.28-6.6Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M202.8,208.83l-.48.42c-.23.2-.45.4-.66.61l-.27.3,0,0-.15.18a1.25,1.25,0,0,1-2.21-1.12l.28-1.06s-.08-.08-.11-.14a1,1,0,0,1-.12-.49,1,1,0,0,1,.06-.48c0-.12.09-.24.14-.36a2.77,2.77,0,0,1-.54-.73l0-.08A20.32,20.32,0,0,0,185,204c-12.6,2.44-20,14.25-16.64,31.76s14.7,25.69,27.3,23.25a22,22,0,0,0,3.33-.91c.35-.5.7-1,1-1.49a1.24,1.24,0,0,1,2.09.08c8.47-4.72,12.87-15.19,10.11-29.43C210.62,219,207.24,212.8,202.8,208.83Zm-8.46,43.35c-8.45,1.64-15.63-4.14-18.3-17.9s1.83-21.8,10.28-23.43A12.53,12.53,0,0,1,198.53,215a1.45,1.45,0,0,1,.47-.22,1.27,1.27,0,0,1,1.47,1,6.89,6.89,0,0,1,.05,1.92,32.66,32.66,0,0,1,4,11C207.21,242.51,202.71,250.56,194.34,252.18Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M257.55,225.82l-2.26-11.68-7.23,1.4,2.27,11.68-20.67,4,19.77-39.06-8.3,1.6-7.43,14.56a16.6,16.6,0,0,1-1.09,5.31.61.61,0,0,1-1.13,0,6.8,6.8,0,0,1-2.06,4.06.54.54,0,0,1-.56.11l-8.41,16.48,1,5.38,12.44-2.41a8.18,8.18,0,0,1,3.65-1.65,18.68,18.68,0,0,1,3.57-.47,9,9,0,0,1,2.69.2l7.54-1.46,2.56,13.21,7.45-1.44-2.56-13.21,10.22-2-1.28-6.6Z"
          style={{
            fill: `#${color}`,
          }}
        />
      </g>
      <g id="freepik--Text--inject-64">
        <path
          d="M120.1,193.81l-1.18-6.08,12.2-2.36-.92-4.73L118,183l-1.1-5.71,13.81-2.67-1-4.88-19.85,3.85,1.7,8.76a2.53,2.53,0,0,1,.79.63,3.05,3.05,0,0,1,.65,1.66c.13,1.25-.18,2.54,0,3.75a.53.53,0,0,1-.2.55L115,199.86l20.34-3.94L134.4,191Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M155.92,183.17a8.65,8.65,0,0,0,3.85-9,.63.63,0,0,1-.38.19.59.59,0,0,1-.57-.33,3.69,3.69,0,0,1-.27-1c0-.05,0-.09,0-.14a2.13,2.13,0,0,1-.39-.8.62.62,0,0,1,.48-.7.45.45,0,0,1,.36.06c-1.91-4.27-6.51-6.17-12.53-5l-11.37,2.2,1.54,7.93a.51.51,0,0,1,.27.27,18.11,18.11,0,0,1,1.78,6.27.62.62,0,0,1-.48.7.5.5,0,0,1-.17,0L140.19,195l6.08-1.18-1.42-7.31,5.59-1.09,6.48,6.34,6.53-1.27Zm-7.06-2.49-5,1-1.78-9.15,5-1c3.71-.72,5.92.6,6.48,3.49S152.58,180,148.86,180.68Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M183.51,177.82a8.68,8.68,0,0,0,3.79-9.34c-1.14-5.89-6.23-8.68-13.25-7.32l-11.37,2.21,5.09,26.26,6.08-1.17-1.42-7.32,5.59-1.09,6.49,6.34,6.53-1.27Zm-7.06-2.48-5,1-1.77-9.16,4.95-1c.31-.06.61-.1.89-.13l.19-.16a2.16,2.16,0,0,1,.1-.44.88.88,0,0,1,.86-.57,2.33,2.33,0,0,1,1,.34.58.58,0,0,1,.19-.15,2.86,2.86,0,0,1,.07-.57.6.6,0,0,1,1.13.4c-.12.46.1.87.06,1.34a.93.93,0,0,1-.1.32,4.25,4.25,0,0,1,2.08,3.12C181.7,172.53,180.16,174.62,176.45,175.34Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M212.28,156.09l-.31.08a1,1,0,0,0-.21.1.27.27,0,0,0-.08.07v0a.69.69,0,0,1-.34.22.6.6,0,0,1-.45-.1.53.53,0,0,1-.25-.38.6.6,0,0,1,.09-.45s.06-.05.08-.08a15.76,15.76,0,0,0-7.92-.43c-8.3,1.61-13.25,8.57-11.74,16.37s8.71,12.41,17,10.8,13.26-8.53,11.74-16.37A13.32,13.32,0,0,0,212.28,156.09Zm-5.12,21a8.56,8.56,0,0,1-3.26-16.81,8.84,8.84,0,0,1,3.4,0c0-.14.09-.28.14-.4a.6.6,0,0,1,.69-.38.54.54,0,0,1,.44.45l.35-.09c.08-.25.16-.49.23-.74s.08-.29.11-.43a1.6,1.6,0,0,0,.05-.21l0-.07h0l.06-.36v-.13c-.06-.4.36-.88.79-.67l1.19.57a.62.62,0,0,1,.36.77,3.66,3.66,0,0,0-.18.46c0,.05,0,.11-.05.16v0s0,0,0,.07a4.36,4.36,0,0,0,0,.72,8.82,8.82,0,0,1-.1,1.7.52.52,0,0,1,0,.41.42.42,0,0,1-.11.18,8.94,8.94,0,0,1,2.51,4.77A8.16,8.16,0,0,1,207.16,177.09Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M246.66,157.75a.38.38,0,0,0-.1,0,2,2,0,0,1-.65-.38,3.19,3.19,0,0,1-.31.44.62.62,0,0,1-.83.16.6.6,0,0,1-.16-.83,5.78,5.78,0,0,0,1.08-2.74c-2-4.06-6.51-5.83-12.38-4.69l-11.37,2.2L227,178.15l6.08-1.18-1.42-7.32,5.59-1.08,6.49,6.33,6.53-1.26-7.53-7.3A8.62,8.62,0,0,0,246.66,157.75Zm-10.95,6.1-5,1L229,155.65l5-1c3.72-.72,5.92.61,6.48,3.5S239.42,163.13,235.71,163.85Z"
          style={{
            fill: `#${color}`,
          }}
        />
      </g>
      <g id="freepik--speech-bubble--inject-64">
        <path
          d="M193.77,316.66h42.74a8.84,8.84,0,0,1,8.84,8.85v15a8.84,8.84,0,0,1-8.84,8.85h-28.4l-13,13.9,7-13.9h-8.38a8.85,8.85,0,0,1-8.85-8.85v-15A8.85,8.85,0,0,1,193.77,316.66Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M193.77,316.66h42.74a8.84,8.84,0,0,1,8.84,8.85v15a8.84,8.84,0,0,1-8.84,8.85h-28.4l-13,13.9,7-13.9h-8.38a8.85,8.85,0,0,1-8.85-8.85v-15A8.85,8.85,0,0,1,193.77,316.66Z"
          style={{
            fill: "#fff",
            opacity: 0.7000000000000001,
          }}
        />
        <path
          d="M195.86,332.18a5.07,5.07,0,1,1,5.08,4.88A4.84,4.84,0,0,1,195.86,332.18Zm8.77,0a3.71,3.71,0,1,0-3.69,3.67A3.58,3.58,0,0,0,204.63,332.18Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M207.22,333.34A3.76,3.76,0,1,1,211,337,3.6,3.6,0,0,1,207.22,333.34Zm6.19,0a2.44,2.44,0,1,0-4.87,0,2.44,2.44,0,1,0,4.87,0Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M224,333.34a3.51,3.51,0,0,1-3.65,3.7,3.08,3.08,0,0,1-2.52-1.18v3.74h-1.31v-9.87h1.25v1.15a3.08,3.08,0,0,1,2.58-1.22A3.51,3.51,0,0,1,224,333.34Zm-1.32,0a2.43,2.43,0,1,0-2.44,2.55A2.39,2.39,0,0,0,222.7,333.34Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M224.92,336.19l.54-1a4.73,4.73,0,0,0,2.54.74c1.21,0,1.71-.36,1.71-1,0-1.62-4.56-.21-4.56-3.09,0-1.29,1.16-2.17,3-2.17a5.25,5.25,0,0,1,2.63.66l-.56,1a3.76,3.76,0,0,0-2.08-.57c-1.15,0-1.71.42-1.71,1,0,1.7,4.58.31,4.58,3.12,0,1.3-1.2,2.14-3.12,2.14A5.17,5.17,0,0,1,224.92,336.19Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M232.36,336.16a.87.87,0,0,1,.9-.87.85.85,0,0,1,.87.87.87.87,0,0,1-.87.88A.88.88,0,0,1,232.36,336.16Zm.12-8.74H234l-.26,6.52h-1Z"
          style={{
            fill: `#${color}`,
          }}
        />
      </g>
      <g id="freepik--character-part-2--inject-64">
        <path
          d="M443,160.78c0,3.26-17.83,6.06-43.37,7.29l-2.05.1-2.76.11c-3.83.15-7.81.26-11.91.35l-1.84,0h-1c-3.15.05-6.37.07-9.64.07-5.92,0-11.67-.08-17.18-.22l-4-.13c-10.05-.33-19.17-.9-26.85-1.64-15.07-1.46-24.58-3.59-24.58-6,0-3.59,21.6-6.61,51.31-7.61l2.33-.08q3.11-.09,6.31-.15c2.57-.05,5.19-.08,7.84-.1h4.83c7.48,0,14.7.12,21.49.36l3.22.11,4.19.18h0l1.09.06,2.79.14C426.75,155,443,157.67,443,160.78Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M443,160.78c0,3.26-17.83,6.06-43.37,7.29l-2.05.1-2.76.11c-3.83.15-7.81.26-11.91.35l-1.84,0h-1c-3.15.05-6.37.07-9.64.07-5.92,0-11.67-.08-17.18-.22l-4-.13c-10.05-.33-19.17-.9-26.85-1.64-15.07-1.46-24.58-3.59-24.58-6,0-1.06,1.89-2.08,5.32-3a129,129,0,0,0,19.26,3c7.68.74,16.8,1.31,26.85,1.64l4,.13c5.51.14,11.26.22,17.18.22,3.27,0,6.49,0,9.64-.07h1l1.84,0c4.1-.09,8.08-.2,11.91-.35l2.76-.11,2.05-.1c17.23-.83,30.94-2.37,38.05-4.29C441.06,158.71,443,159.71,443,160.78Z"
          style={{
            fill: "#fff",
            opacity: 0.4,
          }}
        />
        <path
          d="M365.5,152.84a5.65,5.65,0,0,1-1.28,3.25c-.83,1-1.54,1.76-2.35,2.59-.48.49-1,1-1.45,1.44-.32.33-.65.64-1,.94a55,55,0,0,1-5.36,4.36l-.33.23-.87.58a32.94,32.94,0,0,1-3.74,2.16c-.48.24-1,.47-1.48.69a26.25,26.25,0,0,1-3.69,1.24c-1.12.3-2.19.56-3.25.78-1.49.33-3,.63-4.43.9-2.73.53-5.45,1-8.19,1.4-2.33.35-4.66.68-7,1-1.88.23-3.76.43-5.66.59a2.64,2.64,0,0,1-.63,0,2.81,2.81,0,0,1-2.42-2.55,2.41,2.41,0,0,1,0-.38,2.79,2.79,0,0,1,1.85-2.5h0l.24-.08c2.59-.93,5.19-1.81,7.8-2.66l3.95-1.28c4-1.3,8-2.52,11.89-3.81,1-.32,1.93-.65,2.82-1a11.34,11.34,0,0,0,2.2-1c.45-.26.9-.55,1.36-.86a34.07,34.07,0,0,0,2.91-2.19c1.32-1.09,2.64-2.29,3.93-3.54q3.11-.09,6.31-.15C360.23,152.89,362.85,152.86,365.5,152.84Z"
          style={{
            fill: "#ffb573",
          }}
        />
        <path
          d="M365.62,156.88c-.07.21-.15.41-.23.61s-.17.4-.27.6a5.12,5.12,0,0,1-.3.57l-.33.57,0,.05q-.34.53-.72,1c-.27.35-.55.69-.85,1l-.28.31a17.13,17.13,0,0,1-1.38,1.32c-.16.15-.33.29-.5.42a1.35,1.35,0,0,1-.19.16l0,0h0a31,31,0,0,1-4.8,3.15l-.61.31h0l-.6.3-.23.1-.73-.64-.62-.56-1.4-1.24-7-6.17-.53-.47s2.14-2.39,5-5.18l2.33-.08q3.11-.09,6.31-.15c2.57-.05,5.19-.08,7.84-.1h.58A9.46,9.46,0,0,1,365.62,156.88Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M365.63,156.88a6.44,6.44,0,0,1-.24.61q-.12.3-.27.6a5.12,5.12,0,0,1-.3.57l-.33.57,0,.05q-.34.53-.72,1c-.27.35-.55.69-.85,1l-.28.31a17.13,17.13,0,0,1-1.38,1.32c-.16.15-.33.29-.5.42a1.35,1.35,0,0,1-.19.16l0,0a31,31,0,0,1-4.8,3.15l-.61.31h0l-.6.3-.23.1-.73-.64-.62-.56-1.4-1.24-7-6.17-.53-.47s2.14-2.39,5-5.18l2.33-.08q3.11-.09,6.31-.15c2.57-.05,5.19-.08,7.84-.1h.59A9.45,9.45,0,0,1,365.63,156.88Z"
          style={{
            fill: "#fff",
            opacity: 0.7000000000000001,
          }}
        />
        <path
          d="M360.5,163.56a30.46,30.46,0,0,1-6.27,3.87L351.48,165A24.54,24.54,0,0,1,359,154C360.25,156.32,360.65,161.17,360.5,163.56Z"
          style={{
            opacity: 0.1,
          }}
        />
        <path
          d="M315.28,169.53,306,168.11l.07,8.22s6.36,1.91,9.73-2.41Z"
          style={{
            fill: "#ebb376",
          }}
        />
        <polygon
          points="299.67 170.33 301.2 176.44 306.06 176.33 305.99 168.11 299.67 170.33"
          style={{
            fill: "#ebb376",
          }}
        />
        <path
          d="M399.23,153.47c-.08.21-.17.42-.25.64-.24.61-.47,1.23-.69,1.85-.14.38-.27.76-.4,1.15s-.27.78-.39,1.17c-.37,1.16-.73,2.33-1.08,3.52a1.33,1.33,0,0,1-.05.19c-.38,1.32-.72,2.67-1.05,4l0,.16c-.17.7-.35,1.4-.51,2.11-.08.32-.15.63-.22.95-.27,1.23-.53,2.47-.77,3.7h0c-.09.46-.18.92-.26,1.37s-.19,1-.27,1.43c0,.1,0,.2-.05.3-.05.26-.1.52-.14.78-.13.73-.25,1.45-.37,2.16,0,.11,0,.21-.05.32l-.12.75c-.24,1.46-.45,2.89-.64,4.28a2,2,0,0,0,0,.24c-.21,1.53-.39,3-.55,4.43-.07.59-.14,1.17-.2,1.74-.09.8-.17,1.57-.24,2.32-.06.55-.11,1.09-.16,1.61s-.09,1-.13,1.43-.08.92-.12,1.35c-.1,1.22-.18,2.32-.25,3.27,0,.42-.05.8-.08,1.16-.08,1.33-.12,2.26-.14,2.68,0,.17,0,.26,0,.26l-17.73-1.63-.26,0-3-.28-4.51-.42h0L359,202l-1.31-.12-6.63-.61c0-.63-.06-1.24-.07-1.86v0c0-.17,0-.34,0-.51-.19-5.46-.09-10.47.2-15q.24-3.56.62-6.77c.36-3.08.81-5.91,1.32-8.52.11-.59.23-1.17.35-1.73h0c.08-.39.16-.76.25-1.13a88,88,0,0,1,2.48-8.88h0c.48-1.4,1-2.67,1.43-3.82,2.57-.05,5.19-.08,7.84-.1h4.83c7.48,0,14.7.12,21.49.36l3.22.11Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M399.23,153.47c-.08.21-.17.42-.25.64-.24.61-.47,1.23-.69,1.85-.14.38-.27.76-.4,1.15s-.27.78-.39,1.17l0,.07c-.37,1.14-.71,2.28-1.05,3.45a1.33,1.33,0,0,1-.05.19c-.48,1.7-.93,3.43-1.35,5.17-.09.37-.17.75-.25,1.12s-.15.63-.22.95c-.27,1.23-.53,2.47-.77,3.7q-.15.69-.27,1.38c-.09.47-.18.95-.26,1.42,0,.1,0,.21-.06.31-.05.26-.1.52-.14.78-.13.73-.25,1.45-.37,2.16,0,.11,0,.21-.05.32l-.12.75c-.23,1.46-.45,2.89-.64,4.28a2,2,0,0,0,0,.24c-.21,1.53-.39,3-.55,4.43-.07.59-.14,1.17-.2,1.74-.09.8-.17,1.57-.24,2.32-.06.55-.11,1.09-.16,1.61s-.09,1-.13,1.43-.08.92-.12,1.35c-.1,1.22-.18,2.32-.25,3.27,0,.42-.05.8-.08,1.16-.08,1.33-.12,2.26-.14,2.68,0,.17,0,.26,0,.26l-17.73-1.63-.26,0-3-.28-4.51-.42h0L359,202l-1.31-.12-6.63-.61c0-.63-.06-1.24-.07-1.86v0c0-.17,0-.34,0-.51-.19-5.46-.09-10.47.2-15q.24-3.56.62-6.77c.36-3.08.81-5.91,1.32-8.52.11-.59.23-1.17.35-1.73h0c.08-.39.16-.76.25-1.13a88,88,0,0,1,2.48-8.88h0c.48-1.4,1-2.67,1.43-3.82,2.57-.05,5.19-.08,7.84-.1h4.83c7.48,0,14.7.12,21.49.36l3.22.11Z"
          style={{
            fill: "#fff",
            opacity: 0.7000000000000001,
          }}
        />
        <path d="M303.5,286.2S316,281,316.43,275.85a.89.89,0,0,1,1-.85l9.54.58a1.27,1.27,0,0,1,1.05.74l3.82,8.83-2.65.36-1.11-1.21-1,1.5c-14.36,2-20.58,2.65-23.54,2.15C303,286.74,303.5,286.2,303.5,286.2Z" />
        <path
          d="M362.09,234.27c-.55-.12-.85-.21-.85-.21l-45-5.62,13.38,47.65L316,277.39s-31.08-59.95-18.38-68.6,52.08.9,52.08.9l1.55-11.87,39.15,2.92C396.69,239.87,367.49,235.4,362.09,234.27Z"
          style={{
            fill: "#263238",
          }}
        />
        <path d="M349.12,371.93s13.48-1.29,15.39-6.13a.89.89,0,0,1,1.17-.53l9,3.34a1.27,1.27,0,0,1,.79,1l1.08,9.56-2.64-.43-.72-1.48-1.36,1.15c-14.3-2.31-20.46-3.46-23.14-4.81C348.44,372.28,349.12,371.93,349.12,371.93Z" />
        <path
          d="M390.79,214.92h0c-.06.24-1.69,7.74-3.82,18.49-.12.61-.24,1.25-.37,1.88-1,5.11-2.1,10.86-3.18,16.85-1.32,7.29-2.63,15-3.73,22.27a243.11,243.11,0,0,0-2.91,27.51c-.52,23.17-1.49,68.89-1.49,68.89l-12.47-2s-6.89-48.76-6.81-72.37c.06-15,3.78-45.91,6.32-65.13,1.28-9.86,2.26-16.66,2.26-16.66l14.13.13Z"
          style={{
            fill: "#263238",
          }}
        />
        <polygon
          points="389.81 204.55 390.64 204.62 390.6 201.91 389.87 201.86 389.81 204.55"
          style={{
            fill: "#263238",
          }}
        />
        <polygon
          points="359.03 202.16 375.73 203.46 375.81 200.77 359.17 199.49 359.03 202.16"
          style={{
            fill: "#263238",
          }}
        />
        <polygon
          points="357.88 199.39 350.82 198.84 350.57 201.5 357.72 202.06 357.88 199.39"
          style={{
            fill: "#263238",
          }}
        />
        <polygon
          points="388.58 201.76 377.09 200.87 377.04 203.56 388.49 204.45 388.58 201.76"
          style={{
            fill: "#263238",
          }}
        />
        <g
          style={{
            opacity: 0.2,
          }}
        >
          <polygon
            points="389.81 204.55 390.64 204.62 390.6 201.91 389.87 201.86 389.81 204.55"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="359.03 202.16 375.73 203.46 375.81 200.77 359.17 199.49 359.03 202.16"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="357.88 199.39 350.82 198.84 350.57 201.5 357.72 202.06 357.88 199.39"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="388.58 201.76 377.09 200.87 377.04 203.56 388.49 204.45 388.58 201.76"
            style={{
              fill: "#fff",
            }}
          />
        </g>
        <path
          d="M368.84,203.28h-.08l-4.16-.39a.84.84,0,0,1-.77-.88l.06-1.63a.82.82,0,0,1,.29-.6.8.8,0,0,1,.63-.21l4.14.32a.84.84,0,0,1,.78.87l0,1.7a.83.83,0,0,1-.28.61A.87.87,0,0,1,368.84,203.28Zm-4.1-3a.12.12,0,0,0-.08,0,.11.11,0,0,0,0,.09h0l-.07,1.63a.12.12,0,0,0,.12.13l4.16.39a.13.13,0,0,0,.1,0,.12.12,0,0,0,0-.09l0-1.7a.13.13,0,0,0-.12-.13l-4.14-.32Z"
          style={{
            fill: "#fff",
          }}
        />
        <path
          d="M368.78,201.9h0l-1.27-.12a.37.37,0,0,1-.33-.4.36.36,0,0,1,.39-.32l1.27.12a.36.36,0,0,1,0,.72Z"
          style={{
            fill: "#fff",
          }}
        />
        <path
          d="M396.37,162c-.48,1.7-.93,3.43-1.35,5.17-.16.69-.32,1.38-.47,2.07-.75,3.39-1.39,6.79-1.92,10.07l-.12.75c-6.87,2.21-14,.69-13.77-5.93.33-8.87,7.89-13,7.89-13C390,159.28,393.53,160,396.37,162Z"
          style={{
            opacity: 0.1,
          }}
        />
        <polygon
          points="329.16 416.01 338.41 416.01 308.33 178.31 299.08 178.31 329.16 416.01"
          style={{
            fill: "#263238",
          }}
        />
        <polygon
          points="329.16 416.01 338.41 416.01 308.33 178.31 299.08 178.31 329.16 416.01"
          style={{
            fill: "#fff",
            opacity: 0.4,
          }}
        />
        <path
          d="M309.82,213.54h29.41a1.94,1.94,0,0,0,1.69-1.2l1.87-4H306.26l1.87,4A1.94,1.94,0,0,0,309.82,213.54Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M309.82,213.54h29.41a1.94,1.94,0,0,0,1.69-1.2l1.87-4H306.26l1.87,4A1.94,1.94,0,0,0,309.82,213.54Z"
          style={{
            fill: "#fff",
            opacity: 0.4,
          }}
        />
        <rect
          x={304.85}
          y={206.02}
          width={39.36}
          height={4.25}
          rx={1.91}
          transform="translate(649.05 416.28) rotate(180)"
          style={{
            fill: "#263238",
          }}
        />
        <rect
          x={304.85}
          y={206.02}
          width={39.36}
          height={4.25}
          rx={1.91}
          transform="translate(649.05 416.28) rotate(180)"
          style={{
            fill: "#fff",
            opacity: 0.1,
          }}
        />
        <path
          d="M315.19,247.52h29.4a1.92,1.92,0,0,0,1.69-1.21l1.87-3.95H311.63l1.86,3.95A1.93,1.93,0,0,0,315.19,247.52Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M315.19,247.52h29.4a1.92,1.92,0,0,0,1.69-1.21l1.87-3.95H311.63l1.86,3.95A1.93,1.93,0,0,0,315.19,247.52Z"
          style={{
            fill: "#fff",
            opacity: 0.4,
          }}
        />
        <rect
          x={310.21}
          y={239.99}
          width={39.36}
          height={4.25}
          rx={1.91}
          transform="translate(659.78 484.23) rotate(180)"
          style={{
            fill: "#263238",
          }}
        />
        <rect
          x={310.21}
          y={239.99}
          width={39.36}
          height={4.25}
          rx={1.91}
          transform="translate(659.78 484.23) rotate(180)"
          style={{
            fill: "#fff",
            opacity: 0.1,
          }}
        />
        <path
          d="M320.55,292.49H350a1.92,1.92,0,0,0,1.7-1.2l1.86-4H317l1.87,4A1.91,1.91,0,0,0,320.55,292.49Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M320.55,292.49H350a1.92,1.92,0,0,0,1.7-1.2l1.86-4H317l1.87,4A1.91,1.91,0,0,0,320.55,292.49Z"
          style={{
            fill: "#fff",
            opacity: 0.4,
          }}
        />
        <rect
          x={315.57}
          y={284.97}
          width={39.36}
          height={4.25}
          rx={1.91}
          transform="translate(670.5 574.19) rotate(180)"
          style={{
            fill: "#263238",
          }}
        />
        <rect
          x={315.57}
          y={284.97}
          width={39.36}
          height={4.25}
          rx={1.91}
          transform="translate(670.5 574.19) rotate(180)"
          style={{
            fill: "#fff",
            opacity: 0.1,
          }}
        />
        <path
          d="M325.91,337.47h29.41a1.92,1.92,0,0,0,1.69-1.2l1.87-4H322.35l1.87,4A1.92,1.92,0,0,0,325.91,337.47Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M325.91,337.47h29.41a1.92,1.92,0,0,0,1.69-1.2l1.87-4H322.35l1.87,4A1.92,1.92,0,0,0,325.91,337.47Z"
          style={{
            fill: "#fff",
            opacity: 0.4,
          }}
        />
        <rect
          x={320.94}
          y={329.95}
          width={39.36}
          height={4.25}
          rx={1.91}
          transform="translate(681.23 664.15) rotate(180)"
          style={{
            fill: "#263238",
          }}
        />
        <rect
          x={320.94}
          y={329.95}
          width={39.36}
          height={4.25}
          rx={1.91}
          transform="translate(681.23 664.15) rotate(180)"
          style={{
            fill: "#fff",
            opacity: 0.1,
          }}
        />
        <path
          d="M331.28,382.45h29.4a1.91,1.91,0,0,0,1.69-1.2l1.87-4H327.71l1.87,4A1.92,1.92,0,0,0,331.28,382.45Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M331.28,382.45h29.4a1.91,1.91,0,0,0,1.69-1.2l1.87-4H327.71l1.87,4A1.92,1.92,0,0,0,331.28,382.45Z"
          style={{
            fill: "#fff",
            opacity: 0.4,
          }}
        />
        <rect
          x={326.3}
          y={374.93}
          width={39.36}
          height={4.25}
          rx={1.91}
          transform="translate(691.95 754.11) rotate(180)"
          style={{
            fill: "#263238",
          }}
        />
        <rect
          x={326.3}
          y={374.93}
          width={39.36}
          height={4.25}
          rx={1.91}
          transform="translate(691.95 754.11) rotate(180)"
          style={{
            fill: "#fff",
            opacity: 0.1,
          }}
        />
        <polygon
          points="364.87 416.01 374.12 416.01 344.04 178.31 334.79 178.31 364.87 416.01"
          style={{
            fill: "#263238",
          }}
        />
        <polygon
          points="364.87 416.01 374.12 416.01 344.04 178.31 334.79 178.31 364.87 416.01"
          style={{
            fill: "#fff",
            opacity: 0.7000000000000001,
          }}
        />
        <polygon
          points="267.76 416.01 258.51 416.01 288.58 178.31 297.83 178.31 267.76 416.01"
          style={{
            fill: "#263238",
          }}
        />
        <polygon
          points="267.76 416.01 258.51 416.01 288.58 178.31 297.83 178.31 267.76 416.01"
          style={{
            fill: "#fff",
            opacity: 0.4,
          }}
        />
        <path
          d="M322.8,213.54H293.4a1.94,1.94,0,0,1-1.7-1.2l-1.86-4h36.52l-1.87,4A1.92,1.92,0,0,1,322.8,213.54Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M322.8,213.54H293.4a1.94,1.94,0,0,1-1.7-1.2l-1.86-4h36.52l-1.87,4A1.92,1.92,0,0,1,322.8,213.54Z"
          style={{
            fill: "#fff",
            opacity: 0.5,
          }}
        />
        <rect
          x={288.42}
          y={206.02}
          width={39.36}
          height={4.25}
          rx={1.91}
          style={{
            fill: "#263238",
          }}
        />
        <rect
          x={288.42}
          y={206.02}
          width={39.36}
          height={4.25}
          rx={1.91}
          style={{
            fill: "#fff",
            opacity: 0.2,
          }}
        />
        <path
          d="M317.44,247.52H288a1.93,1.93,0,0,1-1.69-1.21l-1.87-3.95H321l-1.87,3.95A1.93,1.93,0,0,1,317.44,247.52Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M317.44,247.52H288a1.93,1.93,0,0,1-1.69-1.21l-1.87-3.95H321l-1.87,3.95A1.93,1.93,0,0,1,317.44,247.52Z"
          style={{
            fill: "#fff",
            opacity: 0.5,
          }}
        />
        <rect
          x={283.06}
          y={239.99}
          width={39.36}
          height={4.25}
          rx={1.91}
          style={{
            fill: "#263238",
          }}
        />
        <rect
          x={283.06}
          y={239.99}
          width={39.36}
          height={4.25}
          rx={1.91}
          style={{
            fill: "#fff",
            opacity: 0.2,
          }}
        />
        <path
          d="M312.07,292.49h-29.4a1.91,1.91,0,0,1-1.69-1.2l-1.87-4h36.52l-1.86,4A1.92,1.92,0,0,1,312.07,292.49Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M312.07,292.49h-29.4a1.91,1.91,0,0,1-1.69-1.2l-1.87-4h36.52l-1.86,4A1.92,1.92,0,0,1,312.07,292.49Z"
          style={{
            fill: "#fff",
            opacity: 0.5,
          }}
        />
        <rect
          x={277.69}
          y={284.97}
          width={39.36}
          height={4.25}
          rx={1.91}
          style={{
            fill: "#263238",
          }}
        />
        <rect
          x={277.69}
          y={284.97}
          width={39.36}
          height={4.25}
          rx={1.91}
          style={{
            fill: "#fff",
            opacity: 0.2,
          }}
        />
        <path
          d="M306.71,337.47h-29.4a1.93,1.93,0,0,1-1.7-1.2l-1.86-4h36.52l-1.87,4A1.91,1.91,0,0,1,306.71,337.47Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M306.71,337.47h-29.4a1.93,1.93,0,0,1-1.7-1.2l-1.86-4h36.52l-1.87,4A1.91,1.91,0,0,1,306.71,337.47Z"
          style={{
            fill: "#fff",
            opacity: 0.5,
          }}
        />
        <rect
          x={272.33}
          y={329.95}
          width={39.36}
          height={4.25}
          rx={1.91}
          style={{
            fill: "#263238",
          }}
        />
        <rect
          x={272.33}
          y={329.95}
          width={39.36}
          height={4.25}
          rx={1.91}
          style={{
            fill: "#fff",
            opacity: 0.2,
          }}
        />
        <path
          d="M301.35,382.45H271.94a1.92,1.92,0,0,1-1.69-1.2l-1.87-4h36.53l-1.87,4A1.91,1.91,0,0,1,301.35,382.45Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M301.35,382.45H271.94a1.92,1.92,0,0,1-1.69-1.2l-1.87-4h36.53l-1.87,4A1.91,1.91,0,0,1,301.35,382.45Z"
          style={{
            fill: "#fff",
            opacity: 0.5,
          }}
        />
        <rect
          x={266.97}
          y={374.93}
          width={39.36}
          height={4.25}
          rx={1.91}
          style={{
            fill: "#263238",
          }}
        />
        <rect
          x={266.97}
          y={374.93}
          width={39.36}
          height={4.25}
          rx={1.91}
          style={{
            fill: "#fff",
            opacity: 0.2,
          }}
        />
        <polygon
          points="303.46 416.01 294.21 416.01 324.29 178.31 333.54 178.31 303.46 416.01"
          style={{
            fill: "#263238",
          }}
        />
        <polygon
          points="303.46 416.01 294.21 416.01 324.29 178.31 333.54 178.31 303.46 416.01"
          style={{
            fill: "#fff",
            opacity: 0.7000000000000001,
          }}
        />
        <path
          d="M345.52,179.52H286.9a1.94,1.94,0,0,1-1.69-1.2l-1.87-4h65.74l-1.86,4A2,2,0,0,1,345.52,179.52Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M345.52,179.52H286.9a1.94,1.94,0,0,1-1.69-1.2l-1.87-4h65.74l-1.86,4A2,2,0,0,1,345.52,179.52Z"
          style={{
            fill: "#fff",
            opacity: 0.5,
          }}
        />
        <rect
          x={281.93}
          y={172}
          width={68.57}
          height={4.25}
          rx={1.91}
          style={{
            fill: "#263238",
          }}
        />
        <rect
          x={281.93}
          y={172}
          width={68.57}
          height={4.25}
          rx={1.91}
          style={{
            fill: "#fff",
            opacity: 0.2,
          }}
        />
        <path
          d="M349.62,178.53l-7.69-5.42-3.6,7.39s4.84,4.54,9.78,2.18Z"
          style={{
            fill: "#ebb376",
          }}
        />
        <polygon
          points="335.29 172.28 333.93 178.43 338.33 180.5 341.93 173.11 335.29 172.28"
          style={{
            fill: "#ebb376",
          }}
        />
        <path
          d="M402.19,161.43c-1.51,2.33-3.05,4.56-4.66,6.74-1.21,1.61-2.45,3.2-3.75,4.76h0c-.39.48-.79.95-1.19,1.42-.79.91-1.59,1.82-2.43,2.72-1,1.12-2.13,2.22-3.3,3.3l-.67.6h0a31.63,31.63,0,0,1-2.75,2.27,19.79,19.79,0,0,1-4.65,2.59,10.47,10.47,0,0,1-1.51.46c-.49.12-.91.18-1.36.26a23.09,23.09,0,0,1-2.39.2c-1.51.05-2.92,0-4.31-.07-2.76-.15-5.42-.52-8.05-.91-3.36-.53-6.66-1.19-9.93-2-1.21-.28-2.41-.59-3.61-.91l-1.91-.53a2.79,2.79,0,0,1-2-2.86,3.13,3.13,0,0,1,.1-.6,2.76,2.76,0,0,1,2.28-2,2.19,2.19,0,0,1,.51,0h.05l.7,0c1.51.05,3,.11,4.53.15,3.37.12,6.73.21,10,.26,2.51,0,5,0,7.41-.08,1.2-.05,2.37-.15,3.43-.3a6.9,6.9,0,0,0,2.3-.57,11.21,11.21,0,0,0,1.94-1.41c.6-.52,1.23-1.1,1.85-1.73.18-.17.35-.35.52-.53.35-.36.69-.74,1-1.12.86-.94,1.69-1.92,2.52-2.93.37-.46.75-.92,1.12-1.39,1.8-2.27,3.59-4.66,5.32-7.07,1.18-1.63,2.34-3.28,3.46-4.9l.06-.1a5.49,5.49,0,0,1,2.22-1.88l4.19.18h0l1.09.06a3,3,0,0,1,.33.21A5.64,5.64,0,0,1,402.19,161.43Z"
          style={{
            fill: "#ffb573",
          }}
        />
        <path
          d="M399.58,168.07a58.59,58.59,0,0,1-6.33,7.66s0,0,0,0c-.62.64-1.28,1.3-2,2l-1.12-.63-9.81-5.52-.91-.51s.64-1,1.59-2.39c1.19-1.8,2.88-4.37,4.45-6.79,1.22-1.9,2.36-3.71,3.11-5a12.94,12.94,0,0,1,3.24-3.7l3.22.11,4.19.18h0l1.09.06,2.79.14C404.38,156.66,404.1,161.39,399.58,168.07Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M399.58,168.07a58.59,58.59,0,0,1-6.33,7.66s0,0,0,0c-.62.64-1.28,1.3-2,2l-1.12-.63-9.81-5.52-.91-.51s.64-1,1.59-2.39c1.19-1.8,2.88-4.37,4.45-6.79,1.22-1.9,2.36-3.71,3.11-5a12.94,12.94,0,0,1,3.24-3.7l3.22.11,4.19.18h0l1.09.06,2.79.14C404.38,156.66,404.1,161.39,399.58,168.07Z"
          style={{
            fill: "#fff",
            opacity: 0.7000000000000001,
          }}
        />
      </g>
      <g id="freepik--character-part-1--inject-64">
        <path
          d="M253.34,415.84a1,1,0,0,1,0,.17.24.24,0,0,1,0,.08.88.88,0,0,1-.05.15.93.93,0,0,1-.11.19,1.3,1.3,0,0,1-.15.2,5.09,5.09,0,0,1-1.64,1.07l-.49.21c-.48.2-1,.39-1.63.58-.38.12-.78.23-1.21.35-7,1.89-20.45,3.41-37.36,4.25l-2,.1-3.28.14-3.37.12-2.33.08c-1.85.05-3.72.1-5.62.13l-.69,0-1.29,0-1.25,0c-1.75,0-3.52,0-5.3.06h-4.83c-5.84,0-11.53-.07-17-.22h0l-2.42-.08c-.7,0-1.4,0-2.09-.06l-3.22-.11-4.19-.18h0l-1.09-.06-2.79-.14c-4.59-.26-8.9-.56-12.86-.92-9.48-.84-17-1.93-21.65-3.19l-.4-.11-.44-.13c-.75-.22-1.4-.45-2-.67-.21-.09-.41-.17-.59-.26a8.55,8.55,0,0,1-.87-.47,1.85,1.85,0,0,1-.27-.19l-.22-.17-.21-.21a1.25,1.25,0,0,1-.15-.19,1.09,1.09,0,0,1-.16-.35l0-.11a.66.66,0,0,1,0-.14c0-3.26,17.83-6.06,43.37-7.29l2.05-.1,2.76-.11,4.24-.15,2.29-.07,5.38-.13h.29l1.55,0h1.05c1.58,0,3.17,0,4.78-.05h.48c1.45,0,2.91,0,4.38,0,5.92,0,11.67.08,17.18.22l4,.13,1.7.06h.17l1.86.07,1.22.05,3.16.14h0l3,.15c5.72.31,11,.7,15.76,1.16C243.83,411.33,253.34,413.46,253.34,415.84Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M108.1,415.84c0-3.26,17.83-6.06,43.37-7.29l2.05-.1,2.76-.11c3.83-.15,7.81-.26,11.91-.35l1.84,0h1.05c3.15-.05,6.37-.07,9.64-.07,5.92,0,11.67.08,17.18.22l4,.13c10.05.33,19.17.9,26.85,1.64,15.07,1.46,24.58,3.59,24.58,6,0,1.06-1.89,2.08-5.32,3a129,129,0,0,0-19.26-3c-7.68-.74-16.8-1.31-26.85-1.64l-4-.13c-5.51-.14-11.26-.22-17.18-.22-3.27,0-6.49,0-9.64.07H170l-1.84,0c-4.1.09-8.08.2-11.91.35l-2.76.11-2.05.1c-17.23.83-30.94,2.37-38,4.29C110,417.91,108.1,416.91,108.1,415.84Z"
          style={{
            fill: "#fff",
            opacity: 0.4,
          }}
        />
        <path
          d="M174.61,412.15c-.55.66-1.06,1.23-1.57,1.78-.26.27-.51.54-.78.81-.48.49-.95,1-1.44,1.44-.32.32-.65.63-1,.94a53.69,53.69,0,0,1-5.37,4.36l-.32.22c-.28.2-.57.39-.87.59h0c-.63.42-1.28.83-2,1.21-.7,0-1.4,0-2.09-.06l-3.22-.11-4.19-.18h0l-1.09-.06-2.79-.14c-4.59-.26-8.9-.56-12.86-.92l1.56-.5c4-1.3,8-2.52,11.9-3.8,1-.33,1.93-.65,2.82-1a11.34,11.34,0,0,0,2.2-1c.44-.26.9-.55,1.36-.86.24-.16.49-.33.74-.52h0c.72-.51,1.44-1.08,2.15-1.67,1.44-1.18,2.85-2.48,4.25-3.83l.75-.74c.45-.43.89-.86,1.32-1.31l2-2,.12-.13a5.66,5.66,0,0,1,4.72-1.7,5.6,5.6,0,0,1,4.93,4.95h0A5.66,5.66,0,0,1,174.61,412.15Z"
          style={{
            fill: "#ffb573",
          }}
        />
        <path
          d="M172.37,400.67c-5.58-.13-18,13.74-18,13.74l10.25,9.08s9.15-4,11.39-10.55S172.37,400.67,172.37,400.67Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M176,412.94a15.56,15.56,0,0,1-5.13,6.68,30.46,30.46,0,0,1-6.27,3.87l-2.75-2.44-7.5-6.64s12.41-13.87,18-13.74C172.37,400.67,178.27,406.35,176,412.94Z"
          style={{
            fill: "#fff",
            opacity: 0.7000000000000001,
          }}
        />
        <path
          d="M170.9,419.62a30.46,30.46,0,0,1-6.27,3.87l-2.75-2.44a24.54,24.54,0,0,1,7.55-10.95C170.65,412.38,171.05,417.23,170.9,419.62Z"
          style={{
            opacity: 0.1,
          }}
        />
        <path
          d="M210.91,406.52v0h0c-.06.13-.12.25-.17.38-.16.35-.31.7-.46,1.06a5.72,5.72,0,0,0-.24.57.61.61,0,0,0-.05.12,3.71,3.71,0,0,0-.19.46c-.17.4-.33.81-.49,1.23s-.39,1.05-.58,1.58c0,0,0,.05,0,.07-.14.38-.27.76-.4,1.15s-.27.78-.39,1.17a.56.56,0,0,0,0,.12c-.12.36-.24.72-.35,1.08-.23.76-.46,1.52-.68,2.3l-.06.21c-.48,1.7-.93,3.43-1.35,5.17a.43.43,0,0,0,0,.11l-3.37.12-2.33.08c-1.85.05-3.72.1-5.62.13l-.69,0-1.29,0-1.25,0c-1.75,0-3.52,0-5.3.06h-4.83c-5.84,0-11.53-.07-17-.22h0l.15-.74h0c0-.16.07-.31.11-.46s.06-.3.1-.45a2.25,2.25,0,0,0,.05-.22q.67-3,1.4-5.47c.18-.58.34-1.13.51-1.68.06-.17.11-.34.17-.51h0c.13-.41.26-.8.39-1.18,0,0,0,0,0,0h0l.21-.61h0c.11-.31.22-.62.33-.91s.25-.69.37-1l.33-.84h0l.6-1.45c.46-1.1.91-2.06,1.33-2.91.08-.14.15-.29.22-.43l.42-.81.49-.89a1,1,0,0,0,.08-.14c.17-.31.34-.59.49-.83l.39-.62.47-.68h0a71.12,71.12,0,0,1,8.93-.29c.63,0,1.27.05,1.92.09s1.24.1,1.92.18c1.43.16,3,.38,4.67.65l3.14.54c1.05.19,2.09.38,3.09.58l.53.11h0c.79.15,1.56.31,2.27.46.49.1,1,.21,1.46.33.25.06.49.11.73.18l.73.18c.91.23,1.81.48,2.66.72l.83.25,1.55.48,1.36.44c1.55.51,2.58.89,2.73.94Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M210.92,406.52v0h0c-.06.13-.12.25-.17.38-.16.35-.32.7-.46,1.06q-.14.28-.24.57h0a.56.56,0,0,0,0,.12,3.71,3.71,0,0,0-.19.46c-.17.4-.33.81-.49,1.23s-.4,1.05-.58,1.58c0,0,0,.05,0,.07-.14.38-.27.76-.4,1.15s-.27.78-.39,1.17l0,.07a.09.09,0,0,0,0,0c-.12.36-.24.72-.35,1.08-.23.76-.46,1.52-.68,2.3l-.06.21c-.48,1.7-.93,3.43-1.35,5.17a.43.43,0,0,0,0,.11l-3.37.12-2.33.08c-1.85.05-3.72.1-5.62.13l-.69,0-1.29,0-1.25,0c-1.75,0-3.52,0-5.3.06h-4.83c-5.84,0-11.53-.07-17-.22l.15-.73h0c.07-.32.13-.62.2-.92a2.25,2.25,0,0,0,.05-.22q.67-3,1.4-5.47c.18-.58.34-1.13.51-1.68.06-.17.11-.34.17-.51h0c.13-.41.26-.8.39-1.18,0,0,0,0,0,0h0l.21-.61h0c.11-.31.22-.62.33-.91s.25-.69.37-1l.33-.84h0l.6-1.45h0c.46-1.1.91-2.06,1.32-2.91.08-.14.15-.29.22-.43l.42-.81.49-.89a1,1,0,0,0,.08-.14l.49-.83.39-.62.47-.68h0a71.12,71.12,0,0,1,8.93-.29c.63,0,1.27.05,1.92.09s1.24.1,1.92.18c1.43.16,3,.38,4.67.65l3.14.54c1.05.19,2.09.38,3.09.58l.53.11h0c.79.15,1.56.31,2.27.46.49.1,1,.21,1.46.33.25.06.49.11.73.18l.73.17c.91.23,1.81.48,2.66.73l.83.25,1.55.48,1.36.44c1.55.51,2.58.89,2.73.94h0Z"
          style={{
            fill: "#fff",
            opacity: 0.7000000000000001,
          }}
        />
        <path
          d="M192.83,388.45s.31,10.64,1.32,12.22,4.66,2.31,4.66,2.31-8.77,4.36-12.87,3.54-6.07-6.17-6.07-6.17,2.86.11,3.56-.28-.55-7.44-.55-7.44Z"
          style={{
            fill: "#ebb376",
          }}
        />
        <path
          d="M183.72,395.28l-.36.51c-.22-1.65-.48-3.16-.48-3.16l9.94-4.18s.26,8.57,1,11.47C189.8,400.34,185.1,397.36,183.72,395.28Z"
          style={{
            opacity: 0.2,
          }}
        />
        <path
          d="M189.43,370.58c2.72,1.42,6.61,6,2.35,7.53S184.76,368.15,189.43,370.58Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M177.34,388.28a66.65,66.65,0,0,0,6.8,7.15,11.69,11.69,0,0,0,6.19,2.69c8,1,13.18-7.52,9.95-14.55a24.6,24.6,0,0,0-1.25-2.35c-3.51-5.78-10.26-11.85-16.94-9.87A10.39,10.39,0,0,0,177.34,388.28Z"
          style={{
            fill: "#ebb376",
          }}
        />
        <path
          d="M180.63,387.8,181,389a22.22,22.22,0,0,0,3.18,6.45,11.69,11.69,0,0,0,6.19,2.69c8,1,13.18-7.52,9.95-14.55a24.6,24.6,0,0,0-1.25-2.35s.6,2.82.08,4.13c0,0-3-.77-4.48.46,0,0-3.18-.87-5.88,2.5A6.81,6.81,0,0,1,180.63,387.8Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M180.63,387.8,181,389a22.22,22.22,0,0,0,3.18,6.45,11.69,11.69,0,0,0,6.19,2.69c8,1,13.18-7.52,9.95-14.55a24.6,24.6,0,0,0-1.25-2.35s.6,2.82.08,4.13c0,0-3-.77-4.48.46,0,0-3.18-.87-5.88,2.5A6.81,6.81,0,0,1,180.63,387.8Z"
          style={{
            fill: "#fff",
            opacity: 0.5,
          }}
        />
        <path
          d="M169,384.54c-3.63-3.05-.19-7.46,3.61-9a2.12,2.12,0,0,0,1-.82l4.51,6.93c-.41.19-.82.38-1.21.53,0,0,5.27,4.66,4.76,9.3C174.31,393.56,167.21,389.77,169,384.54Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M178.93,395a7,7,0,0,0,5.33,1.07,3.57,3.57,0,0,0,.52-.14h0c1.52-1.43-3-6.46-3.21-6.72h0a3.49,3.49,0,0,0-2.9.4A3.24,3.24,0,0,0,178.93,395Z"
          style={{
            fill: "#ebb376",
          }}
        />
        <path
          d="M187.56,382.8c.37.48.93.67,1.25.42s.26-.85-.12-1.33-.94-.67-1.25-.42S187.18,382.32,187.56,382.8Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M193,378.47c.37.48.93.67,1.25.42s.26-.84-.12-1.33-.94-.67-1.25-.41S192.59,378,193,378.47Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M191.43,380.09a21.21,21.21,0,0,0,5.44,2.11c-.24,1.42-2,2.21-2,2.21Z"
          style={{
            fill: "#d58745",
          }}
        />
        <path
          d="M185.41,379.08a.34.34,0,0,0-.18.31,2.89,2.89,0,0,1-1.14,2.35.38.38,0,0,0-.11.5.36.36,0,0,0,.5.11,3.62,3.62,0,0,0,1.46-3,.36.36,0,0,0-.35-.36A.35.35,0,0,0,185.41,379.08Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M193.85,374.56a.33.33,0,0,0,.12-.17.36.36,0,0,0-.21-.46,3.51,3.51,0,0,0-3.25.37.36.36,0,0,0,.43.57,2.79,2.79,0,0,1,2.58-.26A.35.35,0,0,0,193.85,374.56Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M194.84,391a4.8,4.8,0,0,0,.57-.26l.49-3.94s-.24,0-.61,0c-1.24,0-4,.28-4.74,1.7a2.63,2.63,0,0,0-.19.51,2.78,2.78,0,0,0,.13,1.92C190.94,391.77,192.14,392.21,194.84,391Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M190.36,389l4.81-1.74.12-.47c-1.24,0-4,.28-4.74,1.7A2.63,2.63,0,0,0,190.36,389Z"
          style={{
            fill: "#fff",
          }}
        />
        <path
          d="M194.84,391c-.84-1.4-3.08-.68-4.35-.06C190.94,391.77,192.14,392.21,194.84,391Z"
          style={{
            fill: "#de5753",
          }}
        />
        <path
          d="M169,384.54c-1.48-1.14-3.64-4.21-.74-7.41s6.33-4.78,4.71-7.1,1.25-4.29,3.27-2.78c0,0,4.75-7.77,8.13-1.8,0,0,3.31-2.28,5.34.17s2.45,5.07-.22,6-2.92,1.22-2.46,2.77-1.86,2.83-1.86,2.83,1.44-.92.62-2A3.6,3.6,0,0,1,183,378c-2.59.81-1.39,3.33-1.39,3.33s-1.39.54-1.69-1.44c0,0-1.08,4.42-4,5.52S171.2,386.25,169,384.54Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M206.77,418.05c-.48,1.7-.93,3.43-1.35,5.17a.43.43,0,0,0,0,.11l-3.37.12-2.33.08c-1.85.05-3.72.1-5.62.13l-.69,0-1.29,0-1.25,0a17.27,17.27,0,0,1,5-5.79,10.94,10.94,0,0,1,1.16-.77,8.51,8.51,0,0,1,2.67-.93A9.7,9.7,0,0,1,206.77,418.05Z"
          style={{
            opacity: 0.1,
          }}
        />
        <path
          d="M213.48,414.74a5.59,5.59,0,0,1-.89,2.75c-1.27,2-2.56,3.86-3.91,5.7l-3.28.14-3.37.12-2.33.08c-1.85.05-3.72.1-5.62.13.11-.12.2-.24.3-.36,1.8-2.27,3.59-4.66,5.32-7.07.49-.67,1-1.35,1.44-2,.69-1,1.35-1.92,2-2.87l.07-.1a5.64,5.64,0,0,1,6.57-2.09,5.49,5.49,0,0,1,1.27.66A5.62,5.62,0,0,1,213.48,414.74Z"
          style={{
            fill: "#ffb573",
          }}
        />
        <path
          d="M214.05,414.77a21.58,21.58,0,0,1-3.39,8.32l-2,.1-3.28.14-3.37.12-2.33.08c-1.85.05-3.72.1-5.62.13l-.69,0-1.29,0c1.11-1.68,2.48-3.78,3.77-5.77.88-1.38,1.72-2.7,2.4-3.82l.71-1.17a12.79,12.79,0,0,1,4.64-4.65h0a13.46,13.46,0,0,1,7.27-1.74,6.79,6.79,0,0,1,2.1,2.16A8.94,8.94,0,0,1,214.05,414.77Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M214.05,414.77a21.58,21.58,0,0,1-3.39,8.32l-2,.1-3.28.14-3.37.12-2.33.08c-1.85.05-3.72.1-5.62.13l-.69,0-1.29,0c1.11-1.68,2.48-3.78,3.77-5.77.88-1.38,1.72-2.7,2.4-3.82l.71-1.17a12.79,12.79,0,0,1,4.64-4.65h0a13.46,13.46,0,0,1,7.27-1.74,6.79,6.79,0,0,1,2.1,2.16A8.94,8.94,0,0,1,214.05,414.77Z"
          style={{
            fill: "#fff",
            opacity: 0.7000000000000001,
          }}
        />
      </g>
    </svg>
  )
}
