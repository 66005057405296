import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Stack,
  Button,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Backdrop
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PixIcon from '@mui/icons-material/Pix';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import format from 'date-fns/format'

import { UserContext } from 'contexts';
import { toast, setMask } from 'utils';
import { useDialog } from 'components/Dialog';

import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { chargeInstallments, getContract, getBillingReceipt } from 'services/requests/contract';

import Iconify from 'components/Iconify';
import InstallmentCard from 'components/DashCards/ClientInstallmentItem';
import ContractClientCard from 'components/DashCards/ClientContractItem';

dayjs.extend(utc);

export default function ContractDetail() {
  const { t } = useTranslation('common');
  const userContext = useContext(UserContext);

  const params = useParams()
  const id = params?.id
  const { state } = userContext;

  const dialog = useDialog();

  const [data, setData] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingCharge, setLoadingCharge] = useState(null);
  const [selectedInstallmentsToPay, setSelectedInstallmentsToPay] = useState([]);
  const [loadingProof, setLoadingProof] = useState(false);

  const getData = () => {
    setLoadingData(true)

    getContract({ id })
      .then((res) => setData(res))
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingData(false));
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const selectInstallment = id => {
    const indexToRemove = selectedInstallmentsToPay.findIndex(item => item === id)
    const arr = [...selectedInstallmentsToPay]
    if (indexToRemove > -1) {
      arr.splice(indexToRemove, 1)
    } else {
      arr.push(id)
    }
    setSelectedInstallmentsToPay([...arr])
  }

  const handleConfirmPayInstallment = ids => {
    if (ids.length > 1) {
      dialog({
        content: (
          <Typography variant="subtitle1">
            Deseja realmente realizar o pagamento de {ids.length} parcelas?
          </Typography >
        )
      })
        .then(() => payInstallment(ids))
        .catch(() => { })

      return
    }

    payInstallment(ids)
  }

  const payInstallment = (ids) => {
    setLoadingCharge(true)

    chargeInstallments({ body: { installments: [...ids] } })
      .then((res) => {
        setSelectedInstallmentsToPay([])
        dialog({
          hideCancel: true,
          confirmationText: 'Ok',
          content: (
            <>
              <Stack direction="column" alignItems="left">
                <Typography variant="subtitle1" textAlign="center">
                  Pagamento via Pix
                </Typography>
                <Typography variant="body2" textAlign="center" mb={2}>
                  {setMask('money', res.value)}
                </Typography>

                <Stepper activeStep={-1} orientation="vertical">
                  <Step>
                    <StepLabel>
                      Acesse o APP de seu Banco
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>
                      No menu busque por Pix
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>
                      Selecione a opção "Pagar QR Code Pix" ou "Pix Copia e Cola"
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>
                      Use o botão abaixo para copiar o código "Copia e Cola" e cole no APP de seu Banco ou escaneie o QR Code abaixo
                    </StepLabel>
                  </Step>
                </Stepper>

                <Button
                  sx={{ my: 3 }}
                  variant="outlined"
                  onClick={() => {
                    window.navigator.clipboard.writeText(res.openPixBrCode)
                    toast('Código PIX copiado com sucesso!', 'success')
                  }}
                >
                  Copiar Código Pix Copia e Cola
                </Button>

                <Stack direction="column" alignItems="center">
                  <QRCodeSVG value={res.openPixBrCode} />
                </Stack>
              </Stack>
            </>
          ),
        })
          .then(() => { })
          .catch(() => { })
      })
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingCharge(false))
  }

  const viewBillingReceipt = async (row) => {
    setLoadingProof(true)
    let payment = null

    try {
      payment = await getBillingReceipt({ id: row.openPixIdentifier })
    } catch (e) {
      if (row.status === 'payed') {
        payment = {
          charge: {
            status: 'COMPLETED',
            value: row.value * 100,
            paidAt: row.openPixPayedDate,
            identifier: row.openPixIdentifier,
            transactionID: row.openPixIdentifier
          }
        }
      }
    }

    setLoadingProof(false)

    if (payment?.charge?.status === 'COMPLETED') {
      dialog({
        title: '',
        hideCancel: true,
        confirmationText: 'Ok',
        content: (
          <>
            <Stack direction="column" alignItems="center" textAlign="center">
              <img src="/assets/logo_openpix.svg" className="logo-openpix-comprovante" alt="OpenPix" />

              <CheckCircleIcon color="success" sx={{ fontSize: 70 }} />

              <Typography variant="subtitle1" mt={2}>
                Pagamento Confirmado
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Valor Pago
              </Typography>
              <Typography variant="body1" mt={0}>
                {setMask('money', payment.charge.value / 100)}
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Detalhes da transação
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Destinatário
              </Typography>
              <Typography variant="body2" mt={0}>
                {state.data.provider.name}
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Data e Hora
              </Typography>
              <Typography variant="body2" mt={0}>
                {format(new Date(payment.charge.paidAt), `dd/MM/yyyy 'às' HH:mm:ss`)}
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                Identificador
              </Typography>
              <Typography variant="body2" mt={0}>
                {payment.charge.identifier}
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" textAlign="center">
              <Typography variant="subtitle1" mt={3}>
                ID da Transação
              </Typography>
              <Typography variant="body2" mt={0}>
                {payment.charge.transactionID}
              </Typography>
            </Stack>
          </>
        ),
      })
        .then(() => { })
        .catch(() => { })
    }
  }

  return (
    <>
      <Helmet>
        <title>Detalhes do Contrato | { process.env.REACT_APP_PROJECT_NAME } </title>
      </Helmet>

      <Grid container rowSpacing={2} columnSpacing={2} px={2}>
        <Grid xs={12} item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={loadingData}
            onClick={() => getData()}
            type="link"
            size="small"
            endIcon={<Iconify icon="material-symbols:refresh" />}
          >
            Atualizar
          </Button>
        </Grid>

        {
          !loadingData ? (
            data.contract.id ? (
              <>
                <Grid item columnSpacing={2} xs={12} md={6} lg={7}>
                  <Grid item xs={12} mb={3}>
                    <ContractClientCard data={data.contract} minimal />
                  </Grid>

                  {
                    (data.contract.status === 'borrowed' || data.contract.status === 'finished') ? (
                      <>
                        {
                          data.installments.filter(item => item.status === 'pending').length > 0 ? (
                            <>
                              <Grid item xs={12}>
                                {
                                  data.contract.status === 'borrowed' ? (
                                    <>
                                      <Typography variant="subtitle1">
                                        Parcelas em aberto
                                      </Typography>
                                      <Typography variant="body2" mb={3}>
                                        Selecione as parcelas que deseja efetuar o pagamento
                                      </Typography>
                                    </>
                                  ) : (
                                    <Typography variant="subtitle1" mb={3}>
                                      Parcelas
                                    </Typography>
                                  )
                                }
                              </Grid>
                              {
                                selectedInstallmentsToPay?.length > 0 ? (
                                  <Grid item xs={12} mb={3}>
                                    <LoadingButton
                                      fullWidth
                                      variant="contained"
                                      size="large"
                                      endIcon={<PixIcon />}
                                      onClick={() => handleConfirmPayInstallment(selectedInstallmentsToPay)}
                                      loading={loadingCharge}
                                    >
                                      Pagar {selectedInstallmentsToPay?.length > 1 ? 'parcelas selecionadas' : 'parcela selecionada'}
                                    </LoadingButton>
                                  </Grid>
                                ) : null
                              }
                              {
                                data.installments
                                  .filter(item => item.status === 'pending')
                                  .map((item, key) => (
                                    <Grid item xs={12} md={12} key={key} mb={2}>
                                      <InstallmentCard
                                        onClick={data.contract.status === 'borrowed' ? () => selectInstallment(item.id) : undefined}
                                        highlight={data.contract.status === 'borrowed' ? selectedInstallmentsToPay.includes(item.id) : false}
                                        data={item}
                                      />
                                    </Grid>
                                  ))
                              }
                            </>
                          ) : null
                        }
                        {
                          data.installments.filter(item => item.status === 'payed').length > 0 ? (
                            <>
                              <Grid item xs={12}>
                                <Typography variant="subtitle1" mb={3}>
                                  Parcelas pagas
                                </Typography>
                              </Grid>
                              {
                                data.installments
                                  .filter(item => item.status === 'payed')
                                  .map((item, key) => (
                                    <Grid item xs={12} md={12} key={key} mb={2}>
                                      <InstallmentCard
                                        onClick={() => (item.status === 'payed' && item?.openPixIdentifier) ? viewBillingReceipt(item) : null}
                                        data={item}
                                      />
                                    </Grid>
                                  ))
                              }
                            </>
                          ) : null
                        }
                      </>
                    ) : null
                  }
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" mb={3}>
                      Resumo do contrato
                    </Typography>
                  </Grid>
                  <Card mb={5}>
                    <CardContent>
                      <Grid container columnSpacing={2} mb={3}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" mt={1}>
                            Valor Emprestado
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {setMask('money', data.contract.amount)}
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="subtitle2" mt={1}>
                            Parcelamento
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {`${data.installments.length} parcela${data.installments.length > 0 ? 's' : ''} de ${setMask('money', data.installments[0].value)}`}
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="subtitle2" mt={1}>
                            Valor Final Devido
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {setMask('money', data.contract.value)}
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="subtitle2" mt={1}>
                            Mutuante
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            {data.lender.company.name}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="subtitle2" mt={1}>
                            Vendedor
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            {data.comissioner.company.name}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="subtitle2" mt={1}>
                            Objetivo
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            {data.contract.goal}
                          </Typography>
                        </Grid>
                      </Grid>

                      {
                        data.contract.status === 'pendingSign' ? (
                          <Grid item xs={12} mt={3}>
                            <Button
                              startIcon={<BorderColorIcon />}
                              variant="contained"
                              onClick={() => window.open(`${process.env.REACT_APP_ZAPSIGN_URL}/${data.contract.zapSignSignerBorrowed}`, '_blank')}
                            >
                              Assinar contrato
                            </Button>
                          </Grid>
                        ) : data.contract.signedFile ? (
                          <Grid item xs={12} mt={3}>
                            <Button
                              startIcon={<GetAppTwoToneIcon />}
                              variant="contained"
                              onClick={() => window.open(`${process.env.REACT_APP_S3_URL}/${process.env.REACT_APP_S3_FOLDER_CONTRACTS}/${data.contract.signedFile}`, '_blank')}
                            >
                              Baixar contrato
                            </Button>
                          </Grid>
                        ) : null
                      }
                    </CardContent>
                  </Card>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} md={12}>
                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', my: 5 }}>
                  <Box
                    component="img"
                    src="/assets/illustrations/illustration_empty_content.svg"
                    sx={{ height: 160, mx: 'auto', mb: 5 }}
                  />
                  <Typography variant="h6" paragraph mb={3}>
                    {t('no_data')}
                  </Typography>
                </Box>
              </Grid>
            )
          ) : (
            <Grid item xs={12} md={12}>
              <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', my: 5 }}>
                <CircularProgress size={80} sx={{ my: 12 }} />
              </Box>
            </Grid>
          )
        }

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingProof}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </>
  )
}
