export async function getAddressFromCep({ cep }) {
  const result = await fetch(`https://brasilapi.com.br/api/cep/v2/${cep}`)
  return result.json()
}

export async function getHolidays() {
  const year = new Date().getFullYear()

  const result = await fetch(`https://brasilapi.com.br/api/feriados/v1/${year}`)
  return result.json()
}
