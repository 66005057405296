import PropTypes from 'prop-types'
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextField from 'components/TextField';

import { enums } from 'utils'

export default function AcceptInviteFormStepPersonal({ values, handleChange, touched, errors, isEditing = false }) {
  const { t } = useTranslation('common');

  return (
    <Stack spacing={3}>
      <TextField
        name="name"
        label={t('auth.acceptInvite.form.name')}
        value={values.name}
        onChange={handleChange}
        error={touched.name && Boolean(errors.name)}
        helperText={touched.name && errors.name}
      />

      {
        isEditing ? (
          <>
            <TextField
              name="email"
              label={t('auth.acceptInvite.form.email')}
              value={values.email}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              name="phone"
              mask="(99) 99999-9999"
              label={t('auth.acceptInvite.form.phone')}
              value={values.phone}
              onChange={handleChange}
              error={touched.phone && Boolean(errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </>
        ) : null
      }

      <TextField
        name="cpf"
        mask="999.999.999-99"
        label={t('auth.acceptInvite.form.cpf')}
        value={values.cpf}
        onChange={handleChange}
        error={touched.cpf && Boolean(errors.cpf)}
        helperText={touched.cpf && errors.cpf}
      />

      <TextField
        name="rg"
        label={t('auth.acceptInvite.form.rg')}
        value={values.rg}
        onChange={handleChange}
        error={touched.rg && Boolean(errors.rg)}
        helperText={touched.rg && errors.rg}
      />

      <TextField
        name="birthDate"
        mask="99/99/9999"
        label={t('auth.acceptInvite.form.birthDate')}
        value={values.birthDate}
        onChange={handleChange}
        error={touched.birthDate && Boolean(errors.birthDate)}
        helperText={touched.birthDate && errors.birthDate}
      />

      <TextField
        name="profession"
        label={t('auth.acceptInvite.form.profession')}
        value={values.profession}
        onChange={handleChange}
        error={touched.profession && Boolean(errors.profession)}
        helperText={touched.profession && errors.profession}
      />

      <TextField
        name="nationality"
        label={t('auth.acceptInvite.form.nationality')}
        value={values.nationality}
        onChange={handleChange}
        error={touched.nationality && Boolean(errors.nationality)}
        helperText={touched.nationality && errors.nationality}
      />

      <FormControl fullWidth>
        <InputLabel id="maritalStatus">
          {t('auth.acceptInvite.form.maritalStatus')}
        </InputLabel>
        <Select
          labelId="maritalStatus"
          name="maritalStatus"
          id="maritalStatus"
          label={t('auth.acceptInvite.form.maritalStatus')}
          value={values.maritalStatus}
          onChange={handleChange}
          error={touched.maritalStatus && Boolean(errors.maritalStatus)}
          sx={{ textAlign: 'left' }}
        >
          {
            enums.maritalStatus.map((item, key) => (
              <MenuItem value={item} key={key}>
                {t(`enum.maritalStatus.${item}`)}
              </MenuItem>
            ))
          }
        </Select>
        {
          touched.maritalStatus && Boolean(errors.maritalStatus) ? (
            <FormHelperText
              error={touched.maritalStatus && Boolean(errors.maritalStatus)}
            >
              {touched.maritalStatus && errors.maritalStatus}
            </FormHelperText>
          ) : null
        }
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="propertyRegime">
          {t('auth.acceptInvite.form.propertyRegime')}
        </InputLabel>
        <Select
          labelId="propertyRegime"
          name="propertyRegime"
          id="propertyRegime"
          label={t('auth.acceptInvite.form.propertyRegime')}
          value={values.propertyRegime}
          onChange={handleChange}
          error={touched.propertyRegime && Boolean(errors.propertyRegime)}
          sx={{ textAlign: 'left' }}
        >
          {
            enums.propertyRegime.map((item, key) => (
              <MenuItem value={item} key={key}>
                {t(`enum.propertyRegime.${item}`)}
              </MenuItem>
            ))
          }
        </Select>
        {
          touched.propertyRegime && Boolean(errors.propertyRegime) ? (
            <FormHelperText
              error={touched.propertyRegime && Boolean(errors.propertyRegime)}
            >
              {touched.propertyRegime && errors.propertyRegime}
            </FormHelperText>
          ) : null
        }
      </FormControl>
    </Stack>
  );
}

AcceptInviteFormStepPersonal.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  isEditing: PropTypes.bool,
}
