import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Button, Divider, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { startOfDay, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz'
import { DatePicker } from '@mui/x-date-pickers';

import AdminMetricsItem from 'components/DashCards/AdminMetricsItem';
import List from 'components/List';

import { getCommission, getDashboard } from 'services/requests/dashboard';
import { setMask, toast } from 'utils';

const now = startOfDay(new Date())

export default function SellerDashboard() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const [dateCommission, setDateCommission] = useState(now);
  const [dashCommission, setDashCommission] = useState({});
  const [loadingDashCommission, setLoadingDashCommission] = useState(true);

  const [dashContracts, setDashContracts] = useState([]);
  const [loadingDashContracts, setLoadingDashContracts] = useState(true);

  const getDataCommission = dateBase => {
    setLoadingDashCommission(true)
    const date = format(dateBase, 'yyyy-MM-dd')

    getCommission({ date })
      .then((res) => setDashCommission(res))
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingDashCommission(false))
  }

  useEffect(() => {
    getDataCommission(dateCommission)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateCommission])

  const getDataDashboard = () => {
    setLoadingDashContracts(true)

    getDashboard()
      .then((res) => setDashContracts(res))
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingDashContracts(false))
  }

  useEffect(() => {
    getDataDashboard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const listConfig = {
    onClick: row => navigate(`/contratos/detalhe/${row.id}`),
    fields: [
      { key: 'user_name', label: 'Cliente', desktopColumnSize: 3, isPrimary: true },
      { key: 'amount', label: 'Valor pactuado', desktopColumnSize: 2, customValue: row => setMask('money', row.amount), sm_prefix: 'Valor total: ' },
      { key: 'installmentValue', label: 'Valor da parcela', desktopColumnSize: 2, customValue: row => setMask('money', row.installment_value), sm_prefix: 'Parcelas de: ' },
      { key: 'installmentscount', label: 'Parcelas', desktopColumnSize: 2, customValue: row => `Pagas ${row.installment_payed}/${row.installment_max}` },
      { key: 'phone', label: 'Telefone', desktopColumnSize: 2, customValue: row => setMask('(99) 99999-9999', row.user_phone), sm_prefix: 'Telefone: ' },
      {
        key: 'copy_phone', label: '', desktopColumnSize: 2, customValue: row => (
            <Button sx={{ color: 'error.main', display: { xs: 'flex', md: 'none' } }} onClick={e => {
              e.stopPropagation();
              navigator.clipboard.writeText(row.user_phone);
              toast('Telefone copiado', 'success');
            }}>
              Copiar Telefone
            </Button>
        )
      },
    ]
  }

  const formatedDateCommission = format(dateCommission, 'MM/yyyy')
  const formatedNow = format(now, 'MM/yyyy')

  return (
    <>
      <Helmet>
        <title>Dashboard | { process.env.REACT_APP_PROJECT_NAME }</title>
      </Helmet>

      <Grid container rowSpacing={2} columnSpacing={2} my={1}>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, pt: 1.25 }}>Comissão</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DatePicker
            label="Mês de referência"
            inputFormat="MMMM, YYYY"
            views={['month', 'year']}
            value={dateCommission}
            onChange={newValue => setDateCommission(startOfDay(new Date(newValue)))}
            renderInput={params => <TextField {...params} />}
          />
        </Grid>

        <Grid item xs={12}>
          <AdminMetricsItem
            color="primary"
            isLoading={loadingDashCommission}
            value={dashCommission.totalCommision}
            title="Minha comissão"
            description={formatedDateCommission === formatedNow ? `entre ${dashCommission.startDate} e o dia de hoje` : `entre ${dashCommission.startDate} e ${dashCommission.endDate}`}
            refresh={() => getDataCommission(dateCommission)}
          />
        </Grid>

        <Grid item xs={12} my={2}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 700 }}>Contratos com parcelas atrasadas</Typography>
        </Grid>

        <Grid item xs={12} mt={1}>
          <List
            config={listConfig}
            data={dashContracts || []}
            refresh={getDataDashboard}
            loading={loadingDashContracts}
          />
        </Grid>
      </Grid>
    </>
  )
}
