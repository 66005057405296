import { styled, alpha } from '@mui/material/styles'
import { Box } from '@mui/material'

import Scrollbar from 'components/Scrollbar'

const HEADER_MOBILE = 64
const HEADER_DESKTOP = 92.7

export const StyledScrollbar = styled(Scrollbar)(({ theme }) => ({
  backgroundColor: theme.palette.grey[0]
}))

export const StyledLogo = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 2.5),
  display: 'inline-flex',
  width: '100%',
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: HEADER_DESKTOP
  },
}))

export const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(3)
  }
}))
