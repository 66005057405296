import PropTypes from 'prop-types'
import { InputAdornment, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';

import TextField from 'components/TextField';

import { updateContractParams } from 'services/requests/contract';
import { toast } from 'utils';

const initialValues = {
  templateZapSign: '',
  remunerationFee: '',
  comissionFee: '',
  IOFRate: '',
  IOFDailyDebitRate: '',
  sellerSplitRate: ''
};

export default function ContractParamsForm({ defaultValues }) {
  const validationSchema = yup.object({
    templateZapSign: yup.string()
      .required('Obrigatório'),
    remunerationFee: yup.string()
      .required('Obrigatório'),
    comissionFee: yup.string()
      .required('Obrigatório'),
    IOFRate: yup.string()
      .required('Obrigatório'),
    IOFDailyDebitRate: yup.string()
      .required('Obrigatório'),
    sellerSplitRate: yup.string()
      .required('Obrigatório'),
    latePaymentInterest: yup.string()
      .required('Obrigatório'),
    latePaymentFine: yup.string()
      .required('Obrigatório'),
    installmentDelayDays: yup.string()
      .required('Obrigatório'),
  });

  const formik = useFormik({
    initialValues: defaultValues || initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      updateContractParams({ body: { ...values } })
        .then(() => {
          toast('Configurações atualizadas com sucesso', 'success');
        })
        .catch((err) => toast(err, 'error'))
        .then(() => setSubmitting(false));
    },
  });

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="templateZapSign"
          label="Template ZapSign (ID template)"
          value={formik.values.templateZapSign}
          onChange={formik.handleChange}
          error={formik.touched.templateZapSign && Boolean(formik.errors.templateZapSign)}
          helperText={formik.touched.templateZapSign && formik.errors.templateZapSign}
        />

        <TextField
          type="number"
          name="remunerationFee"
          label="Juros remuneratório"
          value={formik.values.remunerationFee}
          onChange={formik.handleChange}
          error={formik.touched.remunerationFee && Boolean(formik.errors.remunerationFee)}
          helperText={formik.touched.remunerationFee && formik.errors.remunerationFee}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />

        <TextField
          type="number"
          name="comissionFee"
          label="Taxa administrativa de vendas"
          value={formik.values.comissionFee}
          onChange={formik.handleChange}
          error={formik.touched.comissionFee && Boolean(formik.errors.comissionFee)}
          helperText={formik.touched.comissionFee && formik.errors.comissionFee}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />

        <TextField
          type="number"
          name="IOFRate"
          label="IOF adicional"
          value={formik.values.IOFRate}
          onChange={formik.handleChange}
          error={formik.touched.IOFRate && Boolean(formik.errors.IOFRate)}
          helperText={formik.touched.IOFRate && formik.errors.IOFRate}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />

        <TextField
          type="number"
          name="IOFDailyDebitRate"
          label="IOF saldo devedor diário"
          value={formik.values.IOFDailyDebitRate}
          onChange={formik.handleChange}
          error={formik.touched.IOFDailyDebitRate && Boolean(formik.errors.IOFDailyDebitRate)}
          helperText={formik.touched.IOFDailyDebitRate && formik.errors.IOFDailyDebitRate}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />

        <TextField
          type="number"
          name="sellerSplitRate"
          label="Comissão do vendedor (Split)"
          value={formik.values.sellerSplitRate}
          onChange={formik.handleChange}
          error={formik.touched.sellerSplitRate && Boolean(formik.errors.sellerSplitRate)}
          helperText={formik.touched.sellerSplitRate && formik.errors.sellerSplitRate}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />

        <TextField
          type="number"
          name="latePaymentInterest"
          label="Juros de mora"
          value={formik.values.latePaymentInterest}
          onChange={formik.handleChange}
          error={formik.touched.latePaymentInterest && Boolean(formik.errors.latePaymentInterest)}
          helperText={formik.touched.latePaymentInterest && formik.errors.latePaymentInterest}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />

        <TextField
          type="number"
          name="latePaymentFine"
          label="Multa de mora"
          value={formik.values.latePaymentFine}
          onChange={formik.handleChange}
          error={formik.touched.latePaymentFine && Boolean(formik.errors.latePaymentFine)}
          helperText={formik.touched.latePaymentFine && formik.errors.latePaymentFine}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />

        <TextField
          type="number"
          name="installmentDelayDays"
          label="Dias de carência no atraso da parcela"
          value={formik.values.installmentDelayDays}
          onChange={formik.handleChange}
          error={formik.touched.installmentDelayDays && Boolean(formik.errors.installmentDelayDays)}
          helperText={formik.touched.installmentDelayDays && formik.errors.installmentDelayDays}
          InputProps={{
            endAdornment: <InputAdornment position="end">dias</InputAdornment>
          }}
        />
      </Stack>

      <Stack direction="column" alignItems="end" justifyContent="end">
        <LoadingButton
          size="large"
          type="submit"
          color="secondary"
          variant="contained"
          onClick={() => formik.handleSubmit()}
          loading={formik.isSubmitting}
          sx={{ my: 2 }}
        >
          Salvar
        </LoadingButton>

        <Typography variant="body2" sx={{ maxWidth: '360px', textAlign: 'right' }}>
          As novas configurações salvas passam a ser válida apenas para contratos futuros, apartir do momento em que esses novos valores são salvos.
        </Typography>
      </Stack>
    </>
  );
}

ContractParamsForm.propTypes = {
  defaultValues: PropTypes.object
}
