import { addDays, isWeekend, format } from 'date-fns'

import { getHolidays } from 'services/requests/external'

const isHoliday = (date, holidays) => {
  const parsedDate = format(new Date(date), 'yyyy-MM-dd')
  return holidays.includes(parsedDate)
}

const nextWorkday = (date, daysToAdd, holidays) => {
  let businessDaysAdded = 0

  while (businessDaysAdded < daysToAdd) {
    date = addDays(date, 1)

    if (!isWeekend(date) && !isHoliday(date, holidays)) {
      businessDaysAdded++
    }
  }

  return date
}

export const delayDays = async (date, daysToAdd) => {
  const holidays = await getHolidays()
  const parsedHolidays = holidays.map(item => item.date)

  const result = nextWorkday(date, daysToAdd, parsedHolidays)
  return result
}
