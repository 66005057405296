import { API } from 'services';

export function getValues() {
  return API({
    method: 'GET',
    endpoint: '/dash',
  });
}


export function getContracts() {
  return API({
    method: 'GET',
    endpoint: `/contract`,
  });
}

export function getInstallments() {
  return API({
    method: 'GET',
    endpoint: `/installment`,
  });
}

export function getDashboardProvider() {
  return API({
    method: 'GET',
    endpoint: `/dash/contracts/provider`,
  });
}

export function getDashboard() {
  return API({
    method: 'GET',
    endpoint: `/dash/contracts`,
  });
}

export function getMetrics({ date }) {
  return API({
    method: 'GET',
    endpoint: `/dash/metrics?date=${date}`,
  });
}

export function getInstallmentReport({ startDate, endDate }) {
  return API({
    method: 'GET',
    endpoint: `/dash/downloads/installments?startDate=${startDate}&endDate=${endDate}`,
    responseType: 'blob',
  });
}

export function getContractReport({ startDate, endDate }) {
  return API({
    method: 'GET',
    endpoint: `/dash/downloads/contracts?startDate=${startDate}&endDate=${endDate}`,
    responseType: 'blob',
  });
}

export function getPaymentsReport({ startDate, endDate }) {
  return API({
    method: 'GET',
    endpoint: `/dash/downloads/payments?startDate=${startDate}&endDate=${endDate}`,
    responseType: 'blob',
  });
}

export function getManagerialReport({ startDate, endDate }) {
  return API({
    method: 'GET',
    endpoint: `/dash/downloads/managerial?startDate=${startDate}&endDate=${endDate}`,
    responseType: 'blob',
  });
}

export function getCommission({ date }) {
  return API({
    method: 'GET',
    endpoint: `/dash/commission?date=${date}`,
  });
}

export function getCommissionProvider({ date }) {
  return API({
    method: 'GET',
    endpoint: `/dash/commission/provider?date=${date}`,
  });
}

export function getCommissionReport({ date }) {
  return API({
    method: 'GET',
    endpoint: `/dash/downloads/commissions/${date}`,
    responseType: 'blob',
  });
}
