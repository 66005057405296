import { Mask } from 'utils/_mask/Mask'
import { MoneyMask } from 'utils/_mask/Money'

/*
  the mask pattern:
    9 - accept digit.
    A - accept alpha.
    S - accept alphanumeric.
    * - accept all, EXCEPT white space.
*/

export const setMask = (mask, value = '') => {
  let received = null

  if (mask === 'money') {
    const masker = new MoneyMask()
    received = masker.getValue(value)
  } else if (mask === 'moneyInt') {
    const masker = new MoneyMask()
    received = masker.getValue(Math.round(value), { precision: 0 })
  } else {
    const masker = new Mask()
    received = masker.getValue(value, { mask })
  }

  return received
}

export const removeMask = value =>
  // eslint-disable-next-line no-restricted-globals
  isNaN(value) ? value.replace(/[^\d]+/g, '') : value;

export const removeMoneyMask = value =>
  // eslint-disable-next-line no-restricted-globals
  isNaN(value) ? Number(value.replace(/[^\d]+/g, '')).toFixed(2) / 100 : value;
