import React from 'react';
import { TextField } from '@mui/material';

import { setMask } from 'utils';

export default function _TextField({
  mask,
  value,
  onChange,
  ...props
}) {
  const getValueToDisplay = value => {
    if (mask) {
      return setMask(mask, value)
    }

    return value
  }

  const getValueToChange = e => {
    if (mask) {
      const maskedValue = setMask(mask, e.target.value)
      return {
        target: {
          id: e.target.id,
          name: e.target.name,
          value: maskedValue
        }
      }
    }

    return e
  }

  const handleChangeValue = e => {
    onChange?.(getValueToChange(e))
  }

  return (
    <TextField
      value={getValueToDisplay(value)}
      onChange={handleChangeValue}
      {...props}
    />
  )
}
