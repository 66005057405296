import PropTypes from 'prop-types'
import { useState } from 'react'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'
import { Box, List, ListSubheader, ListItemText, Collapse } from '@mui/material'
import i18n from 'i18next'

import Iconify from 'components/Iconify'

import {
  StyledNavItem,
  StyledNavItemIcon
} from './styled'

NavSection.propTypes = {
  data: PropTypes.array,
}

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item, key) => (
          <NavGroup key={key} item={item} />
        ))}
      </List>
    </Box>
  )
}

NavGroup.propTypes = {
  item: PropTypes.object,
}

function NavGroup({ item }) {
  const { title, items } = item

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {i18n.t(title)}
        </ListSubheader>
      }
    >
      {items.map((item, key) => (
        <NavItem key={key} item={item} />
      ))}
    </List>
  )
}

NavItem.propTypes = {
  item: PropTypes.object,
}

function NavItem({ item }) {
  const { pathname } = useLocation()

  const { title, path, url, icon, items } = item

  const [open, setOpen] = useState(false)

  return (
    <>
      <StyledNavItem
        onClick={items ? () => setOpen(!open) : null}
        component={items ? 'div' : RouterLink}
        to={items ? null : path || url || null}
        target={url ? '_blank' : null}
        className={(path === '/' && pathname === '/') || (pathname.startsWith(path) && path !== '/') ? 'active-nav-item' : ''}
        sx={{
          '&.active-nav-item': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

        <ListItemText disableTypography primary={i18n.t(title)} />

        {
          items && (
            <StyledNavItemIcon>
              {
                open
                  ? <Iconify icon="mdi:expand-less" />
                  : <Iconify icon="mdi:expand-more" />
              }
            </StyledNavItemIcon>
          )
        }
      </StyledNavItem>
      {
        items && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {items.map((_item, key) => (
                <StyledNavItem
                  key={key}
                  component={RouterLink}
                  to={_item.path}
                  className={pathname === _item.path ? 'active-nav-item' : ''}
                  sx={{
                    '&.active-nav-item': {
                      color: 'text.primary',
                      bgcolor: 'action.selected',
                      fontWeight: 'fontWeightBold',
                    },
                    pl: 7
                  }}
                >
                  <ListItemText disableTypography primary={i18n.t(_item.title)} />
                </StyledNavItem>
              ))}
            </List>
          </Collapse>
        )
      }
    </>
  )
}
