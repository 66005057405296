import { API } from 'services';

export function login(params) {
  return API({
    method: 'POST',
    endpoint: '/auth/login',
    ...params,
  });
}

export function forgotPassword(params) {
  return API({
    method: 'POST',
    endpoint: '/auth/forgotPassword',
    ...params,
  });
}

export function resetPassword(params) {
  return API({
    method: 'POST',
    endpoint: '/auth/passwordUpdate',
    ...params,
  });
}
