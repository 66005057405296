import PropTypes from 'prop-types'
import { useContext } from 'react';
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import ACL from 'components/ACL';
import Dialog from 'components/Dialog';
import TextField from 'components/TextField';

import { UserContext } from 'contexts';
import { createInvite, updateInvite } from 'services/requests/invite';
import { removeMask, toast } from 'utils';

export default function InviteForm({ open, defaultValues, onCancel, onSuccess }) {
  const { t } = useTranslation('common');
  const user = useContext(UserContext);
  const { state } = user;

  const validationSchema = yup.object({
    type: yup.string()
      .required('Obrigatório'),
    name: yup.string()
      .required('Obrigatório'),
    email: yup.string()
      .email('O email deve ser válido')
      .required('Obrigatório'),
    phone: yup.string()
      .phone('O telefone deve ser válido')
      .required('Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      type: defaultValues?.type || (state.data.role === 'seller' ? 'client' : ''),
      name: defaultValues?.name || '',
      email: defaultValues?.email || '',
      phone: defaultValues?.phone || '',
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const method = defaultValues?.token ? updateInvite : createInvite

      method({ body: { ...values, phone: removeMask(values.phone) }, token: defaultValues?.token || undefined })
        .then(() => {
          toast(`Convite ${defaultValues?.token ? 'reenviado' : 'enviado'} com sucesso`, 'success')
          handleReset()
          onSuccess?.()
        })
        .catch(err => toast(err, 'error'))
        .finally(() => setSubmitting(false))
    },
  });

  const handleReset = () => {
    formik.handleReset()
    onCancel()
  }

  return (
    (open) &&
    <Dialog
      open={open}
      options={{
        title: `${defaultValues?.token ? 'Atualizar' : 'Enviar novo'} convite`,
        content: (
          <Stack spacing={3} py={3}>
            <ACL profiles={['admin']}>
              {
                !defaultValues?.invitorId || defaultValues?.invitorId === state.data.id ? (
                  <FormControl fullWidth>
                    <InputLabel id="type">
                      Tipo de convite
                    </InputLabel>
                    <Select
                      labelId="type"
                      name="type"
                      id="type"
                      label="Tipo de convite"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      sx={{ textAlign: 'left' }}
                    >
                      {
                        ['seller', 'client'].map((item, key) => (
                          <MenuItem value={item} key={key}>
                            {t(`profiles.${item}`)}
                          </MenuItem>
                        ))
                      }
                    </Select>
                    {
                      formik.touched.type && Boolean(formik.errors.type) ? (
                        <FormHelperText
                          error={formik.touched.type && Boolean(formik.errors.type)}
                        >
                          {formik.touched.type && formik.errors.type}
                        </FormHelperText>
                      ) : null
                    }
                  </FormControl>
                ) : (
                  <TextField
                    disabled
                    fullWidth
                    label="Tipo"
                    value={t(`profiles.${formik.values.type}`)}
                  />
                )
              }
            </ACL>

            <ACL profiles={['seller']}>
              <TextField
                disabled
                fullWidth
                label="Tipo"
                value={t(`profiles.${formik.values.type}`)}
              />
            </ACL>

            <TextField
              fullWidth
              id="name"
              name="name"
              label="Nome"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
              fullWidth
              id="phone"
              name="phone"
              label="Telefone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              mask="(99) 99999-9999"
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Stack>
        ),
        cancellationText: 'Cancelar',
        confirmationText: defaultValues?.token ? 'Atualizar e reenviar' : 'Convidar',
        confirmationButtonProps: {
          loading: formik.isSubmitting
        }
      }}
      onCancel={() => handleReset()}
      onConfirm={() => formik.handleSubmit()}
    />
  );
}

InviteForm.propTypes = {
  open: PropTypes.bool,
  defaultValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
}
