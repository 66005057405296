import i18n from 'i18next';
import { Cookies } from 'react-cookie';

import { EventBus } from 'utils/bus';

export default function API({
  method = 'get',
  endpoint = '',
  url,
  body,
  formData,
  headers,
  timeout = 120000,
  responseType = 'json',
  ...props
}) {
  const controller = new AbortController();
  const cookie = new Cookies();

  const destination = url || process.env.REACT_APP_API_URL + endpoint;

  const defaultHeader = formData
    ? {}
    : {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        pragma: 'no-cache',
        'cache-control': 'no-cache',
      };

  if (cookie.get('access_token')) {
    defaultHeader.authorization = `Bearer ${cookie.get('access_token')}`
  }

  const request = {
    headers: {
      ...defaultHeader,
      ...headers,
    },
    responseType,
    signal: controller.signal,
    credentials: 'include',
    cache: 'default',
    method,
    body: body ? JSON.stringify(body) : formData || null,
    ...props,
  };

  if (process.env.NODE_ENV === 'development') {
    console.log('method', method, destination, request.body);
  }

  const timer = setTimeout(() => controller.abort(), timeout);

  return fetch(destination, request)
    .then((rawResponse) => {
      clearTimeout(timer);
      if (rawResponse.ok) {
        return rawResponse;
      }
      throw rawResponse;
    })
    .catch((rawResponse) => {
      if (!rawResponse[responseType]) {
        throw new Error(i18n.t('common:UNKNOWN_ERROR'));
      }

      return rawResponse[responseType]()
        .then((errBody) => {
          if (typeof errBody === 'string') {
            return errBody;
          }

          if (typeof errBody?.message === 'string') {
            return errBody.message;
          }

          if (errBody?.message?.length) {
            return errBody.message.map(item => typeof item === 'string' ? item : i18n.t('common:UNKNOWN_ERROR'));
          }

          return new Error(i18n.t('common:UNKNOWN_ERROR'));
        })
        .catch((err) => {
          switch (true) {
            case !!rawResponse.message:
              return new Error(rawResponse.message);
            case !!rawResponse.statusText:
              return new Error(rawResponse.statusText);
            case !!rawResponse.status:
              return new Error(rawResponse.status);
            case !!rawResponse:
              return new Error(rawResponse);
            case err instanceof Error:
              return err;
            default:
              return err;
          }
        })
        .then((parsedError) => {
          if (process.env.NODE_ENV === 'development') {
            console.log('response parsedError: ', parsedError);
          }

          if (
            parsedError.message === 'Unauthorized' ||
            parsedError.message === '401' ||
            parsedError === 'Unauthorized' ||
            parsedError === '401'
          ) {
            EventBus.$emit('logout');
          }

          throw parsedError;
        });
    })
    .then((rawResponse) => rawResponse[responseType]().catch(() => true))
    .then((parsedResponse) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('response: ', parsedResponse);
      }
      return parsedResponse;
    });
}
