import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { resetPassword } from 'services/requests/auth';
import { toast } from 'utils';

import Iconify from 'components/Iconify';

const initialValues = {
  password: '',
  cpassword: ''
};

export default function ResetForm() {
  const { t } = useTranslation('common');
  const { token } = useParams();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const validationSchema = yup.object({
    password: yup.string()
      .required('Obrigatório')
      .min(8, 'Sua senha deve conter pelo menos 8 caracteres'),
    cpassword: yup.string()
      .required('Obrigatório')
      .oneOf([yup.ref('password'), null], 'Senha e confirmação não conferem'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      resetPassword({ body: { password: values.password, token } })
        .then(() => {
          toast('auth.reset.success', 'success')
          navigate('/auth', { replace: true })
        })
        .catch((err) => toast(err, 'error'))
        .then(() => setSubmitting(false));
    },
  });

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="password"
          label={t('auth.reset.form.password')}
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          name="cpassword"
          label={t('auth.reset.form.cpassword')}
          type={showCPassword ? 'text' : 'password'}
          value={formik.values.cpassword}
          onChange={formik.handleChange}
          error={formik.touched.cpassword && Boolean(formik.errors.cpassword)}
          helperText={formik.touched.cpassword && formik.errors.cpassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowCPassword(!showCPassword)} edge="end">
                  <Iconify icon={showCPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        color="secondary"
        variant="contained"
        onClick={() => formik.handleSubmit()}
        loading={formik.isSubmitting}
        sx={{ mt: 2 }}
      >
        {t('auth.reset.form.submit')}
      </LoadingButton>
    </>
  );
}
