import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Stack,
  Container,
  Button,
  IconButton,
  Chip,
  Card,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import ACL from 'components/ACL';
import Breadcrumbs from 'components/Breadcrumbs';
import List from 'components/List';
import Iconify from 'components/Iconify';

import { cancelContract, getContracts } from 'services/requests/contract';
import { toast, setMask } from 'utils';
import { useDialog } from 'components/Dialog';
import { useNavigate } from 'react-router-dom';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import ContractForm from '../ContractForm';

dayjs.extend(utc);

export default function ContractsProvider() {
  const { t } = useTranslation('common');

  const dialog = useDialog();
  const navigate = useNavigate();


  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [newContract, setNewContract] = useState(false);

  const listConfig = {
    placeholderFilter: t('contracts.search'),
    onClick: row => navigate(`/contratos/detalhe/${row.id}`),
    fields: [
      { key: 'number', label: 'Nº', desktopColumnSize: 1, isPrimary: true },
      { key: 'name', label: 'Cliente', desktopColumnSize: 2 },
      { key: 'goal', label: 'Objetivo', desktopColumnSize: 2, sm_prefix: 'Objetivo: ' },
      { key: 'amount', label: 'Valor pactuado', desktopColumnSize: 2, customValue: row => setMask('money', row.amount) },
      { key: 'installments', label: 'Parcelas', desktopColumnSize: 2, customValue: row => row.installments },
      {
        key: 'status', label: 'Status', desktopColumnSize: 3, customValue: row => (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row">
              <Chip label={row.statusLabel} size="small" variant="outlined" color={row.statusColor} />
            </Stack>
            <ACL profiles={['admin', 'seller']}>
              {
                (row.status === 'created' || row.status === 'pendingSign' || row.status === 'signed') ? (
                  <IconButton sx={{ color: 'error.main', ml: 1 }} onClick={e => deleteItem(e, row)}>
                    <Iconify icon="ic:outline-block" />
                  </IconButton>
                ) : null
              }
            </ACL>
          </Stack>
        )
      }
    ]
  }

  const getData = () => {
    setLoadingData(true)

    getContracts()
      .then((res) => {
        const parsed = res.map(({ id, number, name, goal, amount, installmentscount, installmentspayed, status }) => ({
          id, number, name, goal, amount, status,
          installments: `Pagas ${installmentspayed}/${installmentscount}`,
          statusLabel: t(`contracts.status.${status}`),
          statusColor: status === 'created' ? 'info' : status === 'pendingSign' || status === 'signed' || status === 'borrowed' ? 'warning' : status === 'cancelled' ? 'error' : 'success'
        }))

        const filtered = parsed.filter(item => (item.status !== 'finished' && item.status !== 'cancelled'))

        setAllData(parsed)
        setData(filtered)
      })
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingData(false));
  }

  const deleteItem = (e, contract) => {
    e.stopPropagation()

    dialog({
      title: 'Cancelar contrato',
      description: `Deseja realmente cancelar o contrato de ${contract.name} no valor de ${setMask('money', contract.amount)}?`,
    })
      .then(() => {
        cancelContract(contract.id)
          .then(() => {
            toast('Contrato cancelado com sucesso!', 'success')
            getData()
          }).catch(err => {
            toast(err, 'error')
          })
      })
      .catch(() => { })
  }

  const handleShowAll = active => {
    if (active) {
      setData(allData)
    } else {
      const filtered = allData.filter(item => (item.status !== 'finished' && item.status !== 'cancelled'))
      setData(filtered)
    }
  }

  useEffect(() => {
    if (!newContract) {
      getData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newContract])

  return (
    <>
      <Helmet>
        <title> {t('contractsCreated.pageTitle')} | { process.env.REACT_APP_PROJECT_NAME } </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Breadcrumbs
            items={[
              { label: t('contractsCreated.title') }
            ]}
          />

          <ACL profiles={['admin', 'seller']}>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => setNewContract(true)}
            >
              {t('contracts.new')}
            </Button>
          </ACL>
        </Stack>

        <Card sx={{ px: 3, py: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                name="applyCompanyAddress"
                onChange={e => handleShowAll(e.target.checked)}
              />
            }
            label="Mostrar contratos finalizados e cancelados"
          />
        </Card>

        <List
          config={listConfig}
          data={data}
          refresh={getData}
          loading={loadingData}
        />

        <ContractForm
          open={newContract}
          onCancel={() => setNewContract(false)}
        />
      </Container>
    </>
  )
}
