export default {
  formId: 'acceptInviteForm',
  formField: {
    applyCompanyAddress: 'applyCompanyAddress',
    document: 'document',
    name: 'name',
    email: 'email',
    phone: 'phone',
    cpf: 'cpf',
    rg: 'rg',
    birthDate: 'birthDate',
    profession: 'profession',
    nationality: 'nationality',
    maritalStatus: 'maritalStatus',
    propertyRegime: 'propertyRegime',
    password: 'password',
    zipcode: 'zipcode',
    street: 'street',
    neighborhood: 'neighborhood',
    city: 'city',
    state: 'state',
    number: 'number',
    complement: 'complement',
    socialName: 'socialName',
    fantasyName: 'fantasyName',
    emailCompany: 'emailCompany',
    phoneCompany: 'phoneCompany',
    cnpj: 'cnpj',
    pixType: 'pixType',
    pix: 'pix',
    zipcodeCompany: 'zipcodeCompany',
    streetCompany: 'streetCompany',
    neighborhoodCompany: 'neighborhoodCompany',
    cityCompany: 'cityCompany',
    stateCompany: 'stateCompany',
    numberCompany: 'numberCompany',
    complementCompany: 'complementCompany',
  }
};
