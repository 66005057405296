import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

const Field = ({ label, value }) => (
  <Box mb={2}>
    <Typography color="text.primary" variant="subtitle1">
      {label}
    </Typography>
    <Typography color="text.secondary" variant="body1">
      {value}
    </Typography>
  </Box>
);

Field.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node
}

export default Field
