export const maritalStatus = [
  'single',
  'widower',
  'married',
  'stable union',
  'informal stable union'
]

export const propertyRegime = [
  'universal',
  'partial communion',
  'final share',
  'total separation',
  'own regime',
  'nuptial agreement',
  'dont know'
]

export const pixType = [
  'cnpj',
  'cpf',
  'phone',
  'email',
  'random'
]
