/* eslint-disable operator-assignment */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable eqeqeq */

import { addMethod, string } from 'yup';

export const RegexExpressions = {
  containsNumber: /[0-9]/,
  containsSpecialCharacter: /^(?=.*[@!#$%^&*()/\\])[@!#$%^&*()/\\a-zA-Z0-9]/,
  containsUppercase: /[A-Z]/,
  phone: /\(?\b([0-9]{2}|0((x|[0-9]){2,3}[0-9]{2}))\)?\s*[0-9]{4,5}[- ]*[0-9]{4}\b/,
}

addMethod(string, 'cep', function validator(errorMessage) {
  return this.test('test-cep', errorMessage, function validation(value) {
    const { path, createError } = this;

    if (value.replace(/[^\d]+/g, '').length !== 8) {
      return createError({ path, message: errorMessage });
    }

    return true;
  });
});

addMethod(string, 'birthdate', function validator(errorMessage) {
  return this.test('test-birthdate', errorMessage, function validation(value) {
    const { path, createError } = this;

    if (value.length !== 10) {
      return createError({ path, message: errorMessage });
    }

    const parsed = value.split('/').reverse().join('-')
    const dt = new Date(parsed).getTime()
    const dtNow = new Date().getTime()

    if (Number.isNaN(dt)) {
      return createError({ path, message: errorMessage });
    }

    if (dt >= dtNow) {
      return createError({ path, message: errorMessage });
    }

    return true;
  });
});

addMethod(string, 'password', function validator(errorMessage) {
  return this.test('test-password', errorMessage, function validation(value) {
    const { path, createError } = this;

    if (
      value?.length >= 8 &&
      RegexExpressions.containsNumber.test(value) &&
      RegexExpressions.containsUppercase.test(value) &&
      RegexExpressions.containsSpecialCharacter.test(value)
    ) {
      return true;
    }

    return createError({ path, message: errorMessage });
  });
});

addMethod(string, 'phone', function validator(errorMessage) {
  return this.test('test-phone', errorMessage, function validation(value) {
    const { path, createError } = this;

    if (RegexExpressions.phone.test(value)) {
      value = value.replace(/[^\d]/g, '');
      const validDDDs = ['11', '12', '13', '14', '15', '16', '17', '18', '19', '21', '22', '24', '27', '28', '31', '32', '33', '34', '35', '37', '38', '41', '42', '43', '44', '45', '46', '47', '48', '49', '51', '53', '54', '55', '61', '62', '63', '64', '65', '66', '67', '68', '69', '71', '73', '74', '75', '77', '79', '81', '82', '83', '84', '85', '86', '87', '88', '89', '91', '92', '93', '94', '95', '96', '97', '98', '99'];
      const ddd = value.slice(0, 2);

      const isValid = validDDDs.find(item => item === ddd);
      if (isValid) return true;
    }

    return createError({ path, message: errorMessage });
  });
});

addMethod(string, 'cpf', function validator(errorMessage) {
  return this.test('test-cpf', errorMessage, function validation(value) {
    const { path, createError } = this;

    value = value.replace(/[^\d]+/g, '')
    if (
      value.length != 11
      || value == '00000000000'
      || value == '11111111111'
      || value == '22222222222'
      || value == '33333333333'
      || value == '44444444444'
      || value == '55555555555'
      || value == '66666666666'
      || value == '77777777777'
      || value == '88888888888'
      || value == '99999999999'
    ) return createError({ path, message: errorMessage })

    let sum = 0
    let rest

    for (let i = 1; i <= 9; i++) sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i)
    rest = (sum * 10) % 11
    if ((rest == 10) || (rest == 11)) rest = 0
    if (rest != parseInt(value.substring(9, 10))) return createError({ path, message: errorMessage })

    sum = 0

    for (let i = 1; i <= 10; i++) sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i)
    rest = (sum * 10) % 11
    if ((rest == 10) || (rest == 11)) rest = 0
    if (rest != parseInt(value.substring(10, 11))) return createError({ path, message: errorMessage })

    return true
  });
});

addMethod(string, 'cnpj', function validator(errorMessage) {
  return this.test('test-cnpj', errorMessage, function validation(value) {
    const { path, createError } = this;

    value = value.replace(/[^\d]+/g, '');
    if (
      value.length != 14
      || value == '00000000000000'
      || value == '11111111111111'
      || value == '22222222222222'
      || value == '33333333333333'
      || value == '44444444444444'
      || value == '55555555555555'
      || value == '66666666666666'
      || value == '77777777777777'
      || value == '88888888888888'
      || value == '99999999999999'
    ) return createError({ path, message: errorMessage })

    let tamanho = value.length - 2
    let numeros = value.substring(0, tamanho)
    const digitos = value.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7
    let resultado
    let i

    for (i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) return createError({ path, message: errorMessage })

    tamanho = tamanho + 1;
    numeros = value.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) return createError({ path, message: errorMessage })

    return true
  });
});
