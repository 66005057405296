import { useState, useContext } from 'react';
import { useNavigate, useSearchParams, Link as RouterLink } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { UserContext } from 'contexts';
import { toast } from 'utils';

import Iconify from 'components/Iconify';
import { ArrowForward } from '@mui/icons-material';

const initialValues = {
  email: '',
  password: '',
};

export default function LoginForm() {
  const { t } = useTranslation('common');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const user = useContext(UserContext);

  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = yup.object({
    email: yup.string()
      .email('O email deve ser válido')
      .required('Obrigatório'),
    password: yup.string()
      .required('Obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      user.exec
        .login({ body: { ...values } })
        .then(() => navigate(`/${searchParams.get('next') || ''}`, { replace: true }))
        .catch((err) => toast(err, 'error'))
        .finally(() => setSubmitting(false));
    },
  });

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label={t('auth.login.form.email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          name="password"
          label={t('auth.login.form.password')}
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
          onChange={formik.handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />

        <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
          <Link to={'/auth/recuperar-senha'} component={RouterLink} variant="subtitle2" underline="hover">
            {t('auth.login.form.forgot')}
          </Link>
        </Stack>

        <LoadingButton
          style={{
            justifyContent: 'space-between'
          }}
          color="secondary"
          variant="contained"
          endIcon={<ArrowForward />}
          fullWidth
          size="large"
          type="submit"
          onClick={() => formik.handleSubmit()}
          loading={formik.isSubmitting}
        >
          {t('auth.login.form.submit')}
        </LoadingButton>
      </Stack>
    </>
  );
}
