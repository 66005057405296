import { useContext, useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

import { UserContext } from 'contexts';

export default () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = useContext(UserContext);

  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (state.initialized) {
      if (state.isLoggedIn && state.data) {
        navigate('/', { replace: true });
        return;
      }

      setAuthorized(true);
    }
  }, [state, location, navigate]);

  return state.initialized ? (
    authorized && <Outlet />
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress size={100} />
    </Box>
  );
};
