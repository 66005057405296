import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Link, Drawer, Typography, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'contexts';
import { useResponsive } from 'utils';

import Logo from 'components/Logo';
import NavSection from 'components/NavSection';

import { StyledScrollbar, StyledLogo, StyledAccount } from './styled';

import navConfig from './config';

const NAV_WIDTH = 280;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { t } = useTranslation('common');

  const [navMenu, setNavMenu] = useState([])

  const user = useContext(UserContext);
  const { state } = user;

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (state.data.role) {
      const filteredMenus = navConfig.map(item => ({
        ...item,
        items: item.items.filter(_item => _item?.profiles ? _item.profiles.includes(state.data.role) : true)
      }))
      setNavMenu(filteredMenus)
    } else {
      setNavMenu(navConfig)
    }
  }, [state]);

  const renderContent = (
    <StyledScrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <StyledLogo>
        <Logo authHeight />
      </StyledLogo>

      <Box sx={{ mb: 3, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={state?.data?.user?.avatar || null} alt={state?.data?.user?.name}>
              {state?.data?.name?.slice(0, 1)}
            </Avatar>

            <Box sx={{ ml: 2 }}>
              <Typography variant="body2" color="text.primary">
                {t('hello')} <b>{state?.data?.name?.split(' ')[0]}</b>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t(`profiles.${state?.data?.role}`)}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navMenu} />
    </StyledScrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
