import CryptoJS from 'crypto-js'

const STORAGE_KEY = process.env.REACT_APP_STORAGE_KEY

const crypt = value => CryptoJS.AES.encrypt(value, STORAGE_KEY).toString()

const decrypt = cvalue => {
  const bytes = CryptoJS.AES.decrypt(cvalue, STORAGE_KEY)
  return bytes.toString(CryptoJS.enc.Utf8)
}

export default {
  set: ({ key, value }) => {
    const svalue = JSON.stringify(value)
    const cvalue = crypt(svalue)

    try {
      return localStorage.setItem(`${STORAGE_KEY}${key}`, cvalue)
    } catch (e) {
      return sessionStorage.setItem(`${STORAGE_KEY}${key}`, cvalue)
    }
  },

  get: ({ key }) => {
    let cvalue = ''

    try {
      cvalue = localStorage.getItem(`${STORAGE_KEY}${key}`)
    } catch (e) {
      cvalue = sessionStorage.getItem(`${STORAGE_KEY}${key}`)
    }

    if (cvalue) {
      const svalue = decrypt(cvalue)
      return svalue ? JSON.parse(svalue) : null
    }

    return null
  },

  delete: ({ key }) => {
    try {
      return localStorage.setItem(`${STORAGE_KEY}${key}`, null)
    } catch (e) {
      return sessionStorage.setItem(`${STORAGE_KEY}${key}`, null)
    }
  }
}
