import { Helmet } from 'react-helmet-async'
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ACL from 'components/ACL'

import Admin from './Admin'
import Seller from './Seller'
import Client from './Client'

export default function Home() {
  const { t } = useTranslation('common')

  return (
    <>
      <Helmet>
        <title>{ process.env.REACT_APP_PROJECT_NAME }</title>
      </Helmet>

      <Container maxWidth="xl">
        <ACL profiles={['admin']}>
          <Admin />
        </ACL>

        <ACL profiles={['seller']}>
          <Seller />
        </ACL>

        <ACL profiles={['client']}>
          <Client />
        </ACL>
      </Container>
    </>
  );
}
