import PropTypes from 'prop-types'
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextField from 'components/TextField';

import { getAddressFromCep } from 'services/requests/external'
import { Brazil } from 'utils'

export default function AcceptInviteFormStepCompanyAddress({ values, handleChange, touched, errors, setFieldValue }) {
  const { t } = useTranslation('common');

  const getFullAddressFromCep = async cep => {
    setFieldValue('zipcodeCompany', cep)

    if (cep.length === 9) {
      const address = await getAddressFromCep({ cep })
      if (address?.street) {
        const { street, neighborhood, city, state } = address
        setFieldValue('zipcodeCompany', cep)
        setFieldValue('streetCompany', street)
        setFieldValue('neighborhoodCompany', neighborhood)
        setFieldValue('cityCompany', city)
        setFieldValue('stateCompany', state)
      }
    }
  }

  return (
    <Stack spacing={3}>
      <TextField
        name="zipcodeCompany"
        mask="99999-999"
        label={t('auth.acceptInvite.form.zipcode')}
        value={values.zipcodeCompany}
        onChange={e => getFullAddressFromCep(e.target.value)}
        error={touched.zipcodeCompany && Boolean(errors.zipcodeCompany)}
        helperText={touched.zipcodeCompany && errors.zipcodeCompany}
      />

      <TextField
        name="streetCompany"
        label={t('auth.acceptInvite.form.street')}
        value={values.streetCompany}
        onChange={handleChange}
        error={touched.streetCompany && Boolean(errors.streetCompany)}
        helperText={touched.streetCompany && errors.streetCompany}
      />

      <TextField
        name="neighborhoodCompany"
        label={t('auth.acceptInvite.form.neighborhood')}
        value={values.neighborhoodCompany}
        onChange={handleChange}
        error={touched.neighborhoodCompany && Boolean(errors.neighborhoodCompany)}
        helperText={touched.neighborhoodCompany && errors.neighborhoodCompany}
      />

      <TextField
        name="cityCompany"
        label={t('auth.acceptInvite.form.city')}
        value={values.cityCompany}
        onChange={handleChange}
        error={touched.cityCompany && Boolean(errors.cityCompany)}
        helperText={touched.cityCompany && errors.cityCompany}
      />

      <FormControl fullWidth>
        <InputLabel id="stateCompany">
          {t('auth.acceptInvite.form.state')}
        </InputLabel>
        <Select
          labelId="stateCompany"
          name="stateCompany"
          id="stateCompany"
          label={t('auth.acceptInvite.form.state')}
          value={values.stateCompany}
          onChange={handleChange}
          error={touched.stateCompany && Boolean(errors.stateCompany)}
          sx={{ textAlign: 'left' }}
        >
          {
            Brazil.states.map((item, key) => (
              <MenuItem value={item} key={key}>
                {item}
              </MenuItem>
            ))
          }
        </Select>
        {
          touched.stateCompany && Boolean(errors.stateCompany) ? (
            <FormHelperText
              error={touched.stateCompany && Boolean(errors.stateCompany)}
            >
              {touched.stateCompany && errors.stateCompany}
            </FormHelperText>
          ) : null
        }
      </FormControl>

      <TextField
        name="numberCompany"
        label={t('auth.acceptInvite.form.number')}
        value={values.numberCompany}
        onChange={handleChange}
        error={touched.numberCompany && Boolean(errors.numberCompany)}
        helperText={touched.numberCompany && errors.numberCompany}
      />

      <TextField
        name="complementCompany"
        label={t('auth.acceptInvite.form.complement')}
        value={values.complementCompany}
        onChange={handleChange}
        error={touched.complementCompany && Boolean(errors.complementCompany)}
        helperText={touched.complementCompany && errors.complementCompany}
      />
    </Stack>
  );
}

AcceptInviteFormStepCompanyAddress.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func
}
