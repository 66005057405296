export default function IllustrationInvite({ ...props }) {
  const color = process.env.REACT_APP_PROJECT_COLOR

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <g id="freepik--background-complete--inject-92">
        <rect
          x={72.14}
          y={85.44}
          width={135.7}
          height={203.1}
          transform="translate(279.98 373.98) rotate(180)"
          style={{
            fill: "#e6e6e6",
          }}
        />
        <rect
          x={69.12}
          y={85.44}
          width={135.7}
          height={203.1}
          transform="translate(273.95 373.98) rotate(180)"
          style={{
            fill: "#f5f5f5",
          }}
        />
        <rect
          x={41.5}
          y={124.55}
          width={190.94}
          height={124.87}
          transform="translate(323.96 50.02) rotate(90)"
          style={{
            fill: "#fff",
          }}
        />
        <rect
          x={-19.79}
          y={185.85}
          width={190.94}
          height={2.28}
          transform="translate(262.67 111.31) rotate(90)"
          style={{
            fill: "#e6e6e6",
          }}
        />
        <rect
          x={71.36}
          y={151.05}
          width={131.22}
          height={8.16}
          transform="translate(273.95 310.26) rotate(180)"
          style={{
            fill: "#f5f5f5",
          }}
        />
        <path
          d="M63.22,75.37H213.74a.84.84,0,0,1,.83.84h0a.83.83,0,0,1-.83.83H63.22a.83.83,0,0,1-.83-.83h0A.84.84,0,0,1,63.22,75.37Z"
          style={{
            fill: "#e6e6e6",
          }}
        />
        <path
          d="M58.22,76.21A4.17,4.17,0,1,0,62.39,72,4.17,4.17,0,0,0,58.22,76.21Z"
          style={{
            fill: "#e6e6e6",
          }}
        />
        <path
          d="M206.71,80.87h0a1.13,1.13,0,0,0,1.13-1.12V77.33a1.13,1.13,0,0,0-1.13-1.12h0a1.12,1.12,0,0,0-1.12,1.12v2.42A1.12,1.12,0,0,0,206.71,80.87Z"
          style={{
            fill: "#e6e6e6",
          }}
        />
        <path
          d="M75.79,80.87h0a1.13,1.13,0,0,0,1.13-1.12V77.33a1.13,1.13,0,0,0-1.13-1.12h0a1.12,1.12,0,0,0-1.12,1.12v2.42A1.12,1.12,0,0,0,75.79,80.87Z"
          style={{
            fill: "#e6e6e6",
          }}
        />
        <rect
          x={63.49}
          y={76.21}
          width={139.1}
          height={191.46}
          transform="translate(266.07 343.87) rotate(180)"
          style={{
            fill: "#e0e0e0",
            opacity: 0.5,
          }}
        />
        <path
          d="M189.15,76.21A4.17,4.17,0,1,0,193.31,72,4.16,4.16,0,0,0,189.15,76.21Z"
          style={{
            fill: "#e6e6e6",
          }}
        />
        <rect
          x={186.63}
          y={319.12}
          width={26.78}
          height={59.39}
          transform="translate(400.05 697.63) rotate(180)"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={76.18}
          y={378.51}
          width={132.09}
          height={3.76}
          transform="translate(284.45 760.78) rotate(180)"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={71.04}
          y={319.12}
          width={115.59}
          height={59.39}
          style={{
            fill: "#f5f5f5",
          }}
        />
        <rect
          x={81.57}
          y={326.43}
          width={94.53}
          height={19.48}
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={81.57}
          y={351.72}
          width={94.53}
          height={19.48}
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={115.61}
          y={334.84}
          width={26.45}
          height={2.67}
          rx={1.18}
          transform="translate(257.67 672.34) rotate(180)"
          style={{
            fill: "#f5f5f5",
          }}
        />
        <rect
          x={115.61}
          y={360.13}
          width={26.45}
          height={2.67}
          rx={1.18}
          transform="translate(257.67 722.92) rotate(180)"
          style={{
            fill: "#f5f5f5",
          }}
        />
        <path
          d="M122.92,309.13s-.33,10-12.67,10-12.66-10-12.66-10Z"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <path
          d="M151.79,309.13s-.32,10-12.67,10-12.66-10-12.66-10Z"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <path
          d="M122.92,302.5s-.33,10-12.67,10-12.66-10-12.66-10Z"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <path
          d="M151.79,302.5s-.32,10-12.67,10-12.66-10-12.66-10Z"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <path
          d="M180.66,309.13s-.32,10-12.66,10-12.67-10-12.67-10Z"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <path
          d="M180.66,302.5s-.32,10-12.66,10-12.67-10-12.67-10Z"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={343.97}
          y={86.03}
          width={80.87}
          height={116.58}
          transform="translate(768.8 288.64) rotate(180)"
          style={{
            fill: "#e6e6e6",
          }}
        />
        <rect
          x={321.52}
          y={86.03}
          width={94.19}
          height={116.58}
          transform="translate(737.22 288.64) rotate(180)"
          style={{
            fill: "#f5f5f5",
          }}
        />
        <rect
          x={317.81}
          y={109.27}
          width={101.6}
          height={70.11}
          transform="translate(512.93 -224.29) rotate(90)"
          style={{
            fill: "#fff",
          }}
        />
        <rect
          x={316.51}
          y={143.68}
          width={104.2}
          height={1.28}
          transform="translate(512.93 -224.29) rotate(90)"
          style={{
            fill: "#f5f5f5",
          }}
        />
        <rect
          x={423.68}
          y={243.78}
          width={20.21}
          height={132.51}
          transform="translate(867.57 620.08) rotate(180)"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={271.89}
          y={376.29}
          width={166.67}
          height={6.09}
          transform="translate(710.45 758.67) rotate(180)"
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={266.56}
          y={243.78}
          width={157.12}
          height={132.51}
          style={{
            fill: "#f5f5f5",
          }}
        />
        <rect
          x={277.48}
          y={291.67}
          width={135.28}
          height={31.52}
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={277.48}
          y={332.94}
          width={135.28}
          height={31.52}
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={304.05}
          y={289.51}
          width={82.13}
          height={4.32}
          rx={2.05}
          transform="translate(690.24 583.35) rotate(180)"
          style={{
            fill: "#f5f5f5",
          }}
        />
        <rect
          x={304.05}
          y={330.77}
          width={82.13}
          height={4.32}
          rx={2.05}
          transform="translate(690.24 665.87) rotate(-180)"
          style={{
            fill: "#f5f5f5",
          }}
        />
        <rect
          x={277.48}
          y={250.41}
          width={135.28}
          height={31.52}
          style={{
            fill: "#f0f0f0",
          }}
        />
        <rect
          x={304.05}
          y={248.25}
          width={82.13}
          height={4.32}
          rx={2.05}
          transform="translate(690.24 500.83) rotate(180)"
          style={{
            fill: "#f5f5f5",
          }}
        />
        <path
          d="M295.61,153.12a11.65,11.65,0,0,0,0-23.3h-1.42v23.3Z"
          style={{
            fill: "#e0e0e0",
          }}
        />
        <circle
          cx={294.19}
          cy={141.47}
          r={11.65}
          style={{
            fill: "#f5f5f5",
          }}
        />
        <path
          d="M293,143.67a23.46,23.46,0,0,1-23.43-23.44v-14a2.2,2.2,0,0,1,4.4,0v14a19.06,19.06,0,0,0,19,19,2.2,2.2,0,1,1,0,4.4Z"
          style={{
            fill: "#e0e0e0",
          }}
        />
        <path
          d="M252,118.56h39.56a4.32,4.32,0,0,0,4.27-4.93L292.43,90a11.4,11.4,0,0,0-11.29-9.77h-18.7A11.4,11.4,0,0,0,251.15,90l-3.41,23.59A4.31,4.31,0,0,0,252,118.56Z"
          style={{
            fill: "#f5f5f5",
          }}
        />
      </g>
      <g id="freepik--Shadow--inject-92">
        <ellipse
          id="freepik--path--inject-92"
          cx={250}
          cy={416.24}
          rx={193.89}
          ry={11.32}
          style={{
            fill: "#f5f5f5",
            opacity: 0.05,
          }}
        />
      </g>
      <g id="freepik--character-2--inject-92">
        <polygon
          points="293.81 400.2 286.24 397.83 289.72 379.66 297.29 382.04 293.81 400.2"
          style={{
            fill: "#ff8b7b",
          }}
        />
        <polygon
          points="362.56 408.14 354.62 408.14 348.44 389.76 356.37 389.76 362.56 408.14"
          style={{
            fill: "#ff8b7b",
          }}
        />
        <path
          d="M353.56,407.22h9.68a.68.68,0,0,1,.67.54l1.57,7.05a1.16,1.16,0,0,1-1.16,1.41c-3.12-.05-5.39-.23-9.31-.23-2.41,0-9.68.25-13,.25s-3.76-3.3-2.4-3.59c6.11-1.34,10.72-3.18,12.68-4.94A1.9,1.9,0,0,1,353.56,407.22Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M286.57,397l8.27,2.59a.7.7,0,0,1,.48.72l-.62,7.2a1.15,1.15,0,0,1-1.51,1c-3-1-7.22-2.51-11-3.68-4.38-1.38-8.59-2.62-13.73-4.24-3.11-1-3-4.38-1.6-4.26,6.33.56,11.56,2.24,17.67.69A3.62,3.62,0,0,1,286.57,397Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M332.79,170c-1.33,1.87-2.77,3.87-4.13,5.84s-2.71,4-4.05,6c-2.64,4-5.19,8.05-7.45,12.13-.59,1-1.07,2-1.62,3.07s-1,2-1.44,3-.86,2-1.24,3l-.23.6a.54.54,0,0,1-.07.14s0,0,0-.44v-.21c0-.1,0-.19,0-.3a3.2,3.2,0,0,0-.09-.49c0-.13-.07-.26-.11-.38l-.06-.19-.12-.28a5.39,5.39,0,0,0-.3-.58,4.77,4.77,0,0,0-.58-.76,5,5,0,0,0-2.14-1.39,4.9,4.9,0,0,0-1.2-.24,3.51,3.51,0,0,0-.56,0l-.4,0a4.68,4.68,0,0,0-1.2.3,5.86,5.86,0,0,0-.6.28,3.16,3.16,0,0,0-.33.21l-.2.16-.13.11a1.23,1.23,0,0,0-.21.21c-.07.12.09,0,.22-.16.6-.65,1.38-1.6,2.08-2.52l2.2-2.88c1.5-1.93,3-3.9,4.6-5.8l5.26,2.8c-.73,2.46-1.6,4.81-2.55,7.17-.5,1.17-1,2.35-1.55,3.52a32.09,32.09,0,0,1-2,3.69,10,10,0,0,1-.95,1.27,6.75,6.75,0,0,1-.54.54l-.22.18-.3.22a4,4,0,0,1-.43.27,6.62,6.62,0,0,1-.7.34,5.3,5.3,0,0,1-1.31.32l-.43,0c-.18,0-.39,0-.59,0a4.85,4.85,0,0,1-1.25-.25,5.08,5.08,0,0,1-1.26-.61,4.89,4.89,0,0,1-1-.84,4.77,4.77,0,0,1-.61-.81,5.48,5.48,0,0,1-.33-.63l-.13-.31-.07-.21c-.05-.14-.09-.29-.13-.43s-.08-.41-.11-.61l0-.42v-.32a9.19,9.19,0,0,1,.08-1.36c0-.3.08-.55.12-.77.08-.45.16-.82.25-1.19a36.4,36.4,0,0,1,1.21-4c.45-1.29,1-2.52,1.47-3.76s1.07-2.43,1.65-3.58a139.93,139.93,0,0,1,7.53-13.44c1.36-2.14,2.75-4.26,4.21-6.33s2.89-4.12,4.51-6.2Z"
          style={{
            fill: "#ff8b7b",
          }}
        />
        <path
          d="M337,164.55c1.54,7.42-15.06,26-15.06,26l-14.35-11a96.85,96.85,0,0,1,11.92-16.94C326.3,155.24,335.25,156.4,337,164.55Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M325,169.72c-4,1.46-6.11,11.17-8.41,16.76l5.31,4.06a121.88,121.88,0,0,0,10.86-14.32C330.85,172,329.57,168.06,325,169.72Z"
          style={{
            opacity: 0.2,
          }}
        />
        <polygon
          points="289.72 379.67 287.93 389.03 295.5 391.4 297.3 382.04 289.72 379.67"
          style={{
            opacity: 0.2,
          }}
        />
        <polygon
          points="356.38 389.77 348.44 389.77 351.63 399.24 359.57 399.24 356.38 389.77"
          style={{
            opacity: 0.2,
          }}
        />
        <path
          d="M372.46,164.18a178.64,178.64,0,0,0-7.24,25.58c-1.27,6.06-2.22,11.87-3,17.15-1.72,12.36-2.25,21.86-2.89,25-6.15-1-29.31-4.56-41.93-6.54-1.91-45.45,11-67.68,11-67.68a81.67,81.67,0,0,1,10.21-.39c.71,0,1.44,0,2.18.1a157.55,157.55,0,0,1,17.83,2.78c1.12.23,2.24.49,3.34.76C367.61,162.38,372.46,164.18,372.46,164.18Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M366.66,180.58l-8.79,9.76s-1.6,17.51,3.17,26.53c.33-3,.73-6.37,1.23-10,.73-5.28,1.68-11.09,3-17.15.66-3.16,1.41-6.39,2.25-9.65Z"
          style={{
            opacity: 0.2,
          }}
        />
        <path
          d="M360.06,139.87c-2,5.56-4.78,15.89-1.36,20.35a18.07,18.07,0,0,1-14.6,3.32c-4.92-.88-4.63-4.36-3.23-6.1,6.64-.53,7.21-5.3,6.79-9.93Z"
          style={{
            fill: "#ff8b7b",
          }}
        />
        <path
          d="M355,143l-7.34,4.51a18.65,18.65,0,0,1,.05,3.25c2.5,0,6.28-2.13,6.93-4.65A8.8,8.8,0,0,0,355,143Z"
          style={{
            opacity: 0.2,
          }}
        />
        <path
          d="M346.65,116c-5.6-2.6-8.14,16.2-4.21,18.91S357.12,120.84,346.65,116Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M362.09,131.35c-1.69,7.69-2.29,12.28-6.83,15.65-6.83,5.08-15.86,0-16.51-8-.58-7.23,2.28-18.59,10.36-20.5A10.74,10.74,0,0,1,362.09,131.35Z"
          style={{
            fill: "#ff8b7b",
          }}
        />
        <path
          d="M360.36,138.87c-3.49,2-3.35-21.62,3.47-18.15C375.39,126.59,368.61,134.1,360.36,138.87Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M368.57,125c-3.17,2.9-32.41-15.27-19.92-18.2C362.64,103.49,374.65,119.4,368.57,125Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M362.5,125.29c-3.57,2.39-37.45-4.32-29.2-14.15C343.16,99.38,369.9,120.33,362.5,125.29Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M364.26,140.46a7.51,7.51,0,0,1-5.12,2.54c-2.62.16-3.38-2.31-2.19-4.5,1.06-2,3.71-4.42,6.12-3.55S365.84,138.61,364.26,140.46Z"
          style={{
            fill: "#ff8b7b",
          }}
        />
        <path
          d="M285.64,386.39l12.82,4s12.94-46.88,17.35-71.2c1.34-7.4,4.2-17.62,7.63-28.53,4.32-13.74,9.54-28.56,13.77-40.14,4.46-12.21,7.8-20.83,7.8-20.83l-27.57-4.3s-22.39,64.12-25.27,88.17C289.18,338.57,285.64,386.39,285.64,386.39Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M285.64,386.39l12.82,4s12.94-46.88,17.35-71.2c1.34-7.4,4.2-17.62,7.63-28.53,4.32-13.74,9.54-28.56,13.77-40.14,4.46-12.21,7.8-20.83,7.8-20.83l-27.57-4.3s-22.39,64.12-25.27,88.17C289.18,338.57,285.64,386.39,285.64,386.39Z"
          style={{
            opacity: 0.6000000000000001,
          }}
        />
        <path
          d="M323.44,290.68c4.32-13.74,9.54-28.56,13.77-40.14l-2.35-6.44C327.29,251.68,323.28,276.82,323.44,290.68Z"
          style={{
            opacity: 0.2,
          }}
        />
        <path
          d="M332.31,227.73s-2.77,67.94-1.39,91.14c1.51,25.41,17,78.54,17,78.54h14.53s-6.24-48.24-8.7-72.94c-2.8-28.13,5.64-92.52,5.64-92.52Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M332.31,227.73s-2.77,67.94-1.39,91.14c1.51,25.41,17,78.54,17,78.54h14.53s-6.24-48.24-8.7-72.94c-2.8-28.13,5.64-92.52,5.64-92.52Z"
          style={{
            opacity: 0.6000000000000001,
          }}
        />
        <polygon
          points="346.16 397.72 363.27 397.72 363.27 392.61 344.14 392.27 346.16 397.72"
          style={{
            fill: `#${color}`,
          }}
        />
        <polygon
          points="283.93 386.19 299.21 390.98 300.76 386.04 283.76 380.43 283.93 386.19"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M350,131.68c-.1.63-.51,1.09-.92,1s-.67-.63-.57-1.26.51-1.09.92-1S350.07,131.05,350,131.68Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M342.83,130.57c-.1.63-.52,1.09-.93,1s-.67-.62-.57-1.26.51-1.09.93-1S342.92,129.93,342.83,130.57Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M345.05,131.24a23,23,0,0,1-3.86,4.93,3.68,3.68,0,0,0,2.93,1Z"
          style={{
            fill: "#ff5652",
          }}
        />
        <path
          d="M346.52,139.32a6.12,6.12,0,0,1-.83-.08.18.18,0,0,1-.16-.21.2.2,0,0,1,.21-.16,5,5,0,0,0,4.39-1.32.19.19,0,0,1,.27,0,.18.18,0,0,1,0,.26A5.26,5.26,0,0,1,346.52,139.32Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M352.54,129.72a.42.42,0,0,1-.33-.2,3,3,0,0,0-2.18-1.61.38.38,0,1,1,.06-.75,3.73,3.73,0,0,1,2.78,2,.38.38,0,0,1-.15.51A.41.41,0,0,1,352.54,129.72Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M340.13,127.63a.44.44,0,0,1-.21-.07.37.37,0,0,1-.09-.52,3.78,3.78,0,0,1,3-1.68.38.38,0,1,1,0,.76,2.87,2.87,0,0,0-2.35,1.35A.42.42,0,0,1,340.13,127.63Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M350.94,408.6a2.43,2.43,0,0,1-1.53-.4,1.16,1.16,0,0,1-.39-1,.58.58,0,0,1,.33-.53c.87-.46,3.43,1.12,3.72,1.3a.2.2,0,0,1,.09.19.21.21,0,0,1-.14.15A9.69,9.69,0,0,1,350.94,408.6ZM349.76,407a.54.54,0,0,0-.24.05.23.23,0,0,0-.12.22.76.76,0,0,0,.25.68c.44.37,1.47.42,2.82.15A7.61,7.61,0,0,0,349.76,407Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M353,408.32a.15.15,0,0,1-.09,0c-.82-.44-2.41-2.18-2.24-3.07a.62.62,0,0,1,.6-.49,1.08,1.08,0,0,1,.8.24c.92.76,1.11,3,1.12,3.14a.19.19,0,0,1-.09.17A.17.17,0,0,1,353,408.32Zm-1.6-3.21h-.09c-.24,0-.26.15-.27.19-.1.53.91,1.84,1.73,2.46a4.54,4.54,0,0,0-.94-2.49A.66.66,0,0,0,351.37,405.11Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M285.49,397.78h0c-1.3-.14-3.56-.52-4.16-1.52a1.06,1.06,0,0,1-.06-1,.6.6,0,0,1,.44-.38c1.09-.23,3.64,2.27,3.93,2.56a.18.18,0,0,1,0,.21A.18.18,0,0,1,285.49,397.78Zm-3.65-2.52h-.07a.22.22,0,0,0-.17.15.68.68,0,0,0,0,.65c.34.56,1.56,1,3.32,1.27C283.93,396.39,282.48,395.24,281.84,395.26Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M285.49,397.78a.24.24,0,0,1-.13,0c-.78-.69-2.08-2.9-1.67-3.71.1-.19.32-.4.82-.29a1.33,1.33,0,0,1,.9.62c.72,1.09.28,3.19.26,3.28a.17.17,0,0,1-.12.14Zm-1.25-3.71c-.16,0-.2.09-.21.12-.25.48.58,2.16,1.33,3a4.4,4.4,0,0,0-.26-2.65,1,1,0,0,0-.66-.45Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M376.66,173.51c-.15,1.38-.3,2.57-.51,3.84s-.41,2.49-.64,3.73c-.43,2.48-1,4.93-1.62,7.39a92.66,92.66,0,0,1-5,14.56l-.83,1.79-.91,1.78-.23.45-.11.22-.18.32a9.47,9.47,0,0,1-1.28,1.78,8.05,8.05,0,0,1-4.54,2.48,9,9,0,0,1-4-.26,13.72,13.72,0,0,1-2.64-1.1,21.36,21.36,0,0,1-3.72-2.63,33.94,33.94,0,0,1-2.91-2.82c-.91-1-1.75-1.94-2.55-2.95a55.76,55.76,0,0,1-4.39-6.27l4.6-3.79a95.92,95.92,0,0,0,10.86,8.23,14.4,14.4,0,0,0,2.44,1.18,2,2,0,0,0,.75.16c.12,0,0-.12-.44-.08a2.65,2.65,0,0,0-1.46.71c-.15.15-.22.25-.26.28h0l0-.09.07-.19.16-.38.62-1.51.57-1.55a103.34,103.34,0,0,0,3.67-13.12c.51-2.26,1-4.53,1.39-6.81s.79-4.64,1.12-6.76Z"
          style={{
            fill: "#ff8b7b",
          }}
        />
        <path
          d="M378.21,168.8c4.31,6.24-2.42,27.58-2.42,27.58l-19-4.53a109.51,109.51,0,0,1,4.6-19.42C365,162.36,373,161.24,378.21,168.8Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M345.78,192.55,341.11,186l-5.06,8.78s5.22,4.71,8,2.23Z"
          style={{
            fill: "#ff8b7b",
          }}
        />
        <polygon
          points="334.77 184.05 330.93 191.51 336.06 194.76 341.11 185.98 334.77 184.05"
          style={{
            fill: "#ff8b7b",
          }}
        />
        <path
          d="M314.3,187.72l4.93-6.37,4.69,9s-5.4,4.48-8.1,1.89Z"
          style={{
            fill: "#ff8b7b",
          }}
        />
        <polygon
          points="325.65 179.69 329.18 187.3 323.92 190.34 319.23 181.35 325.65 179.69"
          style={{
            fill: "#ff8b7b",
          }}
        />
      </g>
      <g id="freepik--character-1--inject-92">
        <path
          d="M142.8,132c-1.72-3.25-.66-8.77,8.59-8.85s19.7.35,18.1-8.07c8,1.33,13.5,8.49,9.64,13.2-6.19,7.53-1.85,12.86-6.21,15.76-6.07,4.06-8,24-21.31,19.09C152.85,155.75,132.05,138.72,142.8,132Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M185.82,182c.22,2.39.51,5,.87,7.49s.72,5,1.22,7.52a101.1,101.1,0,0,0,3.82,14.54l.65,1.72.3.78c0,.05,0,0-.07,0a1.24,1.24,0,0,0-.73-.33c-.14,0,.06.07.37,0a9.91,9.91,0,0,0,2.63-1.12,27.8,27.8,0,0,0,3-1.92,73,73,0,0,0,5.91-4.71c1.93-1.67,3.87-3.43,5.72-5.17l3.32,2.8a77.32,77.32,0,0,1-10.7,12.34,37.58,37.58,0,0,1-3.38,2.73,16.82,16.82,0,0,1-4.45,2.3,8.39,8.39,0,0,1-3.52.36,6.38,6.38,0,0,1-2.24-.7,6.59,6.59,0,0,1-1.87-1.48,7.38,7.38,0,0,1-1.12-1.69,2.64,2.64,0,0,1-.15-.3l-.11-.24-.22-.48-.86-1.92a89.08,89.08,0,0,1-4.82-15.76c-.57-2.66-1.08-5.32-1.45-8s-.7-5.33-.87-8.18Z"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <path
          d="M182.5,172.06c3,.78,4.74,20.81,4.74,20.81l-8.06,4.85s-4.87-14.8-4.13-21.19C175.59,171.88,180.22,171.48,182.5,172.06Z"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <path
          d="M209.41,200.2l4.63-7.32,2.63,8.31s-3.8,4-7.24,3Z"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <polygon
          points="220.84 190.45 222.66 197.54 216.67 201.19 214.04 192.88 220.84 190.45"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <polygon
          points="165.28 407.52 173.86 407.52 177.5 376.01 165.73 376.01 165.28 407.52"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <polygon
          points="111.4 395.94 119.71 398.09 132.47 369.17 120.97 365.92 111.4 395.94"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <path
          d="M121,396.71l-8.87-3.77a.67.67,0,0,0-.86.27l-4,6.59a1.38,1.38,0,0,0,.67,1.94c3.12,1.26,4.69,1.71,8.59,3.37,2.4,1,5.8,2.76,9.11,4.17s4.89-1.8,3.63-2.68c-5.62-4-5.82-6.21-7.16-8.85A2.24,2.24,0,0,0,121,396.71Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M174.15,406.52h-9.64a.68.68,0,0,0-.68.59l-1.1,7.63a1.38,1.38,0,0,0,1.38,1.52c3.36-.06,5-.25,9.22-.25,2.6,0,8,.27,11.6.27s3.79-3.56,2.29-3.89c-6.72-1.44-9.37-3.44-11.63-5.34A2.26,2.26,0,0,0,174.15,406.52Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M183,172.05s4.08,1.44-4.1,51.78H144.09c.59-14.18.6-22.92-6.17-52a102.56,102.56,0,0,1,14.79-2,111.24,111.24,0,0,1,15.82,0C175.33,170.47,183,172.05,183,172.05Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M154.28,152c1,5.09,2,14.42-1.57,17.81a60.33,60.33,0,0,0,15.35,11.77c4.94-8,.47-11.77.47-11.77-5.67-1.35-5.53-5.56-4.54-9.52Z"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <path
          d="M154.68,165.53a42.79,42.79,0,0,0,9-.18.13.13,0,0,1,.14.08c.09.23.33.86.52,1.29a.12.12,0,0,1-.05.16c-1,.51-5.95,1.16-10,.4a.13.13,0,0,1-.1-.17c.16-.6.33-1.24.39-1.48A.11.11,0,0,1,154.68,165.53Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M158.24,155.42l5.75,4.91a16.28,16.28,0,0,0-.49,2.8c-2.17-.31-5.15-2.69-5.38-5A7.85,7.85,0,0,1,158.24,155.42Z"
          style={{
            opacity: 0.2,
          }}
        />
        <path
          d="M148.91,142.7c2,8.28,2.66,11.82,7.65,15.37,7.5,5.33,17.23,1.07,17.75-7.63.47-7.84-2.88-20.06-11.66-21.94A11.61,11.61,0,0,0,148.91,142.7Z"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <path
          d="M178.94,223.83s6.76,66.09,6.2,92.25c-.58,27.22-6,60.87-6,60.87H164.18s.64-32.06-.39-58.79c-1.11-29.14-10.37-94.33-10.37-94.33Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <polygon
          points="162.9 378.44 179.77 378.44 181 374.1 162.11 373.57 162.9 378.44"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M163.91,240.28c8.55,17.21,1.71,48.62-1.31,60.3-1.55-17.55-4.17-39-6.21-54.75C157.69,236.92,160,232.34,163.91,240.28Z"
          style={{
            opacity: 0.2,
          }}
        />
        <path
          d="M169.73,223.83s-7.89,68.71-13.85,90.77c-7.08,26.18-21.54,55.1-21.54,55.1l-15-4.23s8.41-35.3,13.82-49.1c11.55-29.44,1.66-73.32,10.89-92.54Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <polygon
          points="118.45 367.75 134 372.06 136.8 368.07 119.55 362.32 118.45 367.75"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M162.89,142.87c.12.68.58,1.17,1,1.09s.71-.7.59-1.38-.58-1.17-1-1.09S162.77,142.19,162.89,142.87Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M170.59,141.5c.13.68.58,1.17,1,1.09s.71-.69.59-1.37-.58-1.17-1-1.09S170.47,140.82,170.59,141.5Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M171,140.2l1.56-.76S171.93,140.86,171,140.2Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M168.41,143a18.61,18.61,0,0,0,3.2,3.92,3,3,0,0,1-2.35.88Z"
          style={{
            fill: "#ed847e",
          }}
        />
        <path
          d="M165.66,151a6.91,6.91,0,0,0,1.16-.1.2.2,0,0,0,.16-.24.19.19,0,0,0-.23-.16,5.49,5.49,0,0,1-4.78-1.33.19.19,0,0,0-.28,0,.21.21,0,0,0,0,.29A5.7,5.7,0,0,0,165.66,151Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M146.13,130.83c4.92,2.42,9.83,1.37,10.08,7.48s-8.16,16-10.08,9S146.13,130.83,146.13,130.83Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M147.6,134c6.35,2.57,23.84,3.24,23.46-4.6s-15.5-4.37-19.83-2.69S147.6,134,147.6,134Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M148.56,148.94a6.2,6.2,0,0,0,3.37,3.35c2,.82,3.27-.94,2.91-3-.33-1.83-1.77-4.47-3.9-4.41A2.79,2.79,0,0,0,148.56,148.94Z"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <path
          d="M159.91,138.31a.37.37,0,0,0,.29-.13,3.28,3.28,0,0,1,2.74-1.06.42.42,0,0,0,.48-.32.42.42,0,0,0-.33-.48,4.06,4.06,0,0,0-3.48,1.3.42.42,0,0,0,0,.58A.43.43,0,0,0,159.91,138.31Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M172.52,137.26a.38.38,0,0,0,.28-.11.4.4,0,0,0,0-.57,4,4,0,0,0-3.48-1.29.4.4,0,0,0-.32.47.41.41,0,0,0,.47.33,3.23,3.23,0,0,1,2.74,1A.42.42,0,0,0,172.52,137.26Z"
          style={{
            fill: "#263238",
          }}
        />
        <path
          d="M142.27,184.29c-1.73,4.52-3.39,9.27-4.86,13.94A102.5,102.5,0,0,0,134,212.31c-.11.58-.16,1.15-.22,1.72l-.09.85,0,.36,0,0a4.65,4.65,0,0,0,.71,1.86,33.34,33.34,0,0,0,4.11,5.38c3.21,3.59,6.91,7,10.56,10.38l-2.53,3.54a84.37,84.37,0,0,1-12.79-9.07,35.21,35.21,0,0,1-5.74-6.16,11.75,11.75,0,0,1-2.16-5.12,7.43,7.43,0,0,1-.06-1c0-.21,0-.22,0-.33v-.26l0-1c0-.7.06-1.4.14-2.07a87.68,87.68,0,0,1,3.13-15.59,135.35,135.35,0,0,1,5.08-14.8Z"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <path
          d="M138,171.8c-3.42,1.4-7.7,20.11-7.7,20.11l7.27,6s6.85-14,7.15-20.38C144.9,172.66,141,170.57,138,171.8Z"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <path
          d="M124.94,399.41a1.2,1.2,0,0,0,.62-.81.68.68,0,0,0-.15-.68c-.74-.81-3.8-.28-4.15-.22a.23.23,0,0,0-.16.16.2.2,0,0,0,.08.21c.92.67,2.62,1.75,3.64,1.39A.43.43,0,0,0,124.94,399.41ZM121.82,398c1.23-.17,2.9-.25,3.29.17a.29.29,0,0,1,0,.31.76.76,0,0,1-.47.58C124.13,399.27,123.08,398.88,121.82,398Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M122.69,397.64c.89-.43,1.8-1.09,1.93-1.7a.71.71,0,0,0-.41-.79,1.09,1.09,0,0,0-.9-.09c-1.17.4-2.16,2.67-2.2,2.76a.2.2,0,0,0,0,.2.18.18,0,0,0,.18.08A4.76,4.76,0,0,0,122.69,397.64Zm.63-2.14.12-.05a.67.67,0,0,1,.57.05c.22.13.23.26.21.35-.12.59-1.56,1.49-2.57,1.77A5.22,5.22,0,0,1,123.32,395.5Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M177.37,408a3.23,3.23,0,0,0,2.09-.53,1.12,1.12,0,0,0,.36-1,.67.67,0,0,0-.34-.55c-1-.57-4.47,1.18-4.86,1.38a.2.2,0,0,0-.11.21.21.21,0,0,0,.16.17A15.14,15.14,0,0,0,177.37,408Zm1.53-1.77a.81.81,0,0,1,.38.07.25.25,0,0,1,.14.22.71.71,0,0,1-.24.65c-.52.47-1.93.55-3.83.24A11,11,0,0,1,178.9,406.25Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M174.71,407.72l.09,0c1-.46,3-2.31,2.88-3.28,0-.23-.2-.52-.76-.58a1.52,1.52,0,0,0-1.12.35c-1.08.9-1.28,3.21-1.29,3.31a.21.21,0,0,0,.09.18A.15.15,0,0,0,174.71,407.72Zm2-3.48h.12c.37,0,.4.18.4.23.1.59-1.27,2-2.32,2.67a4.69,4.69,0,0,1,1.1-2.64A1,1,0,0,1,176.76,404.24Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M143.57,221.79l-.89,3.12c-.12.24.16.48.57.48h35.86c.31,0,.57-.15.59-.35l.32-3.12c0-.21-.25-.39-.6-.39H144.14A.63.63,0,0,0,143.57,221.79Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M143.57,221.79l-.89,3.12c-.12.24.16.48.57.48h35.86c.31,0,.57-.15.59-.35l.32-3.12c0-.21-.25-.39-.6-.39H144.14A.63.63,0,0,0,143.57,221.79Z"
          style={{
            fill: "#fff",
            opacity: 0.8,
          }}
        />
        <path
          d="M148.31,225.73h-.95c-.18,0-.33-.09-.31-.21l.44-4.06c0-.11.18-.21.36-.21h1c.19,0,.33.1.32.21l-.44,4.06C148.66,225.64,148.5,225.73,148.31,225.73Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M176.45,225.73h-.94c-.19,0-.33-.09-.32-.21l.44-4.06c0-.11.18-.21.37-.21h.94c.19,0,.33.1.32.21l-.44,4.06C176.81,225.64,176.64,225.73,176.45,225.73Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M162.38,225.73h-.94c-.19,0-.34-.09-.32-.21l.44-4.06c0-.11.18-.21.37-.21h.94c.19,0,.33.1.32.21l-.44,4.06C162.73,225.64,162.57,225.73,162.38,225.73Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M148.06,232.11l9.24.79-6.17,6.16s-5.29-1.53-6-5Z"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <path
          d="M210.3,189l-6,28.7a2.88,2.88,0,0,0,2.23,3.41l54.64,11.46a2.91,2.91,0,0,0,3.42-2.24l6-28.7a2.91,2.91,0,0,0-2.23-3.42l-54.64-11.45a2.93,2.93,0,0,0-2.08.36A2.87,2.87,0,0,0,210.3,189Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M210.3,189l-6,28.7a2.88,2.88,0,0,0,2.23,3.41l54.64,11.46a2.91,2.91,0,0,0,3.42-2.24l6-28.7a2.91,2.91,0,0,0-2.23-3.42l-54.64-11.45a2.93,2.93,0,0,0-2.08.36A2.87,2.87,0,0,0,210.3,189Z"
          style={{
            opacity: 0.5,
          }}
        />
        <path
          d="M270.12,199.41l-8.63,4.39-17.12,8.69a11.74,11.74,0,0,1-14.41-3L218.11,195l-6.48-7.89a3,3,0,0,1,2.09-.36l4.77,1,43.36,9.09,6.5,1.36A2.92,2.92,0,0,1,270.12,199.41Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M263.56,164.49l-1.71,32.39-.36,6.92-17.12,8.69a11.74,11.74,0,0,1-14.41-3L218.11,195l.38-7.25,1.35-25.6a2.65,2.65,0,0,1,2.76-2.49l38.47,2A2.64,2.64,0,0,1,263.56,164.49Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M263.56,164.49l-1.71,32.39-.36,6.92-17.12,8.69a11.74,11.74,0,0,1-14.41-3L218.11,195l.38-7.25,1.35-25.6a2.65,2.65,0,0,1,2.76-2.49l38.47,2A2.64,2.64,0,0,1,263.56,164.49Z"
          style={{
            fill: "#fff",
            opacity: 0.9,
          }}
        />
        <path
          d="M232,205.49,218.27,192l-.16,3L230,209.47a11.74,11.74,0,0,0,14.41,3l17.12-8.69.17-3.24-17.26,7.28A11.37,11.37,0,0,1,232,205.49Z"
          style={{
            opacity: 0.1,
          }}
        />
        <path
          d="M224,171.92l.42-7.93,1.6.09L225.6,172Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M227.14,172.09l.42-7.93,1.56.08,3,5.47.27-5.3,1.49.08-.42,7.93-1.6-.08L228.9,167l-.27,5.17Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M237.15,172.62l-2.42-8.08,1.74.09,1.7,6,2.25-5.76,1.7.09-3.26,7.78Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M242.47,172.9l.42-7.93,1.6.08-.42,7.93Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M247.38,173.16l.35-6.59-2.35-.13.07-1.34,6.3.33-.07,1.34-2.35-.12-.35,6.59Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <path
          d="M252.36,173.42l.42-7.93,5.88.31-.07,1.34-4.28-.23-.09,1.76,4,.21-.07,1.34-4-.21-.11,2.16,4.43.23-.07,1.34Z"
          style={{
            fill: `#${color}`,
          }}
        />
        <polygon
          points="162.58 237.81 157.13 242.69 149.81 238.57 157.3 232.9 162.58 237.81"
          style={{
            fill: "#ffc3bd",
          }}
        />
        <path
          d="M163.29,141.56l1.55-.76S164.23,142.23,163.29,141.56Z"
          style={{
            fill: "#263238",
          }}
        />
      </g>
    </svg>
  )
}
