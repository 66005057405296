import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography, IconButton } from '@mui/material';

import Iconify from 'components/Iconify'

import { setMask } from 'utils';

import MainCard from './_MainCard';
import Skeleton from './_Skeleton';

const CardWrapper = styled(MainCard, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
  backgroundColor: theme.palette[color].light,
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    width: 210,
    height: 210,
    background: theme.palette[color].main,
    borderRadius: '50%',
    top: -85,
    right: -95,
    [theme.breakpoints.down('sm')]: {
      top: -105,
      right: -140
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: -2,
    width: 210,
    height: 210,
    background: theme.palette[color].lighter,
    borderRadius: '50%',
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: -155,
      right: -70
    }
  }
}));

const AdminMetricsItem = ({ isLoading, color, title, description, value, refresh }) => (
  <>
    {isLoading ? (
      <Skeleton />
    ) : (
      <CardWrapper border={false} content={false} color={color}>
        <Box sx={{ p: 2.25 }}>
          <Grid container>
            <Grid xs={typeof refresh === 'function' ? 9 : 12} item sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2">
                <b>{title}</b> {description}
              </Typography>
            </Grid>
            {
              typeof refresh === 'function' ? (
                <Grid xs={3} item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={() => refresh()} type="link" sx={{ p: 1 }}>
                    <Iconify icon="material-symbols:refresh" sx={{ color: 'white', width: 25, height: 25 }} />
                  </IconButton>
                </Grid>
              ) : null
            }
            <Grid xs={12} item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography sx={{ fontSize: '2.125rem', fontWeight: 500 }}>{setMask('money', value)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CardWrapper>
    )}
  </>
)

AdminMetricsItem.propTypes = {
  isLoading: PropTypes.bool,
  color: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.number,
  refresh: PropTypes.func
};

export default AdminMetricsItem;
