import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import {
  Breadcrumbs,
  Link,
  Typography
} from '@mui/material'

const _Breadcrumbs = ({ items }) => (
  <Breadcrumbs aria-label="breadcrumb">
    {
      items.map((item, key) => (
        item.path ? (
          <Link
            key={key}
            underline="hover"
            color="inherit"
            to={item.path}
            component={RouterLink}
          >
            {item.label}
          </Link>
        ) : (
          <Typography
            key={key}
            color="text.primary"
          >
            {item.label}
          </Typography>
        )
      ))
    }
  </Breadcrumbs>
)

_Breadcrumbs.propTypes = {
  items: PropTypes.array
}

export default _Breadcrumbs
