import { Navigate, useRoutes } from 'react-router-dom';

import { SimpleLayout, DashboardLayout } from 'layouts';

// public
import Login from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import AcceptInvite from 'pages/AcceptInvite';

// private
import Home from 'pages/Home';
import InvitesInvited from 'pages/Invites/Invited';
import InvitesProvider from 'pages/Invites/Provider';
import SellersInvited from 'pages/Sellers/Invited';
import SellersProvider from 'pages/Sellers/Provider';
import SellersDetail from 'pages/Sellers/Detail';
import ClientsInvited from 'pages/Clients/Invited';
import ClientsProvider from 'pages/Clients/Provider';
import ClientsDetail from 'pages/Clients/Detail';
import ContractsCreated from 'pages/Contracts/Created';
import ContractsProvider from 'pages/Contracts/Provider';
import ContractDetail from 'pages/Contracts/Detail';
import ContractsEndedClient from 'pages/Contracts/ClientEndedList';
import ContractClientDetail from 'pages/Contracts/ClientDetail';
import MyProfile from 'pages/MyProfile';
import ContractParams from 'pages/Settings/ContractParams';
import Terms from 'pages/Settings/Terms';
import Privacy from 'pages/Settings/Privacy';
import TermsPublic from 'pages/Terms';

// global
import Page404 from 'pages/404';

// ACL routers
import Public from './Public';
import Private from './Private';

export default function Router() {
  const routes = useRoutes([
    // public routes
    {
      path: 'auth',
      element: <Public />,
      children: [
        { element: <Login />, index: true },
        { path: 'recuperar-senha', element: <ForgotPassword /> },
        { path: 'redefinir-senha/:token', element: <ResetPassword /> },
        { path: 'convite/:token', element: <AcceptInvite /> },
      ],
    },

    // all users routes
    {
      path: '/',
      element: <Private />,
      children: [
        {
          element: <DashboardLayout />,
          children: [
            { path: '', element: <Home />, index: true },
            { path: 'meu-perfil', element: <MyProfile /> },
          ],
        },
      ],
    },

    // admin routes
    {
      path: '/',
      element: <Private profiles={['admin']} />,
      children: [
        {
          element: <DashboardLayout />,
          children: [
            { path: 'convites/todos', element: <InvitesProvider /> },
            { path: 'vendedores/todos', element: <SellersProvider /> },
            { path: 'vendedores/todos/perfil/:id', element: <SellersDetail /> },
            { path: 'clientes/todos', element: <ClientsProvider /> },
            { path: 'clientes/todos/perfil/:id', element: <ClientsDetail /> },
            { path: 'contratos/todos', element: <ContractsProvider /> },
            { path: 'contratos/todos/detalhe/:id', element: <ContractDetail /> },
            { path: 'configuracoes/contrato', element: <ContractParams /> },
            { path: 'configuracoes/termos', element: <Terms /> },
            { path: 'configuracoes/privacidade', element: <Privacy /> },
          ],
        },
      ],
    },

    // admin and seller routes
    {
      path: '/',
      element: <Private profiles={['admin', 'seller']} />,
      children: [
        {
          element: <DashboardLayout />,
          children: [
            { path: 'convites', element: <InvitesInvited /> },
            { path: 'vendedores', element: <SellersInvited /> },
            { path: 'vendedores/perfil/:id', element: <SellersDetail /> },
            { path: 'clientes', element: <ClientsInvited /> },
            { path: 'clientes/perfil/:id', element: <ClientsDetail /> },
            { path: 'contratos', element: <ContractsCreated /> },
            { path: 'contratos/detalhe/:id', element: <ContractDetail /> },
          ],
        },
      ],
    },

    // client routes
    {
      path: '/',
      element: <Private profiles={['client']} />,
      children: [
        {
          element: <DashboardLayout />,
          children: [
            { path: 'encerrados', element: <ContractsEndedClient /> },
            { path: 'cliente/contrato/:id', element: <ContractClientDetail /> }
          ],
        },
      ],
    },

    // terms routes
    {
      element: <SimpleLayout />,
      children: [
        { path: 'termos-de-uso', element: <TermsPublic /> },
        { path: 'politica-de-privacidade', element: <TermsPublic /> },
      ],
    },

    // 404 routes
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
