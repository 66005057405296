export default class BaseMask {
  getKeyboardType() {
    return 'numeric'
  }

  mergeSettings(obj1, obj2) {
    var obj3 = {}
    for (var attrname in obj1) {
      obj3[attrname] = obj1[attrname]
    }
    for (var attrname in obj2) {
      obj3[attrname] = obj2[attrname]
    }
    return obj3
  }

  getRawValue(value) {
    return value
  }

  getDefaultValue(value) {
    if (value === undefined || value === null) {
      return ''
    }

    return value
  }

  getMask(_, settings) {
    return settings.mask
  }

  removeNotNumbers(value) {
    return value.replace(/[^0-9]+/g, '')
  }

  removeWhiteSpaces(value) {
    return (value || '').replace(/\s/g, '')
  }
}
