import { API } from 'services';

export function getContracts() {
  return API({
    endpoint: '/contract',
  });
}

export function getContractsProvider() {
  return API({
    endpoint: '/contract/provider',
  });
}

export function getContractsSeller(sellerId) {
  return API({
    endpoint: `/contract/seller/${sellerId}`,
  });
}

export function getContract({ id, ...params }) {
  return API({
    endpoint: `/contract/detail/${id}`,
    ...params
  })
}

export function createContract(body, companyId){
  return API({
    method: 'POST',
    endpoint: `/contract/${companyId}`,
    body,
  })
}

export function cancelContract(contractId){
  return API({
    method: 'PUT',
    endpoint: `/contract/status/cancel/${contractId}`,
  })
}


export function contractPayment({ id, ...params }) {
  return API({
    method: 'POST',
    endpoint: `/contract/payment/${id}`,
    ...params
  })
}

export function getInstallments(contractId){
  return API({
    endpoint: `/installment/contract/${contractId}`,
  })
}

export function chargeInstallments({ body, ...params }){
  return API({
    method: 'POST',
    endpoint: `/installment/charge`,
    body,
    ...params
  })
}

export function getContractParams() {
  return API({
    endpoint: `/contract-params`
  })
}

export function updateContractParams({ body, ...params }) {
  return API({
    method: 'PUT',
    endpoint: `/contract-params`,
    body,
    ...params
  })
}

export function getBillingReceipt({ id, ...params }) {
  return API({
    endpoint: `/openpix/billing-receipt/${id}`,
    ...params
  })
}

export function getPaymentVoucher({ id, ...params }) {
  return API({
    endpoint: `/openpix/payment-voucher/${id}`,
    ...params
  })
}
