import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Grid, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Iconify from 'components/Iconify';
import ContractClientCard from 'components/DashCards/ClientContractItem';

import { getContracts } from 'services/requests/contract';
import { toast } from 'utils';

dayjs.extend(utc);

export default function ContractsProvider() {
  const { t } = useTranslation('common');

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const getData = () => {
    setLoadingData(true)

    getContracts()
      .then((res) => {
        const parsed = res
          .filter(item => (item.status === 'cancelled' || item.status === 'finished' || item.status === 'denied'))
          .map(({ installmentscount, installmentspayed, status, ...rest }) => ({
            ...rest, status,
            installmentsLabel: `Parcelas pagas: ${installmentspayed} de ${installmentscount}`,
            statusLabel: t(`contracts.status.${status}`),
          }))

        setData(parsed)
      })
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingData(false));
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>Contratos passados | { process.env.REACT_APP_PROJECT_NAME }</title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container rowSpacing={2} columnSpacing={2} my={1}>
          <Grid xs={12} item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={loadingData}
              onClick={() => getData()}
              type="link"
              size="small"
              endIcon={<Iconify icon="material-symbols:refresh" />}
            >
              Atualizar lista
            </Button>
          </Grid>

          {
            !loadingData ? (
              data.length > 0 ? (
                data.map((item, key) => (
                  <Grid item xs={12} md={6} key={key}>
                    <ContractClientCard data={item} />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} md={12}>
                  <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', my: 5 }}>
                    <Box
                      component="img"
                      src="/assets/illustrations/illustration_empty_content.svg"
                      sx={{ height: 160, mx: 'auto', mb: 5 }}
                    />
                    <Typography variant="h6" paragraph mb={3}>
                      Ainda não existem contratos encerrados.
                    </Typography>
                  </Box>
                </Grid>
              )
            ) : (
              [1, 2].map(key => (
                <Grid item xs={12} md={6} key={key}>
                  <ContractClientCard isLoading />
                </Grid>
              ))
            )
          }
        </Grid>
      </Container>
    </>
  )
}
