import { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Typography,
  Stack,
  Box,
  CircularProgress,
  Button,
  Stepper,
  Step,
  StepLabel
} from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';

import Logo from 'components/Logo';
import IllustrationInvite from 'components/Illustrations/Invite'
import {
  AcceptInviteFormStepCompany,
  AcceptInviteFormStepCompanyAddress,
  AcceptInviteFormStepPersonal,
  AcceptInviteFormStepPersonalAddress,
  AcceptInviteFormStepAccess,
  AcceptInviteFormStepPersonalDocument
} from 'sections/auth';

import { UserContext } from 'contexts';
import { getInvitePreview } from 'services/requests/invite'
import { useResponsive, removeMask, toast } from 'utils';

import formModel from './FormModel/formModel';
import initialValues from './FormModel/initialValues';
import { StyledRoot, StyledSection, StyledContent } from './styled';

const steps = ['Dados da empresa', 'Endereço comercial', 'Dados do responsável', 'Endereço do responsával', 'Dados de acesso', 'Documentos do responsável'];
const { formId, formField } = formModel;

function _renderStepContent({ activeStep, ...props }) {
  switch (activeStep) {
    case -1:
      return null;
    case 0:
      return (
        <AcceptInviteFormStepCompany
          formField={formField}
          {...props}
        />
      );
    case 1:
      return (
        <AcceptInviteFormStepCompanyAddress
          formField={formField}
          {...props}
        />
      );
    case 2:
      return (
        <AcceptInviteFormStepPersonal
          formField={formField}
          {...props}
        />
      );
    case 3:
      return (
        <AcceptInviteFormStepPersonalAddress
          formField={formField}
          {...props}
        />
      );
    case 4:
      return (
        <AcceptInviteFormStepAccess
          formField={formField}
          {...props}
        />
      );
    case 5:
      return (
        <AcceptInviteFormStepPersonalDocument
          formField={formField}
          {...props}
        />
      );
    default:
      return <div>Not Found</div>;
  }
}

export default function AcceptInvite() {
  const { t } = useTranslation('common');
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const { token } = useParams();

  const user = useContext(UserContext);

  const [activeStep, setActiveStep] = useState(-1);
  const [data, setData] = useState({});
  const [loadingData, setLoadingData] = useState(true);

  let currentValidationSchema = {};
  if (activeStep > -1) {
    // eslint-disable-next-line global-require
    const validationSchema = require('./FormModel/validationSchema')
    currentValidationSchema = validationSchema.default[activeStep];
  }

  const isLastStep = activeStep === steps.length - 1;

  async function submitForm(values, actions) {
    const {
      name,
      email,
      phone,
      cpf,
      rg,
      birthDate,
      profession,
      nationality,
      maritalStatus,
      propertyRegime,
      password,
      zipcode,
      street,
      neighborhood,
      city,
      state,
      number,
      complement,
      socialName,
      fantasyName,
      emailCompany,
      phoneCompany,
      cnpj,
      pixType,
      pix,
      zipcodeCompany,
      streetCompany,
      neighborhoodCompany,
      cityCompany,
      stateCompany,
      numberCompany,
      complementCompany,
      document
    } = values;

    const body = {
      user: {
        name,
        email,
        phone: removeMask(phone),
        cpf: removeMask(cpf),
        rg,
        birthDate: birthDate.split('/').reverse().join('-'),
        profession,
        nationality,
        maritalStatus,
        propertyRegime,
        password
      },
      userAddress: {
        zipcode,
        street,
        neighborhood,
        city,
        state,
        number,
        complement
      },
      company: {
        name: socialName,
        fantasyName,
        email: emailCompany,
        phone: removeMask(phoneCompany),
        cnpj: removeMask(cnpj),
        pixType,
        pix: (pixType === 'phone' || pixType === 'cnpj' || pixType === 'cpf') ? removeMask(pix) : pix,
      },
      companyAddress: {
        zipcode: zipcodeCompany,
        street: streetCompany,
        neighborhood: neighborhoodCompany,
        city: cityCompany,
        state: stateCompany,
        number: numberCompany,
        complement: complementCompany
      }
    }

    const formData = new FormData()
    document.forEach(file => {
      formData.append('file', file)
    })
    formData.append('body', JSON.stringify(body))

    user.exec
      .acceptInvite({ token, formData })
      .then(() => {
        toast('Conta criada com sucesso!', 'success')
        navigate('/', { replace: true })
      })
      .catch((err) => toast(err, 'error'))
      .finally(() => actions.setSubmitting(false));
  }

  function handleSubmit(values, actions) {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  const getData = () => {
    if (!token) {
      navigate('/auth', { replace: true });
      return
    }

    getInvitePreview({ token })
      .then((res) => setData(res))
      .catch((err) => {
        toast(err, 'error')
        navigate('/auth', { replace: true })
      })
      .finally(() => setLoadingData(false));
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>
          {t('auth.acceptInvite.pageTitle')} | { process.env.REACT_APP_PROJECT_NAME }
        </title>
      </Helmet>

      <StyledRoot>
        <StyledSection>
          {
            mdUp ? (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    top: { xs: 16, sm: 24, md: 40 },
                    left: { xs: 16, sm: 24, md: 40 },
                  }}
                >
                  <Logo dark />
                </Box>
                <IllustrationInvite
                  style={{ position: 'relative', width: '90%', maxWidth: 500 }}
                />
              </>
            ) : (
              <Box
                sx={{
                  position: 'relative',
                  padding: { xs: 0, sm: 2, md: 2 },
                  width: '100%'
                }}
              >
                <Logo height={24} dark />
              </Box>
            )
          }
        </StyledSection>

        <Container maxWidth="sm">
          <StyledContent>
            {
              loadingData ? (
                <Box sx={{ textAlign: 'center' }}>
                  <CircularProgress size={80} sx={{ my: 12 }} />
                </Box>
              ) : (
                <>
                  <Typography variant="subtitle1" mb={1}>
                    {t('hello')} {data.name}!
                  </Typography>
                  <Typography variant="subtitle2" sx={{ mb: activeStep < 0 ? 0 : 4 }}>
                    {
                      data.type === 'seller' ? (
                        <Trans i18nKey="common:auth.acceptInvite.descriptionSeller" />
                      ) : (
                        <Trans i18nKey="common:auth.acceptInvite.descriptionClient" />
                      )
                    }
                  </Typography>

                  {
                    activeStep > -1 ? (
                      <>
                        <Box sx={{ width: '100%' }}>
                          <Stepper activeStep={activeStep} alternativeLabel>
                            {
                              steps.map((_, key) => (
                                <Step key={key}>
                                  <StepLabel />
                                </Step>
                              ))
                            }
                          </Stepper>
                          <Typography
                            variant="subtitle1"
                            my={3}
                          >
                            {steps[activeStep]}
                          </Typography>
                        </Box>

                        <Formik
                          initialValues={{
                            ...initialValues,
                            name: data?.name || '',
                            email: data?.email || '',
                            phone: data?.phone || ''
                          }}
                          validationSchema={currentValidationSchema}
                          onSubmit={(values, actions) => handleSubmit(values, actions)}
                        >
                          {({ values, handleChange, touched, errors, setFieldValue, isSubmitting }) => (
                            <Form id={formId}>
                              {
                                _renderStepContent({
                                  activeStep, values, handleChange, touched, errors, setFieldValue
                                })
                              }

                              <Typography variant="body2" sx={{ mt: 3 }} className="terms-link">
                                Ao se cadastrar, você concorda com os <a href="/termos-de-uso" target="_blank">termos de uso</a> e com nossa <a href="/politica-de-privacidade" target="_blank">política de privacidade</a>.
                              </Typography>

                              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%' }} mt={3}>
                                {
                                  activeStep !== 0 ? (
                                    <Button
                                      sx={{ justifyContent: 'center' }}
                                      color="primary"
                                      variant="outlined"
                                      onClick={() => handleBack()}
                                      startIcon={<ArrowBack />}
                                      size="large"
                                    >
                                      {t('auth.acceptInvite.form.back')}
                                    </Button>
                                  ) : <div />
                                }
                                <LoadingButton
                                  sx={{ justifyContent: 'center' }}
                                  color="secondary"
                                  variant="contained"
                                  endIcon={isLastStep ? null : <ArrowForward />}
                                  size="large"
                                  type="submit"
                                  loading={isSubmitting}
                                >
                                  {t(`auth.acceptInvite.form.${isLastStep ? 'submit' : 'next'}`)}
                                </LoadingButton>
                              </Stack>
                            </Form>
                          )}
                        </Formik>
                      </>
                    ) : (
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {
                          mdUp ? null : (
                            <IllustrationInvite
                              style={{ position: 'relative', width: '90%', maxWidth: 500 }}
                            />
                          )
                        }

                        <LoadingButton
                          sx={{ justifyContent: 'center', mt: mdUp ? 6 : 1 }}
                          color="secondary"
                          variant="contained"
                          endIcon={<ArrowForward />}
                          size="large"
                          onClick={() => setActiveStep(0)}
                        >
                          {t('auth.acceptInvite.form.start')}
                        </LoadingButton>
                      </Box>
                    )
                  }
                </>
              )
            }
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
