import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';

import Router from 'routes';
import ThemeProvider from 'theme';
import { DialogProvider, UserProvider } from 'contexts';

import { StyledChart } from 'components/Chart';
import ScrollToTop from 'components/ScrollToTop';
// import Offline from 'components/Offline';

import 'utils/validations';

export default function App() {
  return (
    <ThemeProvider>
      <DialogProvider>
        <UserProvider>
          <StyledChart />

          <ScrollToTop />

          <Router />

          <ToastContainer
            position="top-right"
            theme="colored"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            rtl={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <AddToHomeScreen
            translate={{
              headline: 'Instalar',
              safariTapShare: 'Clique em "Compartilhar"',
              safariAddHomeScreen: 'Selecione "Adicionar à Tela de Inicío"',
              chromiumAddHomeScreen: 'Clique em "Adicionar à tela inicial" no menu do navegador',
              chromiumInstall: 'O ícone do aplicativo será exibido na sua tela inicial',
              buttonInstall: 'Instalar',
            }}
          />

          {/* <Offline /> */}
        </UserProvider>
      </DialogProvider>
    </ThemeProvider>
  );
}
