import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, TextField, Divider, Button, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { startOfDay, format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import AdminMetricsItem from 'components/DashCards/AdminMetricsItem';
import List from 'components/List';

import { getCommissionProvider, getCommissionReport, getDashboardProvider, getMetrics } from 'services/requests/dashboard';
import { setMask, toast } from 'utils';

import ExportContractReport from './ExportContractReport';

const now = startOfDay(new Date())

export default function AdminDashboard() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const [dateMetrics, setDateMetrics] = useState(now);
  const [dashMetrics, setDashMetrics] = useState({});
  const [loadingDashMetrics, setLoadingDashMetrics] = useState(true);

  const [showReportSetup, setShowReportSetup] = useState(false);

  const [dateCommission, setDateCommission] = useState(now);
  const [dashCommission, setDashCommission] = useState([]);
  const [loadingDashCommission, setLoadingDashCommission] = useState(true);
  const [loadingDashCommissionDownload, setLoadingDashCommissionDownload] = useState(false);

  const [dashContracts, setDashContracts] = useState([]);
  const [loadingDashContracts, setLoadingDashContracts] = useState(true);

  const getDataMetrics = dateBase => {
    setLoadingDashMetrics(true)
    const date = format(dateBase, 'yyyy-MM-dd')

    getMetrics({ date })
      .then((res) => setDashMetrics(res))
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingDashMetrics(false))
  }

  useEffect(() => {
    getDataMetrics(dateMetrics)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateMetrics])

  const getDataDashboard = () => {
    setLoadingDashContracts(true)

    getDashboardProvider()
      .then((res) => setDashContracts(res))
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingDashContracts(false))
  }

  useEffect(() => {
    getDataDashboard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDataCommission = dateBase => {
    setLoadingDashCommission(true)
    const date = format(dateBase, 'yyyy-MM-dd')

    getCommissionProvider({ date })
      .then((res) => setDashCommission(res))
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingDashCommission(false))
  }

  useEffect(() => {
    getDataCommission(dateCommission)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateCommission])

  const formatedDateMetrics = format(dateMetrics, 'dd/MM/yyyy')
  const formatedNow = format(now, 'dd/MM/yyyy')

  const listConfigCommission = {
    onClick: () => null,
    fields: [
      {
        key: 'fantasyName',
        label: 'Vendedor',
        desktopColumnSize: 4,
        isPrimary: true
      },
      {
        key: 'pix',
        label: 'Chave PIX',
        desktopColumnSize: 5,
        sm_prefix: 'Chave Pix: '
      },
      {
        key: 'commissionTotal',
        label: 'Valor',
        desktopColumnSize: 3,
        customValue: (row) => setMask('money', row.commissionTotal),
        sm_prefix: 'Valor: '
      },
    ]
  }

  const listConfig = {
    onClick: row => navigate(`/contratos/todos?seller_id=${row.id}&pending=true`),
    fields: [
      {
        key: 'name',
        label: 'Vendedor',
        desktopColumnSize: 5,
        isPrimary: true
      },
      {
        key: 'amount',
        label: 'Contratos com pendência',
        desktopColumnSize: 4,
        customValue: (row) => `${row.contracts.filter((i) => i?.installment_delayed > 0).length || 0}`,
        sm_prefix: 'Contratos com pendência: '
      },
    ]
  }

  const downloadCommissionReport = () => {
    setLoadingDashCommissionDownload(true)
    const date = format(dateCommission, 'yyyy-MM-dd')

    getCommissionReport({ date })
      .then((response) => downloadFile({ data: response, fileName: `comissoes-${format(dateCommission, 'MM-yyyy')}.xlsx`, fileType: response.type }))
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingDashCommissionDownload(false))
  }

  const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()

    toast('Relatório exportado com sucesso!', 'success')
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | { process.env.REACT_APP_PROJECT_NAME }</title>
      </Helmet>

      <Grid container rowSpacing={2} columnSpacing={2} my={1}>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, pt: 1.1 }}>Relatórios</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant={showReportSetup ? 'outlined' : 'contained'}
            onClick={() => setShowReportSetup(!showReportSetup)}
            endIcon={showReportSetup ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            Gerar
          </Button>
        </Grid>

        {
          showReportSetup ? (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <ExportContractReport onReset={() => setShowReportSetup(false)} />
                </CardContent>
              </Card>
            </Grid>
          ) : null
        }

        <Grid item xs={12} my={2}>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, pt: 1.25 }}>Saldo</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DatePicker
            label="Data de referência"
            inputFormat="DD/MM/YYYY"
            value={dateMetrics}
            onChange={newValue => setDateMetrics(startOfDay(new Date(newValue)))}
            renderInput={params => <TextField {...params} />}
          />
        </Grid>

        <Grid item xs={12} sm={dashMetrics.payedValue >= 0 ? 4 : 6}>
          <AdminMetricsItem
            color="primary"
            isLoading={loadingDashMetrics}
            value={dashMetrics.expectedAllValue}
            title="Saldo emprestado"
            refresh={() => getDataMetrics(dateMetrics)}
          />
        </Grid>
        <Grid item xs={12} sm={dashMetrics.payedValue >= 0 ? 4 : 6}>
          <AdminMetricsItem
            color="secondary"
            isLoading={loadingDashMetrics}
            value={dashMetrics.expectedValue}
            title="Saldo Previsto"
            description={formatedDateMetrics === formatedNow ? 'para hoje' : `em ${formatedDateMetrics}`}
            refresh={() => getDataMetrics(dateMetrics)}
          />
        </Grid>
        {
          dashMetrics.payedValue >= 0 ? (
            <Grid item xs={12} sm={4}>
              <AdminMetricsItem
                color="error"
                isLoading={loadingDashMetrics}
                value={dashMetrics.payedValue}
                title="Saldo Recebido"
                description={formatedDateMetrics === formatedNow ? 'hoje' : `em ${formatedDateMetrics}`}
                refresh={() => getDataMetrics(dateMetrics)}
              />
            </Grid>
          ) : null
        }

        <Grid item xs={12} my={2}>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, pt: 1.25 }}>Comissões</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DatePicker
            label="Mês de referência"
            inputFormat="MMMM, YYYY"
            views={['month', 'year']}
            value={dateCommission}
            onChange={newValue => setDateCommission(startOfDay(new Date(newValue)))}
            renderInput={params => <TextField {...params} />}
          />
        </Grid>

        <Grid item xs={12} mt={1}>
          <List
            onDownloadList={() => downloadCommissionReport()}
            loadingDownloadList={loadingDashCommissionDownload}
            config={listConfigCommission}
            data={dashCommission?.commissions || []}
            refresh={() => getDataCommission(dateCommission)}
            loading={loadingDashCommission}
          />
        </Grid>

        <Grid item xs={12} my={2}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 700 }}>Pendências por vendedor</Typography>
        </Grid>

        <Grid item xs={12} mt={1}>
          <List
            config={listConfig}
            data={dashContracts || []}
            refresh={getDataDashboard}
            loading={loadingDashContracts}
          />
        </Grid>
      </Grid>
    </>
  )
}
