import * as yup from 'yup';

import formModel from './formModel';

const {
  formField: {
    name,
    email,
    phone,
    cpf,
    rg,
    birthDate,
    profession,
    nationality,
    maritalStatus,
    propertyRegime,
    zipcode,
    street,
    neighborhood,
    city,
    state,
    number,
    complement,
    socialName,
    fantasyName,
    emailCompany,
    phoneCompany,
    cnpj,
    pixType,
    pix,
    zipcodeCompany,
    streetCompany,
    neighborhoodCompany,
    cityCompany,
    stateCompany,
    numberCompany,
    complementCompany,
  }
} = formModel;

export default [
  yup.object().shape({
    [name]: yup.string()
      .required('Obrigatório'),
    [email]: yup.string()
      .email('O email deve ser válido')
      .required('Obrigatório'),
    [phone]: yup.string()
      .phone('O telefone deve ser válido')
      .required('Obrigatório'),
    [cpf]: yup.string()
      .cpf('O CPF deve ser válido')
      .required('Obrigatório'),
    [rg]: yup.string()
      .required('Obrigatório'),
    [birthDate]: yup.string()
      .birthdate('A data de nascimento deve ser válida')
      .required('Obrigatório'),
    [profession]: yup.string()
      .required('Obrigatório'),
    [nationality]: yup.string()
      .required('Obrigatório'),
    [maritalStatus]: yup.string()
      .required('Obrigatório'),
    [propertyRegime]: yup.string()
      .required('Obrigatório')
  }),
  yup.object().shape({
    [zipcode]: yup.string()
      .cep('O CEP deve ser válido')
      .required('Obrigatório'),
    [street]: yup.string()
      .required('Obrigatório'),
    [number]: yup.string()
      .required('Obrigatório'),
    [neighborhood]: yup.string()
      .required('Obrigatório'),
    [city]: yup.string()
      .required('Obrigatório'),
    [state]: yup.string()
      .required('Obrigatório'),
    [complement]: yup.string()
  }),
  yup.object().shape({
    [socialName]: yup.string()
      .required('Obrigatório'),
    [fantasyName]: yup.string()
      .required('Obrigatório'),
    [emailCompany]: yup.string()
      .email('O email deve ser válido')
      .required('Obrigatório'),
    [phoneCompany]: yup.string()
      .phone('O telefone deve ser válido')
      .required('Obrigatório'),
    [cnpj]: yup.string()
      .cnpj('O CNPJ deve ser válido')
      .required('Obrigatório'),
    [pixType]: yup.string()
      .required('Obrigatório'),
    [pix]: yup.string()
      .required('Obrigatório')
  }),
  yup.object().shape({
    [zipcodeCompany]: yup.string()
      .cep('O CEP deve ser válido')
      .required('Obrigatório'),
    [streetCompany]: yup.string()
      .required('Obrigatório'),
    [numberCompany]: yup.string()
      .required('Obrigatório'),
    [neighborhoodCompany]: yup.string()
      .required('Obrigatório'),
    [cityCompany]: yup.string()
      .required('Obrigatório'),
    [stateCompany]: yup.string()
      .required('Obrigatório'),
    [complementCompany]: yup.string()
  })
];
