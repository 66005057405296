import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  Box,
  Modal,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Typography
} from '@mui/material'
import { Info } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { useDialog } from 'components/Dialog'
import Iconify from 'components/Iconify'

const ImageGallery = ({ files, onRemoveFile }) => {
  const { t } = useTranslation('common')
  const dialog = useDialog();

  const [selectedImage, setSelectedImage] = useState(null)

  const handleOpenDocument = file => {
    setSelectedImage(file)
  }

  const handleCloseDocument = () => {
    setSelectedImage(null)
  }

  const handleRemoveFile = () => {
    dialog({
      title: 'Remover documento',
      description: `Deseja realmente remover o documento selecionado?`
    })
      .then(() => {
        onRemoveFile(selectedImage.key)
        handleCloseDocument()
      })
      .catch(() => { })
  }

  return (
    <>
      <ImageList cols={3}>
        {
          files.map((item, key) => (
            <ImageListItem
              key={key}
              onClick={() => handleOpenDocument(item)}
              className="link"
            >
              <img
                src={`${process.env.REACT_APP_S3_URL}/${process.env.REACT_APP_S3_FOLDER_USERDOCS}/${item.key}`}
                alt={t(`enum.fileType.${item.type}`)}
                loading="lazy"
              />
              <ImageListItemBar
                title={t(`enum.fileType.${item.type}`)}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label="info"
                  >
                    <Info />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))
        }
      </ImageList>

      {
        selectedImage ? (
          <Modal
            open={Boolean(selectedImage?.key)}
            onClose={handleCloseDocument}
          >
            <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', height: '100vh' }}>
              <img
                src={`${process.env.REACT_APP_S3_URL}/${process.env.REACT_APP_S3_FOLDER_USERDOCS}/${selectedImage.key}`}
                alt={t(`enum.fileType.${selectedImage.type}`)}
                style={{ maxHeight: '80%', maxWidth: '90%' }}
                loading="lazy"
              />

              <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
                <IconButton
                  sx={{ color: '#FFF', padding: 3 }}
                  onClick={() => handleRemoveFile()}
                  aria-label="close"
                >
                  <Iconify icon="zondicons:trash" width={30} />
                </IconButton>

                <IconButton
                  sx={{ color: '#FFF', padding: 3 }}
                  onClick={() => handleCloseDocument()}
                  aria-label="close"
                >
                  <Iconify icon="zondicons:close-solid" width={30} />
                </IconButton>
              </Box>

              <Typography
                sx={{ position: 'absolute', left: 0, bottom: 0, width: '100%', color: '#FFF', padding: 3 }}
                textAlign="center"
              >
                {t(`enum.fileType.${selectedImage.type}`)}
              </Typography>
            </Box>
          </Modal>
        ) : null
      }
    </>
  )
}

ImageGallery.propTypes = {
  files: PropTypes.array,
  onRemoveFile: PropTypes.func
}

export default ImageGallery
