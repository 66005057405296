export default function IllustrationForgot({ ...props }) {
  const color = process.env.REACT_APP_PROJECT_COLOR

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <g id="freepik--Shadow--inject-66">
        <ellipse
          id="freepik--shadow--inject-66"
          cx={201.25}
          cy={398.08}
          rx={81.24}
          ry={46.9}
          style={{
            fill: "rgba(0,0,0,.1)",
          }}
        />
      </g>
      <g id="freepik--Plants--inject-66">
        <g id="freepik--plants--inject-66">
          <path
            d="M432.28,339.4l-9.53-5.5s-2.33-20.4-.78-36.2c2.18-22.2,16.52-48.92,33.62-42.64s-7.84,28.88-13.39,47.71S432.28,339.4,432.28,339.4Z"
            style={{
              fill: `#${color}`,
            }}
          />
          <path
            d="M432.28,339.4l-9.53-5.5s-2.33-20.4-.78-36.2c2.18-22.2,16.52-48.92,33.62-42.64s-7.84,28.88-13.39,47.71S432.28,339.4,432.28,339.4Z"
            style={{
              opacity: 0.15,
            }}
          />
          <path
            d="M427.27,337.09h0a.58.58,0,0,0,.55-.6c-1.67-49.33,22.89-74.15,23.13-74.39a.58.58,0,0,0,0-.82.57.57,0,0,0-.81,0c-.26.24-25.17,25.42-23.48,75.25A.58.58,0,0,0,427.27,337.09Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M438.27,342.83l-6.78-3.91s-1.16-14,5.71-31c6-14.8,20.8-27.09,28.26-24.51s7.27,7.87,3.63,13.42S443.34,315.59,438.27,342.83Z"
            style={{
              fill: `#${color}`,
            }}
          />
          <path
            d="M434.69,341.35h0a.59.59,0,0,0,.58-.58c-.14-31.2,29.15-52.28,29.45-52.49a.56.56,0,0,0,.13-.8.58.58,0,0,0-.8-.14c-.3.21-30.07,21.65-29.94,53.43A.58.58,0,0,0,434.69,341.35Z"
            style={{
              fill: "#fff",
            }}
          />
        </g>
        <g id="freepik--plants--inject-66">
          <path
            d="M79.66,327.15l8.8-5.94s3.87-23,.27-43.29C83.67,249.46,64,240,57,243s-8.5,11.47,4.57,27.71C69.32,280.26,79.39,300.65,79.66,327.15Z"
            style={{
              fill: `#${color}`,
            }}
          />
          <g
            style={{
              opacity: 0.15,
            }}
          >
            <path d="M79.66,327.15l8.8-5.94s3.87-23,.27-43.29C83.67,249.46,64,240,57,243s-8.5,11.47,4.57,27.71C69.32,280.26,79.39,300.65,79.66,327.15Z" />
          </g>
          <path
            d="M82.68,315.75h-.09A.64.64,0,0,1,82,315c3.18-26.79-8.13-55.87-20.44-67.44a.64.64,0,0,1,0-.9.63.63,0,0,1,.9,0C75,258.44,86.52,288,83.3,315.18A.65.65,0,0,1,82.68,315.75Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M56.62,342.69c-1.4.84-5.48-2.19-6.61-2.92a13.44,13.44,0,0,1-4.58-5.06,12.64,12.64,0,0,1-1.27-7.56,29.89,29.89,0,0,1,.89-4c.35-1.31.7-2.62,1-3.94a9.75,9.75,0,0,0,.5-4c-.39-2.67-2.8-4.5-5-6.07-2.42-1.75-5-4.06-5-7.28A11.32,11.32,0,0,1,39.3,295c1.5-2,3.3-3.74,4.65-5.83a8,8,0,0,0,1.53-5,8.32,8.32,0,0,0-1.16-3.11c-1.18-2.08-2.71-4-3.54-6.21s-.77-5.05,1-6.7c1.41-1.35,3.56-1.57,5.51-1.48a12.63,12.63,0,0,1,5.2,1.71c5.17,2.75,8.21,8.75,13.68,10.84,2.11.8,4.41.94,6.6,1.48s4.45,1.61,5.5,3.6c2.07,3.95-1.54,8.93-.06,13.12,1.32,3.72,5,5.08,7.08,8.41a8,8,0,0,1,1,4.88A23.35,23.35,0,0,1,84.4,319,73,73,0,0,1,80,326.9S56.83,342.56,56.62,342.69Z"
            style={{
              fill: `#${color}`,
            }}
          />
          <path
            d="M70.14,334.4h0a.64.64,0,0,1-.63-.65c.56-25.32-11.9-53-20.72-61a.64.64,0,0,1-.05-.9.63.63,0,0,1,.9,0c9,8.1,21.71,36.26,21.14,61.93A.63.63,0,0,1,70.14,334.4Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M65.47,303.55a.37.37,0,0,1-.18,0c-8.18-2.36-18.32-2.6-18.42-2.61a.63.63,0,0,1-.62-.65.61.61,0,0,1,.65-.62c.1,0,10.4.25,18.74,2.65a.64.64,0,0,1,.43.79A.62.62,0,0,1,65.47,303.55Z"
            style={{
              fill: "#fff",
            }}
          />
        </g>
      </g>
      <g id="freepik--speech-bubble--inject-66">
        <g id="freepik--speach-bubble--inject-66">
          <path
            d="M301.16,309.74l-3.79-2.19a4.78,4.78,0,0,1-2.17-3.75V146.28a4.8,4.8,0,0,1,2.16-3.75L402.71,81.71a4.79,4.79,0,0,1,4.33,0l3.79,2.19A4.78,4.78,0,0,1,413,87.65V245.17a4.8,4.8,0,0,1-2.16,3.75L305.49,309.75A4.76,4.76,0,0,1,301.16,309.74Z"
            style={{
              fill: "#455a64",
            }}
          />
          <path
            d="M413,87.3c-.15-1.12-1.06-1.53-2.15-.9L305.49,147.23a4.48,4.48,0,0,0-1.53,1.61l-8.13-4.69a4.43,4.43,0,0,1,1.53-1.62L402.71,81.71a4.79,4.79,0,0,1,4.33,0l3.79,2.19A4.83,4.83,0,0,1,413,87.3Z"
            style={{
              fill: "#455a64",
            }}
          />
          <path
            d="M305.12,309.92a4.83,4.83,0,0,1-4-.17l-3.8-2.2a4.8,4.8,0,0,1-2.16-3.75V146.28a4.43,4.43,0,0,1,.63-2.13l8.13,4.69a4.37,4.37,0,0,0-.64,2.14V308.5C303.32,309.74,304.1,310.31,305.12,309.92Z"
            style={{
              fill: "#263238",
            }}
          />
          <path
            d="M303.32,151V308.5c0,1.38,1,1.94,2.17,1.25l105.35-60.83a4.8,4.8,0,0,0,2.16-3.75V87.65c0-1.38-1-1.94-2.16-1.25L305.49,147.23A4.78,4.78,0,0,0,303.32,151Z"
            style={{
              fill: "#37474f",
            }}
          />
          <path
            d="M278.72,211c-1.36-.81-7.67-4.4-8-4.59a2,2,0,0,1-.43-3.09l24.91-26.72,8.12,4.66L278.42,208A2,2,0,0,0,278.72,211Z"
            style={{
              fill: "#455a64",
            }}
          />
          <path
            d="M303.32,181.29,278.42,208a2,2,0,0,0,1.63,3.36l23.27-1.92Z"
            style={{
              fill: "#37474f",
            }}
          />
          <path
            d="M358.16,133.2c12.28-7.09,22.23-1.34,22.23,12.84s-9.95,31.41-22.23,38.5-22.23,1.34-22.23-12.83S345.88,140.29,358.16,133.2Z"
            style={{
              fill: `#${color}`,
            }}
          />
          <path
            d="M358,144.36c4-2.3,7.21-.44,7.21,4.16A15.93,15.93,0,0,1,358,161c-4,2.3-7.22.44-7.22-4.16A15.93,15.93,0,0,1,358,144.36Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M372.22,168.88c0-7.55-5.31-10.62-11.85-6.84l-4.09,2.36c-6.54,3.78-11.85,13-11.85,20.53V187a12.43,12.43,0,0,0,3.82.59,20.14,20.14,0,0,0,9.91-3,43.81,43.81,0,0,0,14.06-13.9Z"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M344.08,254.73l28.16-16.26c1.2-.69,2.17-.13,2.17,1.25v9.07a4.78,4.78,0,0,1-2.17,3.75L344.08,268.8c-1.2.69-2.17.13-2.17-1.25v-9.07A4.78,4.78,0,0,1,344.08,254.73Z"
            style={{
              fill: `#${color}`,
            }}
          />
          <path
            d="M330.29,234.47,386,202.29c1.43-.83,2.6-.16,2.6,1.5v3.37a5.74,5.74,0,0,1-2.6,4.5l-55.74,32.18c-1.43.83-2.59.16-2.59-1.5V239A5.73,5.73,0,0,1,330.29,234.47Z"
            style={{
              fill: "#fafafa",
            }}
          />
          <path
            d="M336.81,211.18c.76-.44,1.35-.51,1.77-.2s.63,1,.63,2.06a5.92,5.92,0,0,1-.63,2.79,4.42,4.42,0,0,1-1.77,1.86l-1.5.87v3.23a.76.76,0,0,1-.08.32.46.46,0,0,1-.18.22l-.76.44a.15.15,0,0,1-.18,0,.33.33,0,0,1-.07-.24v-9.35a.74.74,0,0,1,.07-.32.51.51,0,0,1,.18-.22Zm-1.5,5.57,1.45-.84a2.42,2.42,0,0,0,.86-.82,2.48,2.48,0,0,0,.32-1.32c0-.54-.11-.85-.32-.94a1,1,0,0,0-.86.19l-1.45.84Z"
            style={{
              fill: "#fafafa",
            }}
          />
          <path
            d="M343.4,207.37c.1-.06.18-.06.23,0a.78.78,0,0,1,.13.3l2.31,7.82s0,.06,0,.09a.72.72,0,0,1-.08.32.46.46,0,0,1-.18.22l-.68.39c-.12.07-.21.08-.26,0a.4.4,0,0,1-.1-.18l-.41-1.37-3,1.72-.4,1.84a1.83,1.83,0,0,1-.11.29.54.54,0,0,1-.26.28l-.68.39a.14.14,0,0,1-.18,0,.32.32,0,0,1-.07-.23.37.37,0,0,1,0-.11l2.3-10.48a2.27,2.27,0,0,1,.13-.45.56.56,0,0,1,.23-.25Zm.52,6.06-1-3.53-1,4.72Z"
            style={{
              fill: "#fafafa",
            }}
          />
          <path
            d="M349.57,203.66a2.45,2.45,0,0,1,1.11-.37,1.34,1.34,0,0,1,.8.18,1.29,1.29,0,0,1,.49.59,2.11,2.11,0,0,1,.18.78.6.6,0,0,1-.07.32.46.46,0,0,1-.18.22l-.66.38c-.11.06-.19.07-.24,0a.47.47,0,0,1-.15-.16.73.73,0,0,0-.42-.39,1.16,1.16,0,0,0-.86.22,3.73,3.73,0,0,0-.46.35,2.44,2.44,0,0,0-.39.46,2.53,2.53,0,0,0-.26.52,1.7,1.7,0,0,0-.09.54,1,1,0,0,0,.09.49.38.38,0,0,0,.3.19,1.64,1.64,0,0,0,.56,0l.87-.19a3,3,0,0,1,1-.12,1.12,1.12,0,0,1,.67.22,1.19,1.19,0,0,1,.38.59,3.08,3.08,0,0,1,.13,1,4.73,4.73,0,0,1-.19,1.32,5.35,5.35,0,0,1-.53,1.26,6.08,6.08,0,0,1-.86,1.12,5.15,5.15,0,0,1-1.14.89,3.08,3.08,0,0,1-1,.39,1.61,1.61,0,0,1-.86-.07,1.3,1.3,0,0,1-.59-.55,2.24,2.24,0,0,1-.26-1,.78.78,0,0,1,.07-.33.56.56,0,0,1,.19-.21l.66-.38c.1-.06.18-.08.24-.05s.1.08.14.17a1.35,1.35,0,0,0,.16.29.53.53,0,0,0,.25.19.89.89,0,0,0,.4,0,2,2,0,0,0,.6-.25,3.87,3.87,0,0,0,.55-.38,2.68,2.68,0,0,0,.78-1,1.68,1.68,0,0,0,.12-.63.58.58,0,0,0-.13-.44.5.5,0,0,0-.4-.12,2.8,2.8,0,0,0-.67.11c-.26.06-.56.13-.91.19a2.4,2.4,0,0,1-.82.06.94.94,0,0,1-.58-.25,1.28,1.28,0,0,1-.35-.62,4.3,4.3,0,0,1-.11-1.06,4.5,4.5,0,0,1,.19-1.3,5.24,5.24,0,0,1,.52-1.24,5.54,5.54,0,0,1,.78-1.07A4.53,4.53,0,0,1,349.57,203.66Z"
            style={{
              fill: "#fafafa",
            }}
          />
          <path
            d="M356.14,199.88a2.37,2.37,0,0,1,1.11-.38,1.36,1.36,0,0,1,.8.18,1.34,1.34,0,0,1,.48.59,2.11,2.11,0,0,1,.18.78.52.52,0,0,1-.07.32.46.46,0,0,1-.18.22l-.66.38c-.1.06-.18.08-.24,0a.35.35,0,0,1-.14-.17.8.8,0,0,0-.43-.38,1.13,1.13,0,0,0-.85.22,3.19,3.19,0,0,0-.47.35,2.39,2.39,0,0,0-.38.46,2.11,2.11,0,0,0-.26.52,1.71,1.71,0,0,0-.1.54,1,1,0,0,0,.09.49.38.38,0,0,0,.3.19,1.63,1.63,0,0,0,.56,0l.87-.19a3.32,3.32,0,0,1,1-.12,1.1,1.1,0,0,1,.67.22,1.28,1.28,0,0,1,.39.59,3.48,3.48,0,0,1,.12,1,4.73,4.73,0,0,1-.19,1.32,5.35,5.35,0,0,1-.53,1.26,6,6,0,0,1-.86,1.13,5.63,5.63,0,0,1-1.13.88,3.12,3.12,0,0,1-1,.39,1.59,1.59,0,0,1-.85-.07,1.27,1.27,0,0,1-.6-.55,2.12,2.12,0,0,1-.25-1,.52.52,0,0,1,.07-.32.46.46,0,0,1,.18-.22l.66-.38c.1-.06.19-.08.24-.05a.25.25,0,0,1,.14.17,2,2,0,0,0,.16.29.55.55,0,0,0,.26.19.89.89,0,0,0,.4,0,2,2,0,0,0,.6-.25,4.45,4.45,0,0,0,.54-.38,2.32,2.32,0,0,0,.46-.48,2.61,2.61,0,0,0,.32-.56,1.68,1.68,0,0,0,.12-.63.58.58,0,0,0-.13-.44.5.5,0,0,0-.4-.12,3.22,3.22,0,0,0-.66.11c-.26.06-.57.13-.91.19a2.46,2.46,0,0,1-.83.06.91.91,0,0,1-.58-.25,1.27,1.27,0,0,1-.34-.62,3.79,3.79,0,0,1-.12-1.05,4.88,4.88,0,0,1,.19-1.31,5.69,5.69,0,0,1,.52-1.24,5.19,5.19,0,0,1,.79-1.07A4,4,0,0,1,356.14,199.88Z"
            style={{
              fill: "#fafafa",
            }}
          />
          <path
            d="M363.43,201.13l-.81,4.79a1.93,1.93,0,0,1-.12.42.51.51,0,0,1-.21.26l-.76.44c-.1.06-.17.06-.22,0a.49.49,0,0,1-.11-.29L360,198.3s0-.08,0-.11a.76.76,0,0,1,.08-.32.51.51,0,0,1,.18-.22l.69-.4a.24.24,0,0,1,.18,0q.09,0,.12.24l.79,5.69.69-4.27a1.4,1.4,0,0,1,.09-.31.53.53,0,0,1,.24-.3l.77-.45c.11-.06.19-.05.24,0a.6.6,0,0,1,.09.21l.7,3.47.79-6.6a.9.9,0,0,1,.11-.37.66.66,0,0,1,.18-.19l.69-.4a.15.15,0,0,1,.18,0,.33.33,0,0,1,.08.24.62.62,0,0,1,0,.12l-1.2,9.83a1.85,1.85,0,0,1-.11.42.58.58,0,0,1-.22.26l-.77.44c-.09.05-.16.05-.21,0a1.41,1.41,0,0,1-.12-.29Z"
            style={{
              fill: "#fafafa",
            }}
          />
          <path
            d="M368.11,196.89a10.32,10.32,0,0,1,.2-1.65,7.16,7.16,0,0,1,.52-1.54,6,6,0,0,1,.84-1.33,4.52,4.52,0,0,1,1.21-1,2.27,2.27,0,0,1,1.19-.39,1.24,1.24,0,0,1,.85.35,2.32,2.32,0,0,1,.51.95,5.67,5.67,0,0,1,.21,1.41c0,.18,0,.38,0,.6s0,.45,0,.69,0,.47,0,.69,0,.43,0,.62a10.07,10.07,0,0,1-.2,1.64,7.73,7.73,0,0,1-.51,1.54,6.39,6.39,0,0,1-.85,1.33,4.73,4.73,0,0,1-1.2,1,2.38,2.38,0,0,1-1.21.39,1.15,1.15,0,0,1-.84-.34,2.11,2.11,0,0,1-.52-1,6.38,6.38,0,0,1-.2-1.41c0-.18,0-.38,0-.6s0-.45,0-.69,0-.46,0-.69S368.1,197.07,368.11,196.89Zm4.26-2.39a1.82,1.82,0,0,0-.44-1.36c-.26-.23-.62-.22-1.05,0a2.62,2.62,0,0,0-1.06,1.18,4.37,4.37,0,0,0-.44,1.87c0,.37,0,.78,0,1.23s0,.85,0,1.21a1.82,1.82,0,0,0,.44,1.36c.26.22.62.2,1.06-.05a2.68,2.68,0,0,0,1.05-1.17,4.32,4.32,0,0,0,.44-1.86c0-.38,0-.79,0-1.24S372.38,194.86,372.37,194.5Z"
            style={{
              fill: "#fafafa",
            }}
          />
          <path
            d="M378,187.38c.79-.45,1.38-.52,1.79-.19a2.45,2.45,0,0,1,.61,2,6.13,6.13,0,0,1-.31,2,5,5,0,0,1-.93,1.64l1.3,2.83a.78.78,0,0,1,0,.14.58.58,0,0,1-.06.26.39.39,0,0,1-.14.18l-.8.46c-.13.07-.22.08-.28,0a.68.68,0,0,1-.13-.18l-1.23-2.67-1.25.72v3.38a.74.74,0,0,1-.07.32.59.59,0,0,1-.18.22l-.76.44a.14.14,0,0,1-.18,0,.3.3,0,0,1-.08-.23v-9.35a.76.76,0,0,1,.08-.32.46.46,0,0,1,.18-.22Zm-1.41,5.38L378,192a2.52,2.52,0,0,0,.86-.79,2.26,2.26,0,0,0,.32-1.28c0-.54-.11-.84-.32-.9a1.18,1.18,0,0,0-.86.22l-1.36.78Z"
            style={{
              fill: "#fafafa",
            }}
          />
          <path
            d="M384.7,183.53a2.65,2.65,0,0,1,1.21-.42,1.2,1.2,0,0,1,.85.31,2.1,2.1,0,0,1,.53,1,6.36,6.36,0,0,1,.22,1.52c0,.7,0,1.42,0,2.15a10,10,0,0,1-.22,1.77,7.42,7.42,0,0,1-.52,1.57,5.9,5.9,0,0,1-.84,1.28,4.6,4.6,0,0,1-1.18,1L382.38,195a.14.14,0,0,1-.18,0,.29.29,0,0,1-.08-.23v-9.35a.76.76,0,0,1,.08-.32.46.46,0,0,1,.18-.22Zm1.54,3.11a3.06,3.06,0,0,0-.12-.8,1.09,1.09,0,0,0-.29-.52.65.65,0,0,0-.48-.19,1.42,1.42,0,0,0-.7.24l-1.26.72v6.52l1.31-.75a2.6,2.6,0,0,0,.67-.56,3.43,3.43,0,0,0,.47-.72,4.12,4.12,0,0,0,.28-.86,5,5,0,0,0,.12-.94C386.27,188.05,386.27,187.34,386.24,186.64Z"
            style={{
              fill: "#fafafa",
            }}
          />
        </g>
      </g>
      <g id="freepik--question-marks--inject-66">
        <g id="freepik--question-marks--inject-66">
          <path
            d="M247.38,38.39a18.76,18.76,0,0,1,4.94,1.86,14.19,14.19,0,0,1,3.93,3.12,11.26,11.26,0,0,1,2.32,4.2,10,10,0,0,1,.1,5.09,10.36,10.36,0,0,1-1.55,3.72,12.65,12.65,0,0,1-2.44,2.56,19.45,19.45,0,0,1-2.91,1.87l-2.88,1.53a15.76,15.76,0,0,0-2.46,1.59A4.72,4.72,0,0,0,244.89,66a2.2,2.2,0,0,1-.73.94,1.28,1.28,0,0,1-1.1.23l-4.24-.94a1.57,1.57,0,0,1-1-.69,1.29,1.29,0,0,1-.19-1.15,10.12,10.12,0,0,1,1.77-3.62A14.12,14.12,0,0,1,242,58.24a20.91,20.91,0,0,1,3-1.83c1-.51,2-1,2.87-1.49a13,13,0,0,0,2.27-1.55,3.61,3.61,0,0,0,1.21-2,4.32,4.32,0,0,0-1-3.88,7.75,7.75,0,0,0-4.44-2.41q-4.74-1.05-8,3.19a3.07,3.07,0,0,1-.78.79,1.47,1.47,0,0,1-1.11.08l-4.51-1a1.24,1.24,0,0,1-.79-.52,1.22,1.22,0,0,1-.17-1,8.94,8.94,0,0,1,2.08-3.72,13.93,13.93,0,0,1,3.89-3,16.81,16.81,0,0,1,5.11-1.7A14.9,14.9,0,0,1,247.38,38.39Zm-4.79,32.18a1.56,1.56,0,0,1,1.17,1.84l-1.08,4.9a1.48,1.48,0,0,1-.68,1,1.46,1.46,0,0,1-1.16.22L236,77.42a1.45,1.45,0,0,1-1-.68,1.49,1.49,0,0,1-.21-1.16l1.09-4.9a1.57,1.57,0,0,1,1.84-1.18Z"
            style={{
              fill: `#${color}`,
            }}
          />
          <path
            d="M252.72,85.3a13.06,13.06,0,0,1,3.64-.07,10,10,0,0,1,3.32,1,8,8,0,0,1,2.56,2.1,7.57,7.57,0,0,1,1.32,6,8.37,8.37,0,0,1-.91,2.27,12.63,12.63,0,0,1-1.38,1.94c-.51.6-1,1.17-1.45,1.72a10.86,10.86,0,0,0-1.17,1.65,3.09,3.09,0,0,0-.46,1.71,1.69,1.69,0,0,1-.23.8.94.94,0,0,1-.65.42l-3,.48a1.07,1.07,0,0,1-.81-.18.88.88,0,0,1-.41-.69,6.81,6.81,0,0,1,.2-2.78,9.81,9.81,0,0,1,1-2.28,13.27,13.27,0,0,1,1.44-1.93c.53-.6,1-1.16,1.46-1.7a8.51,8.51,0,0,0,1.06-1.57,2.51,2.51,0,0,0,.27-1.58,3,3,0,0,0-1.65-2.23,5.39,5.39,0,0,0-3.46-.42,5.18,5.18,0,0,0-4.33,4.11,2.26,2.26,0,0,1-.3.71,1.06,1.06,0,0,1-.68.33l-3.16.51a.86.86,0,0,1-.64-.13.82.82,0,0,1-.35-.58A6,6,0,0,1,244.4,92a9.59,9.59,0,0,1,1.72-2.92,11.76,11.76,0,0,1,2.84-2.4A10.42,10.42,0,0,1,252.72,85.3Zm5.16,21.85a1.06,1.06,0,0,1,.79.19,1,1,0,0,1,.44.69l.56,3.43a1.07,1.07,0,0,1-.88,1.22l-3.35.55a1.07,1.07,0,0,1-.79-.19,1,1,0,0,1-.43-.69l-.56-3.43a1,1,0,0,1,.19-.79,1,1,0,0,1,.69-.43Z"
            style={{
              fill: `#${color}`,
            }}
          />
          <g
            style={{
              opacity: 0.2,
            }}
          >
            <path d="M252.72,85.3a13.06,13.06,0,0,1,3.64-.07,10,10,0,0,1,3.32,1,8,8,0,0,1,2.56,2.1,7.57,7.57,0,0,1,1.32,6,8.37,8.37,0,0,1-.91,2.27,12.63,12.63,0,0,1-1.38,1.94c-.51.6-1,1.17-1.45,1.72a10.86,10.86,0,0,0-1.17,1.65,3.09,3.09,0,0,0-.46,1.71,1.69,1.69,0,0,1-.23.8.94.94,0,0,1-.65.42l-3,.48a1.07,1.07,0,0,1-.81-.18.88.88,0,0,1-.41-.69,6.81,6.81,0,0,1,.2-2.78,9.81,9.81,0,0,1,1-2.28,13.27,13.27,0,0,1,1.44-1.93c.53-.6,1-1.16,1.46-1.7a8.51,8.51,0,0,0,1.06-1.57,2.51,2.51,0,0,0,.27-1.58,3,3,0,0,0-1.65-2.23,5.39,5.39,0,0,0-3.46-.42,5.18,5.18,0,0,0-4.33,4.11,2.26,2.26,0,0,1-.3.71,1.06,1.06,0,0,1-.68.33l-3.16.51a.86.86,0,0,1-.64-.13.82.82,0,0,1-.35-.58A6,6,0,0,1,244.4,92a9.59,9.59,0,0,1,1.72-2.92,11.76,11.76,0,0,1,2.84-2.4A10.42,10.42,0,0,1,252.72,85.3Zm5.16,21.85a1.06,1.06,0,0,1,.79.19,1,1,0,0,1,.44.69l.56,3.43a1.07,1.07,0,0,1-.88,1.22l-3.35.55a1.07,1.07,0,0,1-.79-.19,1,1,0,0,1-.43-.69l-.56-3.43a1,1,0,0,1,.19-.79,1,1,0,0,1,.69-.43Z" />
          </g>
          <path
            d="M281,90.69a1.22,1.22,0,0,1,.51.82,1.2,1.2,0,0,1-.21.94l-2.38,3.35a1.22,1.22,0,0,1-.82.51,1.2,1.2,0,0,1-.94-.21l-3.28-2.33a1.17,1.17,0,0,1-.5-.82,1.2,1.2,0,0,1,.21-.94L276,88.66a1.27,1.27,0,0,1,1.76-.3ZM294.84,68A14.76,14.76,0,0,1,298,71a11.55,11.55,0,0,1,2,3.6,9.21,9.21,0,0,1,.41,3.9,8.15,8.15,0,0,1-1.54,3.87,8.25,8.25,0,0,1-2.36,2.3,9.85,9.85,0,0,1-2.65,1.16,16.68,16.68,0,0,1-2.78.48l-2.66.24a13,13,0,0,0-2.36.41A3.86,3.86,0,0,0,284.18,88a1.77,1.77,0,0,1-.85.48,1,1,0,0,1-.9-.18l-2.9-2.06a1.32,1.32,0,0,1-.53-.83,1.06,1.06,0,0,1,.23-.93,8.31,8.31,0,0,1,2.49-2.16,11.42,11.42,0,0,1,2.76-1.07,16.43,16.43,0,0,1,2.83-.43q1.41-.09,2.64-.21a10.41,10.41,0,0,0,2.21-.44A3,3,0,0,0,293.7,79a3.55,3.55,0,0,0,.47-3.25,6.35,6.35,0,0,0-2.57-3.23q-3.24-2.31-7.07-.15a2.5,2.5,0,0,1-.84.34,1.18,1.18,0,0,1-.86-.29l-3.08-2.19a1,1,0,0,1-.43-.65,1,1,0,0,1,.19-.78,7.21,7.21,0,0,1,2.75-2.15,11.63,11.63,0,0,1,3.88-1,13.62,13.62,0,0,1,4.4.35A12,12,0,0,1,294.84,68Z"
            style={{
              fill: `#${color}`,
            }}
          />
          <g
            style={{
              opacity: 0.6000000000000001,
            }}
          >
            <path
              d="M281,90.69a1.22,1.22,0,0,1,.51.82,1.2,1.2,0,0,1-.21.94l-2.38,3.35a1.22,1.22,0,0,1-.82.51,1.2,1.2,0,0,1-.94-.21l-3.28-2.33a1.17,1.17,0,0,1-.5-.82,1.2,1.2,0,0,1,.21-.94L276,88.66a1.27,1.27,0,0,1,1.76-.3ZM294.84,68A14.76,14.76,0,0,1,298,71a11.55,11.55,0,0,1,2,3.6,9.21,9.21,0,0,1,.41,3.9,8.15,8.15,0,0,1-1.54,3.87,8.25,8.25,0,0,1-2.36,2.3,9.85,9.85,0,0,1-2.65,1.16,16.68,16.68,0,0,1-2.78.48l-2.66.24a13,13,0,0,0-2.36.41A3.86,3.86,0,0,0,284.18,88a1.77,1.77,0,0,1-.85.48,1,1,0,0,1-.9-.18l-2.9-2.06a1.32,1.32,0,0,1-.53-.83,1.06,1.06,0,0,1,.23-.93,8.31,8.31,0,0,1,2.49-2.16,11.42,11.42,0,0,1,2.76-1.07,16.43,16.43,0,0,1,2.83-.43q1.41-.09,2.64-.21a10.41,10.41,0,0,0,2.21-.44A3,3,0,0,0,293.7,79a3.55,3.55,0,0,0,.47-3.25,6.35,6.35,0,0,0-2.57-3.23q-3.24-2.31-7.07-.15a2.5,2.5,0,0,1-.84.34,1.18,1.18,0,0,1-.86-.29l-3.08-2.19a1,1,0,0,1-.43-.65,1,1,0,0,1,.19-.78,7.21,7.21,0,0,1,2.75-2.15,11.63,11.63,0,0,1,3.88-1,13.62,13.62,0,0,1,4.4.35A12,12,0,0,1,294.84,68Z"
              style={{
                fill: "#fff",
              }}
            />
          </g>
        </g>
      </g>
      <g id="freepik--Character--inject-66">
        <g id="freepik--character--inject-66">
          <path
            d="M206.53,179.21H171V93.13a28.19,28.19,0,0,1,28.19-28.19h3.28a28.19,28.19,0,0,1,28.19,28.19v62A24.13,24.13,0,0,1,206.53,179.21Z"
            style={{
              fill: "#263238",
            }}
          />
          <path
            d="M167.54,386.44a66.14,66.14,0,0,0-1,10.8c-.13,6.56-1.33,8.89-1.49,14.27-.19,6.15.88,8.45,3.37,11.91s9,6.59,11.82.75,2.41-9.67,1.52-15.7c-.91-6.24-1-9.69-1.56-14.44-.46-4.19-1-7.38-1-7.38Z"
            style={{
              fill: "#263238",
            }}
          />
          <path
            d="M186.16,342.87l-21.6-.39c.32,14.42,2.61,30.43,3,44,.08,2.55-.06,19.79.9,25,1.45,7.88,10.79,6.81,11.29,2.1s-.6-24.84-.57-26.92c-.09-8.68,4-23.33,6.11-38.91C185.38,347,186.06,343.62,186.16,342.87Z"
            style={{
              fill: "#ffa8a7",
            }}
          />
          <path
            d="M213.91,375.22s-.85-.46-1,.45c-.21,1.08-.53,3.31-1,5.09-.62,2.47-1.14,6.54-.77,8.56s5.07,4.6,8.12,5.54a24.74,24.74,0,0,1,10,5.95c3,2.84,9.53,5.52,14,5.49,5.25,0,8.91-1.75,10.64-4.56s-1.58-3.15-7.58-7l-4.65-3c-9.51-6.21-13-9.53-15.67-15.74Z"
            style={{
              fill: "#263238",
            }}
          />
          <path
            d="M226.08,371.37C225.92,366.91,229,329,229,329l-20.51,9.35c4.39,26.22,5.33,32.41,5.4,36.7h0a3.81,3.81,0,0,0-.11,1.1c0,5.09,5.77,7.84,10.25,11.24a84.25,84.25,0,0,0,12.13,7.92c3.26,1.63,9.88,1.78,10.7-.31-5.46-4.17-13.4-10.41-17.74-15.17C227,377.59,226.24,375.84,226.08,371.37Z"
            style={{
              fill: "#ffa8a7",
            }}
          />
          <path
            d="M173.41,202.16c-7.88,17.29-7.88,30.67-6.57,52.74C168,275.15,169.93,293,169.93,293c-.3,3.59-5.29,10.6-5.94,26.48-.6,14.84,1,33.71,1,33.71s11.21,6.21,19.5-.06c0,0,8-38.19,10.34-56.77,2-16.21,5.6-36.92,5.6-36.92L208,296.25a96,96,0,0,0-3.54,16c-1.06,10.93,5.06,32.39,5.06,32.39s11.58,3.45,18.65-2.82c0,0,2.64-34.89,3.63-48.05,1.32-17.63-1.46-65.35-3.63-90.62Z"
            style={{
              fill: "#455a64",
            }}
          />
          <path
            d="M200.45,259.41l3.11-27.74s13.94-1.86,20.26-7.8a22.88,22.88,0,0,1-16.43,10.38l-4.5,25.81L208,296.25Z"
            style={{
              fill: "#263238",
            }}
          />
          <path
            d="M256.9,195.34a120.34,120.34,0,0,0,.76-15.25c-.31-4.4-1.1-5.12-7.25-12.91-8.3-10.53-15.39-20-22.89-29.41-5.36-6.72-11-7.75-16.48-7.45-3.84.21,3.66,18.68,7.43,29.61L240,183.11a4.6,4.6,0,0,1,.86,3.17L239.56,206l6.45,8.64S255.78,206.22,256.9,195.34Z"
            style={{
              fill: "#ffa8a7",
            }}
          />
          <path
            d="M190.28,130.92h0c-4.17.29-11.75,1-11.75,1-4.37,2.44-5.48,4.45-6.29,10.25-.35,2.48,1.67,9.07,3.4,18.44l3.28,17.37-.36,8.62c-1.7,5.26-5.55,11.79-8.95,21.42,16.7,9.32,30.7,9.67,43.4,8,9.68-1.25,14.81-3.59,16.52-5.91-.36-9.51-1-15.39-1.2-25.33-.07-4.17.18-8.39.35-13.19,6.49-5.34,6.57-14.22,3-20-8.23-13.33-16.24-21.72-21.54-21.29Z"
            style={{
              fill: `#${color}`,
            }}
          />
          <path
            d="M190.28,130.92h0c-4.17.29-11.75,1-11.75,1-4.37,2.44-5.48,4.45-6.29,10.25-.35,2.48,1.67,9.07,3.4,18.44l3.28,17.37-.36,8.62c-1.7,5.26-5.55,11.79-8.95,21.42,16.7,9.32,30.7,9.67,43.4,8,9.68-1.25,14.81-3.59,16.52-5.91-.36-9.51-1-15.39-1.2-25.33-.07-4.17.18-8.39.35-13.19,6.49-5.34,6.57-14.22,3-20-8.23-13.33-16.24-21.72-21.54-21.29Z"
            style={{
              fill: "#fff",
              opacity: 0.6000000000000001,
            }}
          />
          <path
            d="M215.75,171.72s-7.62,5.92-16.33,2.83a14.9,14.9,0,0,1-10-10.69s-.92,9.95,10.05,13.34C210.1,180.47,215.75,171.72,215.75,171.72Z"
            style={{
              opacity: 0.1,
            }}
          />
          <path
            d="M226.09,103.61c-.33,14.44-5,18-7.36,19s-6.88.4-11.28-.33V131s5.08,5.32,4.7,9.29-8,4.42-13.68.53a43,43,0,0,1-8.43-7.61,3.09,3.09,0,0,1-.73-2V110.81s-2.41,2.55-6.67-.37a7.86,7.86,0,0,1-2-11.42c1.86-2.19,6.58-2.34,8.84,1,1.28,2.49,1.45,5,2.88,4,3.8-2.74,1.76-9,6.35-12h0a33,33,0,0,1,4.68-2c8.19-2.83,15.29-5.21,17-10.81C223.61,81.05,226.49,86.58,226.09,103.61Z"
            style={{
              fill: "#ffa8a7",
            }}
          />
          <circle
            cx={209.94}
            cy={114.61}
            r={1.9}
            style={{
              fill: "#b16668",
            }}
          />
          <path
            d="M208.68,101.42a2,2,0,1,1-2.1-2A2,2,0,0,1,208.68,101.42Z"
            style={{
              fill: "#263238",
            }}
          />
          <path
            d="M223.83,101.62a1.85,1.85,0,0,1-1.72,2,1.75,1.75,0,0,1-1.91-1.8,1.82,1.82,0,1,1,3.63-.17Z"
            style={{
              fill: "#263238",
            }}
          />
          <path
            d="M206.11,106.56a6.51,6.51,0,0,1-6.51-6.5c0-4.42,4.56-4.42,6.51-4.42s6.5,0,6.5,4.42A6.51,6.51,0,0,1,206.11,106.56Zm0-9.9c-4,0-5.48,1-5.48,3.4a5.48,5.48,0,1,0,11,0C211.59,97.61,210.05,96.66,206.11,96.66Z"
            style={{
              fill: "#455a64",
            }}
          />
          <path
            d="M222.62,106.56a6.51,6.51,0,0,1-6.51-6.5c0-4.42,4.56-4.42,6.51-4.42s6.5,0,6.5,4.42A6.51,6.51,0,0,1,222.62,106.56Zm0-9.9c-3.94,0-5.48,1-5.48,3.4a5.48,5.48,0,1,0,11,0C228.1,97.61,226.56,96.66,222.62,96.66Z"
            style={{
              fill: "#455a64",
            }}
          />
          <polygon
            points="213.62 97.96 213.94 110.11 220.33 108.13 213.62 97.96"
            style={{
              fill: "#f28f8f",
            }}
          />
          <path
            d="M207.44,122.31c-4.64-.61-14.19-3.35-15.68-7.13a9.83,9.83,0,0,0,3.35,4.82c2.85,2.45,12.33,5.2,12.33,5.2Z"
            style={{
              fill: "#f28f8f",
            }}
          />
          <path
            d="M207.67,92.06l-4.24,2.59a2.61,2.61,0,0,1,.86-3.5A2.42,2.42,0,0,1,207.67,92.06Z"
            style={{
              fill: "#263238",
            }}
          />
          <path
            d="M224.92,94.94l-4.72-1.65a2.39,2.39,0,0,1,3.1-1.58A2.62,2.62,0,0,1,224.92,94.94Z"
            style={{
              fill: "#263238",
            }}
          />
          <path
            d="M212.1,100.58a.51.51,0,0,1-.51-.52c0-.83,1.64-1.37,3.12-1.37s2.43.54,2.43,1.37a.52.52,0,0,1-1,0c0-.1-.51-.35-1.4-.35a5.61,5.61,0,0,0-2.1.36A.51.51,0,0,1,212.1,100.58Z"
            style={{
              fill: "#455a64",
            }}
          />
          <path
            d="M200.13,100.14h-.06l-11.43-1a5.54,5.54,0,0,0-1.87-1.22l13.42,1.24a.52.52,0,0,1,.45.57A.51.51,0,0,1,200.13,100.14Z"
            style={{
              fill: "#455a64",
            }}
          />
          <path
            d="M153.2,112.06c3.74-5,6.29-8.77,9.7-13,1.94-2.43,3.18-4.17,5-6.66,1.28-1.72,2-3.17,3.46-3.7a12,12,0,0,0,4.17-2.86,25.11,25.11,0,0,0,2-2.52,37.6,37.6,0,0,1,7.56-7.61c3-2.26,6.35-4,9.44-6.15,1.84-1.29,3.81-3.43,1.57-5.43a5.33,5.33,0,0,0-2.39-1.15c-5.45-1.18-10.19.69-15.52,2.05a43.85,43.85,0,0,0-6.77,2.29,12.05,12.05,0,0,0-3,1.72,15.83,15.83,0,0,0-3.09,4.42C163,77.62,158,81.34,154.5,84.63c-4.09,3.85-7.75,7.38-12.42,11.62-4.2,3.83-8.31,7.35-12.16,11.53-3.19,3.45-2,6.15-1.58,7.09,2.6,5.34,5.51,7.26,9,10.57,6,5.73,26.75,22.22,33.62,27.67a11.14,11.14,0,0,1,3.37,4.4l1.32,3.05c7.84,1.44,13.07-10.33,6.58-24.5a14.87,14.87,0,0,0-4.77-5.75C169.26,124.34,153.2,112.06,153.2,112.06Z"
            style={{
              fill: "#ffa8a7",
            }}
          />
          <path
            d="M153.2,112.06a20.63,20.63,0,0,1-9.58,6.59s5,1.28,11.85-4.87Z"
            style={{
              fill: "#f28f8f",
            }}
          />
          <path
            d="M254.86,184.91h0l-1.28-1.1h0a1.46,1.46,0,0,0-1.31-.15l-13.89,3.78a5.12,5.12,0,0,0-3.12,2.92l-10,28.83a1.32,1.32,0,0,0,.18,1.47h0l1.32,1.14v0a1.44,1.44,0,0,0,1.33.16L242,218.18a5.12,5.12,0,0,0,3.12-2.92c2-5.67,8-23.16,10-28.83C255.36,185.72,255.24,185.18,254.86,184.91Z"
            style={{
              fill: "#455a64",
            }}
          />
          <path
            d="M226.57,220.3l10-28.83a5.08,5.08,0,0,1,3.12-2.91l13.88-3.78c1.29-.35,2,.39,1.54,1.65-2,5.67-8,23.16-10,28.83a5.12,5.12,0,0,1-3.12,2.92L228.11,222C226.83,222.3,226.14,221.56,226.57,220.3Z"
            style={{
              fill: `#${color}`,
            }}
          />
          <path
            d="M238.18,189.36a5,5,0,0,0-1.62,2.12l-10,28.82a1.36,1.36,0,0,0,.14,1.44l-1.24-1.06v0a1.3,1.3,0,0,1-.19-1.47l10-28.83a4.91,4.91,0,0,1,1.49-2Z"
            style={{
              fill: "#37474f",
            }}
          />
          <path
            d="M251.05,198.05c-1,2.93-4.47,4.89-10.21,6.25s-8.91,7.45-8,9.3,10.19,3.58,13.9,3.05,7.43-5.89,8.56-7.55,2.43-13.24,1.24-14.83-3.7-1.34-3.7-1.34Z"
            style={{
              fill: "#f28f8f",
            }}
          />
        </g>
      </g>
    </svg>
  )
}
