import { styled } from '@mui/material/styles'
import { AppBar, Toolbar } from '@mui/material'

import { cssStyles } from 'utils'

const NAV_WIDTH = 280

const HEADER_MOBILE = 64

const HEADER_DESKTOP = 92

export const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...cssStyles.bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}))

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}))
